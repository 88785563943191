import React, { useState, useEffect } from "react";
import {
    useRouteMatch,
    Switch,
    Link,
    useParams,
    useHistory,
    useLocation,
    Redirect
} from "react-router-dom";
import { Col, Row, Drawer, Menu, Dropdown, Space, Button, Modal, message } from "antd";
import Icon from '@ant-design/icons';
import PrivateRoute from "../../../logics/PrivateRoute";
import MenuDropdownSvg from  '../../../assets/svg-icons/keyboard_arrow_down.svg';
import CustomMenuSvg from  '../../../assets/svg-icons/custom-menu.svg';
import ArbitrationSvg from  '../../../assets/svg-icons/arbitration.svg';
import StarSvg from  '../../../assets/svg-icons/star.svg';
import StarFillSvg from  '../../../assets/svg-icons/star_fill.svg';
import HomeSvg from  '../../../assets/svg-icons/home.svg';
import ActivitySvg from  '../../../assets/svg-icons/activity.svg';
import AppMenuSvg from  '../../../assets/svg-icons/center_btn.svg';
import OverviewSvg from  '../../../assets/svg-icons/overview.svg';
import DetailsSvg from  '../../../assets/svg-icons/details.svg';
import PartiesSvg from  '../../../assets/svg-icons/supervisor_account.svg';
import MsgSvg from  '../../../assets/svg-icons/message.svg';
import TasksSvg from  '../../../assets/svg-icons/tasks.svg';
import CalendarSvg from  '../../../assets/svg-icons/calendar.svg';
import OutcomeSvg from  '../../../assets/svg-icons/outcome.svg';
import SwitchUserSvg from '../../../assets/svg-icons/switch_user.svg';
import './styles.less';
import Overview from "./Overview";
import apiService from "../../../logics/services/api.service";
import userService from "../../../logics/services/user.service";
import { useDispatch, useSelector } from 'react-redux';
import Details from "./Details";
import Activity from "./Activity";
import Message from "./Message";
import Withdraw from "./Withdraw";
import CaseClose from "./CaseClose";
import Escalate from "./Escalate";
import Tasks from "./Tasks";
import Calendar from "./Calendar";
import Outcome from "./Outcome";
import CaseStatusIcon from "./CaseStatusIcon";
import PermissionDenied from "./PermissionDenied";
import { decodeToken } from "react-jwt";
import store from "../../../logics/store/case.store";

import AccessDeniedSvg from  '../../../assets/svg-icons/access-denied.svg';
import { socket } from "../../../logics/services/websocket.service";

const MenuDropdownIcon = props => <Icon component={MenuDropdownSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const CustomMenuIcon = props => <Icon component={CustomMenuSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const ArbitrationIcon = props => <Icon component={ArbitrationSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const StarIcon = props => <Icon component={StarSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const StarFillIcon = props => <Icon component={StarFillSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const HomeIcon = props => <Icon component={HomeSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const ActivityIcon = props => <Icon component={ActivitySvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const AppMenuIcon = props => <Icon component={AppMenuSvg} style={{ fontSize: 48, verticalAlign: 'middle'}} {...props} />;
const OverviewIcon = props => <Icon component={OverviewSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const DetailsIcon = props => <Icon component={DetailsSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const PartiesIcon = props => <Icon component={PartiesSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const MsgIcon = props => <Icon component={MsgSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const TasksIcon = props => <Icon component={TasksSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const CalendarIcon = props => <Icon component={CalendarSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const OutcomeIcon = props => <Icon component={OutcomeSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const SwitchUserIcon = props => <Icon component={SwitchUserSvg} style={{ fontSize: 64, verticalAlign: 'middle'}} {...props} />;

const AccessDeniedIcon = props => <Icon component={AccessDeniedSvg} style={{ fontSize: 200, verticalAlign: 'middle'}} {...props} />;

export default function CaseView() {
    const dispatch = useDispatch();
    const history = useHistory();
    const sampleLocation = useLocation();
    const url = sampleLocation.pathname;
    const caseUrl = url.substring(url.lastIndexOf("/") + 1);
    const deniedDetail = ["message", "tasks", "files", "questionnaires", "events", "outcomes"];
    const user = store.getState().user;//userService.getUser();
    // const user = useSelector(state => state.user);
    let rolePermissions = user?.permissions;
    let { path } = useRouteMatch();
    let { caseId, caseDetail } = useParams();
    const caseInfo = useSelector(state => state.case); //store.getState().case;
    // console.log(caseInfo);
    // const [caseInfo, setCaseInfo] = useState([]);
    const [visible, setVisible] = useState(false);
    const [withdraw, setWithdraw] = useState(false);
    const [caseClose, setCaseClose] = useState(false);
    const [escalate, setEscalate] = useState(false);
    const [showSwitchRole, setShowSwitchRole] = useState(false);
    const [escalation, setEscalation] = useState(null);
    const [isFav, setIsFav] = useState(false);
    const [permissionDenied, setPermissionDenied] = useState(false);
    const [menuDisable, setMenuDisable] = useState(false);
    const [prevRole, setPrevRole] = useState(user?.role_name);
    const [currentRole, setCurrentRole] = useState(user?.role_name);
    const [autoCloseTime, setAutoCloseTime] = useState(5);
    const [redirectTo, ] = useState(`/app/case/${caseId}/overview`);

    useEffect(() => {
        apiService.getSingleCase({
            case_id: caseId
        }).then((res) => {
            if (res && res.status === 'success') {
                dispatch({ type: 'case', value: res.data});
                // setCaseInfo(res.data);
                setIsFav(res.data.isFav == 1 ? true: false);
                if(!res.data?.caseUserStatus && (deniedDetail.includes(caseUrl) || deniedDetail.includes(caseDetail))) {
                    history.push('/app/case/'+caseId+'/permission-denied');
                }
                setMenuDisable(!res.data?.caseUserStatus && true);
                setPrevRole(user?.role_name);
                let prevRCount = user?.role_count;
                if (res.role && user && res.role.role_label != user.role_label && res.token) {
                    setCurrentRole(res.role.role_name);
                    setShowSwitchRole(true);
                    userService.setUserToken(res.token);
                    const user = decodeToken(res.token);
                    dispatch({type: 'user', value: user});
                    if (prevRCount > 1) {
                        setTimeout(() => {
                            setShowSwitchRole(false);
                        }, 5000);
                        let timer = autoCloseTime;
                        let autotimer = setInterval(() => {
                            timer--;
                            setAutoCloseTime(timer);
                            if (timer == 0) {
                                setAutoCloseTime(5);
                                setShowSwitchRole(false);
                                clearInterval(autotimer);
                            }
                        }, 1000);
                    }
                }
            } else if (!res) {
                if (!rolePermissions['All Cases Tab']) {
                    setPermissionDenied(true);
                } else {
                    history.push('/app/case/'+caseId+'/overview');
                }
            } else {
                history.push('/app/case/'+caseId+'/overview');
            }
        }, () => {
            if (!rolePermissions['All Cases Tab']) {
                setPermissionDenied(true);  
            } else {
                history.push('/app/case/'+caseId+'/overview');
            }
        });
        getCaseInfo();
    }, [caseId, dispatch]);

    socket.onmessage = resData => {
        let res = (resData && resData.data) ? JSON.parse(resData.data): [];
        let user = userService.getUser();
        let data = res.data;
        if (res && (data.type == 'accept-case' || data.type == 'reject-case' || data.type == 'close' || data.type == 'withdraw' || data.type == 'reopen-case' || data.type == 'neutral-reallocate' || data.type == 'case-reallocate' || data.type == 'common') && res.data && res.data.users && Array.isArray(res.data.users) && res.data.users.indexOf(user.id) != -1 && caseId == res.data.case_id && res.data.from.u_id != user.id) {
            apiService.getSingleCase({
                case_id: caseId
            }).then((res) => {
                if (res && res.status === 'success') {
                    dispatch({ type: 'case', value: res.data});
                }
            });
        }
    }

    const getCaseInfo = () => {
        apiService.getCaseInfo({
            case_id: caseId,
        }).then((res) => {
            if (res.status === "success") {
                setEscalation(res.data && res.data.escalation.length > 0 && res.data.escalation[0].status);
            }
        });
    };

    const addToFavourite = () => {
        let caseFav;
        apiService.addFavourite({
            case_id: caseId, 
            favourite: 1
        })
        .then((res) => {
            if (res.status == 'success') {
                message.success(res.message);
                caseFav = caseInfo;
                caseFav.isFav = 1;
                dispatch({ type: 'case', value: caseFav});
                // setCaseInfo(caseFav);
                setIsFav(true);
            }
        });
    };

    const removeFromFavourite = () => {
        let caseRemFav;
        apiService.removeFavourite({
            case_id: caseId, 
            favourite: 0
        })
        .then((res) => {
            if (res.status == 'success') {
                message.success(res.message);
                caseRemFav = caseInfo;
                caseRemFav.isFav = 0;
                dispatch({ type: 'case', value: caseRemFav});
                // setCaseInfo(caseRemFav);
                setIsFav(false);
            }
        });
    };

    const showDrawer = () => {
        setVisible(!visible);
    };

    const onClose = () => {
        setVisible(!visible);
    };

    const showWithdraw = () => {
        setWithdraw(true);
    };

    const closeWithdraw = (val) => {
        setWithdraw(val);
    }

    const closeCaseClose = (val) => {
        setCaseClose(val);
    }

    const showEscalate = () => {
        if (escalation === 0) {
            history.push('/app/case/'+caseId+'/details');
        } else {
            setEscalate(true);
        }
    };

    const closeEscalate = (val) => {
        setEscalate(val);
        getCaseInfo();
    }

    const redirectToPreviousUrl = () => {
        history.push('/app/allcases');
    }

    const closeRoleChange = () => {
        setShowSwitchRole(false);
    }

    const menu = (
        <Menu>
            {(!isFav) && (<Menu.Item key="1" onClick={addToFavourite}>Mark as Favorite</Menu.Item>)}
            {(isFav) && (<Menu.Item key="2" onClick={removeFromFavourite}>Remove Favorite</Menu.Item>)}
            {(Boolean(rolePermissions && rolePermissions['Escalate']) && caseInfo && caseInfo.case_type != 3) && (<Menu.Item key="6" disabled={caseInfo && (caseInfo.case_status != 3 || !caseInfo.isAdmin)} onClick={caseInfo && caseInfo.case_status == 3 && caseInfo.isAdmin && showEscalate}>Escalate</Menu.Item>)}
            {(caseInfo && ((caseInfo.case_status == 1 || caseInfo.case_status == 2 || caseInfo.case_status == 3) && rolePermissions['Withdraw Case'] && caseInfo?.caseUserStatus)) && (<Menu.Item key="8" disabled={caseInfo && caseInfo.userActive == 0} onClick={showWithdraw}>Withdraw Case</Menu.Item>)}
        </Menu>
    );

    return (
        <div>
            {   !permissionDenied
                ? 
                    <>
                        <Row className="sub-header case-name-header">
                            <Col span={20} className="case-name-bar">
                                <CaseStatusIcon caseId={caseId} case_status={caseInfo?.case_status} case_type={caseInfo?.case_type} size={32}/>
                                <span className="pl16 case-name">{ caseInfo?.case_name }</span>
                                <span className="pl8 mr8">
                                    {(!isFav) && (<StarIcon />)}
                                    {(isFav) && (<StarFillIcon />)}
                                </span>
                            </Col>
                            <Col span={4} className="textRight">
                                <Dropdown placement="bottomRight" overlay={menu} trigger={['click']}>
                                    <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        <CustomMenuIcon />
                                        <MenuDropdownIcon />
                                    </Space>
                                    </a>
                                </Dropdown>
                                { withdraw 
                                    ?
                                        <Withdraw caseId={caseId} visible={withdraw} closeWithdrawVisible={closeWithdraw}/>
                                    :
                                        <></>
                                }
                                { caseClose 
                                    ?
                                        <CaseClose caseId={caseId} visible={caseClose} closeCaseVisible={closeCaseClose}/>
                                    :
                                        <></>
                                }
                                { escalate 
                                    ?
                                        <Escalate caseId={caseId} visible={escalate} escalateRequestVisible={false} escalationRequest={[]} closeEscalateVisible={closeEscalate}/>
                                    :
                                        <></>
                                }
                            </Col>
                        </Row>
                        <div className="main-display-container">
                            <Switch>
                                {caseDetail == "tasks" && (<PrivateRoute path={`${path}`} component={Tasks}/>)}
                                {caseDetail == "outcomes" && (<PrivateRoute path={`${path}`} component={Outcome}/>)}
                                {caseDetail == "overview" && (<PrivateRoute path={`${path}`} component={Overview}/>)}
                                {caseDetail == "message" && (<PrivateRoute path={`${path}`} component={Message}/>)}
                                {caseDetail == "events" && (<PrivateRoute path={`${path}`} component={Calendar}/>)}
                                <PrivateRoute path={`${path}/overview`} component={Overview} />
                                <PrivateRoute path={`${path}/details`} component={Details} />
                                <PrivateRoute path={`${path}/activity`} component={Activity} />
                                <PrivateRoute path={`${path}/message`} component={Message} />
                                <PrivateRoute path={`${path}/tasks`} component={Tasks} />
                                <PrivateRoute path={`${path}/events`} component={Calendar} />
                                <PrivateRoute path={`${path}/outcomes`} component={Outcome} />
                                <PrivateRoute path={`${path}/permission-denied`} component={PermissionDenied} />
                                <Redirect from="*" to={`${redirectTo}`} />
                            </Switch>
                        </div>
                        <Row className="bottom-fixed-menu">
                            <Col span={8}>
                                <Link to={`/app/allcases`}>
                                    <HomeIcon />
                                    <p>Home</p>
                                </Link>
                            </Col>
                            <Col span={8}>
                                <AppMenuIcon onClick={showDrawer} />
                            </Col>
                            <Col span={8}>
                                <Link to={`/app/allcases-activity`}>
                                    <ActivityIcon />
                                    <p>All Activities</p>
                                </Link>
                            </Col>
                        </Row>
                        <Drawer
                            placement="bottom"
                            closable={false}
                            mask={false} 
                            className="app-menu-drawer"
                            contentWrapperStyle={{bottom: 60, borderRadius: '4px 4px 0px 0px'}}
                            height={300}
                            onClose={onClose}
                            visible={visible}
                            style={{ position: 'absolute' }}
                            >
                                <div className="menu-bar-div" onClick={onClose}></div>
                                <Row className="mb16" gutter={24}>
                                    <Col span={12}><Link to={`/app/case/${caseId}/overview`} onClick={onClose}><div><OverviewIcon className="pr8" />Overview</div></Link></Col>
                                    <Col span={12}><Link to={`/app/case/${caseId}/details`} onClick={onClose}><div><DetailsIcon className="pr8" />Details</div></Link></Col>
                                </Row>
                                <Row className="mb16" gutter={24}>
                                    <Col span={12}><Link to={`/app/case/${caseId}/activity`} onClick={onClose}><div><ActivityIcon className="pr8" />Activity</div></Link></Col>
                                    <Col span={12} className={menuDisable && "disabled-opacity"}><Link to={`/app/case/${caseId}/message`} onClick={onClose}><div><MsgIcon className="pr8" />Messages</div></Link></Col>
                                    {/* <Col span={12}><div><PartiesIcon className="pr8" />Participants</div></Col> */}
                                </Row>
                                <Row className="mb16" gutter={24}>
                                    <Col span={12} className={menuDisable && "disabled-opacity"}><Link to={`/app/case/${caseId}/tasks`} onClick={onClose}><div><TasksIcon className="pr8" />Tasks</div></Link></Col>
                                    <Col span={12} className={menuDisable && "disabled-opacity"}><Link to={`/app/case/${caseId}/events`} onClick={onClose}><div><CalendarIcon className="pr8" />Calendar</div></Link></Col>
                                </Row>
                                {/* <Row className="mb16" gutter={24}>
                                    <Col span={12}><div><FolderIcon className="pr8" />Files</div></Col>
                                    <Col span={12}><div><LiveHelpIcon className="pr8" />Questionnaires</div></Col>
                                </Row> */}
                                <Row gutter={24}>
                                    <Col span={12} className={menuDisable && "disabled-opacity"}><Link to={`/app/case/${caseId}/outcomes`} onClick={onClose}><div><OutcomeIcon className="pr8" />Outcomes</div></Link></Col>
                                </Row>
                        </Drawer>
                        <Modal
                            visible={showSwitchRole}
                            title={<div><span className="name-title">Hi {user?.name}!</span> <span className="auto-close">Auto close {autoCloseTime} sec</span></div>}
                            zIndex="10000"
                            footer={null}
                            onCancel={closeRoleChange}
                            className="switch-case-role-popup"
                        >
                            <p className="textCenter">
                                <SwitchUserIcon/>
                            </p>
                            <p>Your role has been switched from <span className="role">{ prevRole }</span> to <span className="role">{ currentRole }</span> in this case</p>
                            <p className="info">You can switch your role by clicking “Switch Role” from profile dropdown</p>
                        </Modal>
                    </>
                :
                    <Row className="permission-denied">
                        <Col>
                            <AccessDeniedIcon className="access-denied-icon"/>
                            <span className="access-denied-title">Permission Denied</span>
                            <p>You don't have permission to access this case.</p>
                            <Button type="primary" id="access-denied-back" onClick={redirectToPreviousUrl}>Go home</Button>
                        </Col>
                    </Row>
            }
        </div>
    );
}