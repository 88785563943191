import React, { useState, useEffect } from "react";
import { Row, Col, message, Radio, Button, Drawer, Select, DatePicker, Space, TimePicker, Input, Form, Spin } from 'antd';
import Icon from "@ant-design/icons";
import userService from "../../../logics/services/user.service";
import apiService from "../../../logics/services/api.service";
import { websocketService, socket } from "../../../logics/services/websocket.service";
import "../../../logics/prototypes";
import questionAnswer from  '../../../assets/svg-icons/question_answer.svg';
import dateRange from  '../../../assets/svg-icons/date_range.svg';
import infoSvg from '../../../assets/svg-icons/info.svg';
import StarSvg from  '../../../assets/svg-icons/star.svg';
import StarFillSvg from  '../../../assets/svg-icons/star_fill.svg';
import DeleteSvg from '../../../assets/svg-icons/delete.svg';
import store from "../../../logics/store/case.store";
import CaseStatusIcon from "./CaseStatusIcon";
import moment from "moment";

const DateRange = props => <Icon component={dateRange} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const Info = props => <Icon component={infoSvg} style={{ fontSize: 20, verticalAlign: 'middle', float: 'right'}} {...props} />;
const QuestionAnswer = props => <Icon component={questionAnswer} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const StarIcon = props => <Icon component={StarSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const StarFillIcon = props => <Icon component={StarFillSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const DeleteIcon = props => <Icon component={DeleteSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginRight: '8px'}} {...props} />;
const { TextArea } = Input;
const { Option } = Select;

function CalanderView(props) {
    const onClose = () => {
        let element = document.getElementsByClassName('bottom-fixed-menu')[0];
        element.style.display = 'flex';
        props.closeCalenderView(false);
    }
    const user = userService.getUser();
    const caseInfo = store.getState().case;
    const [timeZoneDetails, setTimeZoneDetails] = useState([]);
    const [timeZoneId, setTimeZoneId] = useState('');
    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [videoMode, setVideoMode] = useState('default');    
    const [videoModeUrl, setVideoModeUrl] = useState(''); 
    const [form] = Form.useForm();
    const [caseParties, setCaseParties] = useState([]);
    const [selectedParties, setSelectedParties] = useState();
    const [selectedType, setSelectedType] = useState('meeting');
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [apId, setApId] = useState();
    const [partyList, setPartyList] = useState();
    const [organiser, setOrganiser] = useState([]);
    const [remainders, setRemainders] = useState([{
        value: null
    }]);

    const remainderList = [{
        text: '15 mins',
        value: 900
    },{
        text: '30 mins',
        value: 1800
    },{
        text: '45 mins',
        value: 2700
    },{
        text: '1 hrs',
        value: 3600
    },{
        text: '2 hrs',
        value: 7200
    },{
        text: '4 hrs',
        value: 14400
    },{
        text: '6 hrs',
        value: 21600
    },{
        text: '12 hrs',
        value: 43200
    },{
        text: '1 day',
        value: 86400
    },{
        text: '2 days',
        value: 172800
    },{
        text: '3 days',
        value: 259200
    },{
        text: '4 days',
        value: 345600
    },{
        text: '5 days',
        value: 432000
    }];
        
    useEffect(() => {
        let element = document.getElementsByClassName('bottom-fixed-menu')[0];
        element.style.display = 'none';
        apiService.getTimeZone().then((res) => {
            if (res.status === "success") {
                setTimeZoneDetails(res.data);
                res.data.filter(zone => {
                    let flag;
                    flag = zone.zone_name.toString().indexOf(user.timezone) > -1;
                    if(flag) {
                        setTimeZoneId(zone.zone_id);
                    }
                });
            }
        });

        let masterPartyList;
        apiService.getCaseParties({
            case_id: props.caseId,
        }).then((res) => {
            setLoading(false);
            if (res.status === "success") {
                masterPartyList = res.data.filter(e => e.u_id != user.id);
                setCaseParties(masterPartyList);
                setPartyList(Object.assign([], masterPartyList));
            }
        });

        if (props.edit && props.event) {
            setSelectedType(props.event.type);
            setTitle(props.event.title);
            setApId(props.event.ap_id);
            setStartDate(moment(props.event.start));
            setStartTime(props.event.edit_start_time ? moment(props.event.edit_start_time) : null);
            setEndDate(moment(props.event.end));
            setEndTime(props.event.edit_end_time ? moment(props.event.edit_end_time) : null);
            setVideoModeUrl(props.event.conference_url);
            setDescription(props.event.description);
            setVideoMode(props.event.conference_id ? 'default' : 'custom');
            setTimeZoneId(props.event.timezone);
            setSelectedParties(props.event.assignee?.map(x=>x.u_id));
            setOrganiser(props.event.organiser);

            //this.eventModel.assignee = this.event.assignee;
            //this.assigneeShowMore = this.eventModel.assignee?.length > 3 ? true : false;
            //this.assigneeSize = this.eventModel.assignee?.length > 3 ? 3 : this.eventModel.assignee?.length;

            setPartyList(Object.assign([], masterPartyList).filter(i => !props.event.assignee.includes(i.u_id)));
            
            if(props.event.conference_id) {
                //this.defaultUrl = '/app/case/meeting/'+videoMode;
                setVideoModeUrl(null);
            } else {
                setVideoModeUrl(props.event.conference_url);
                //this.defaultUrl = null;
            }
            if(props.event.notify_before) {
                let remainderArray = props.event.notify_before.split(',');
                var remainder = [];
                remainderArray.forEach(element => {
                    remainder.push({value:(element*60)});
                });
                setRemainders(remainder);
            } else {
                setRemainders([{
                    value: null
                }]);
            }
        }
    },[]);
    

    const createAppointment = () => {
        setLoading(true);
        setButtonLoading(true);
        let start_date = startDate?moment(startDate).year()+'-'+("0" + (moment(startDate).month()+1)).slice(-2)+'-'+("0" + moment(startDate).format('D')).slice(-2):null;
        let end_date = endDate?(moment(endDate).year()+'-'+("0" + (moment(endDate).month()+1)).slice(-2)+'-'+("0" + moment(endDate).format('D')).slice(-2)):null;
        let start_time = startTime?("0" + new Date(startTime).getHours()).slice(-2)+':'+("0" + new Date(startTime).getMinutes()).slice(-2)+':00':null;
        let end_time = endTime?(("0" + new Date(endTime).getHours()).slice(-2)+':'+("0" + new Date(endTime).getMinutes()).slice(-2)+':00'):null;
        
        let notify = [];
        remainders.forEach(element => {
            if(element.value)
                notify.push(((element.value)/60));
        });
        if(remainders) {
            let duplicates = notify?.reduce(function(acc, el, i, arr) {
            if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
            }, []);
            if (duplicates.length > 0) {
                message.error('Cannot set same reminder');
                setLoading(false);
                setButtonLoading(false);
                return;
            }
        }

        let currentDate1 = moment(new Date().getTime());
        if (selectedType == 'meeting') {
            let startDateTime = start_date + (start_time? 'T'+ start_time : '');
            let endDateTime = end_date + (end_time? 'T'+ end_time : '');
            let startPipeTime = moment(new Date(startDateTime).getTime());
            let endPipeTime = moment(new Date(endDateTime).getTime());
            if (startPipeTime >= endPipeTime) {
                message.error('Start Time must be greater than end time');
                setLoading(false);
                setButtonLoading(false);
                return;
            } else if (currentDate1 >=  startPipeTime) {
                message.error('Start Time must be greater than current time');
                setLoading(false);
                setButtonLoading(false);
                return;
            }
        } else if (selectedType == 'keydate') {
            if (start_time) {
                let startDateTime = start_date + (start_time? 'T'+ start_time : '');
                let startPipeTime =  moment(new Date(startDateTime).getTime());
                if(currentDate1 >=  startPipeTime) {
                    message.error('Time must be greater than current time');
                    setLoading(false);
                    setButtonLoading(false);
                    return;
                }
            }
            end_date = null;
            end_time = null;
        }

        let uniqueParticipant = selectedParties ? selectedParties?.filter((v, i, a) => a.indexOf(v) === i): [];
        if (!props.edit) {
            apiService.createAppointment({
                case_id: caseInfo.case_id,
                title: title,   
                start_date: start_date,
                end_date: end_date,
                start_time: start_time,
                end_time: end_time,
                assignee: uniqueParticipant?.join(','),
                timezone: timeZoneId,
                type: selectedType,
                description: description,
                conference: videoMode,
                conference_url: videoModeUrl,
                notification:notify?.join(',')
            }).then((res) => {
                setLoading(false);
                setButtonLoading(false);
                if (res.status === "success") {
                    message.success(res.message);
                    onClose();
                    websocketService.send({
                        method: 'calendar',
                        isDisplay: true,
                        data: {
                            users: uniqueParticipant,
                            content: res.instant_msg,
                            type: 'event-create'
                        }
                    });
                } else {
                    message.error(res.message)
                }
            });
        } else if(props.edit) {
            apiService.updateAppointment({
                case_id: caseInfo.case_id,
                title: title,
                start_date: start_date,
                end_date: end_date,
                start_time: start_time,
                end_time: end_time,
                assignee: uniqueParticipant?.join(','),
                timezone: timeZoneId,
                type: selectedType,
                description: description,
                conference: videoMode,
                conference_url: videoModeUrl,
                notification:notify?.join(','),
                ap_id: apId
            })
            .then(res => {
                setLoading(false);
                setButtonLoading(false);
                if (res.status == 'success') {
                    message.success(res.message);
                    onClose();
                    websocketService.send({
                        method: 'calendar',
                        isDisplay: true,
                        data: {
                            users: res.oldAssignee,
                            content: res.instant_msg,
                            type: 'event-update'
                        }
                    });
                } else {   
                    message.error(res.message)             
                }
            },() => {
                setLoading(false);
                setButtonLoading(false);
            });
        }
    }
               
    function desc(event) {
        setDescription(event.target.value);
    }

    function tit(event) {
        setTitle(event.target.value);
    }

    function startTi(event) {
        setStartTime(moment(event._d));
        if (event._d) {
            setEndTime(moment(new Date(event._d.getTime() + 30 *60000)));
        }
        if (event._d){   
            if (moment(event._d).format("HH") >= 23 && moment(event._d).format("mm") >= 30 ) {
                let start = new Date(startDate);
                setEndDate(moment(new Date(start.setDate(start.getDate() + 1))));
            } else {
                setEndDate(moment(startDate));
            }           
        } else {
            setEndDate(moment(startDate));
        }
    }

    function endTi(event) {
        setEndTime(moment(event._d));
    }

    function startDa(event) {
        setStartDate(moment(event._d));
        if (startTime){            
            if (moment(startTime).format("HH") >= 23 && moment(startTime).format("mm") >= 30 ) {
                let start = new Date(event._d);
                setEndDate(moment(new Date(start.setDate(start.getDate() + 1))));
            } else {
                setEndDate(moment(event._d));
            }           
        } else {
            setEndDate(moment(event._d));
        }
    }

    function endDa(event) {
        setEndDate(moment(event._d));
    }

    function videoSelect(e) {
        setVideoMode(e.target.value);
    }

    function videoUrl(e) {
        setVideoModeUrl(e.target.value);
    }

    function selectParties(value) {
        setSelectedParties(value);
    }

    function setEventType(value) {
        setSelectedType(value);
    }

    function setEventTimezone(value) {
        setTimeZoneId(value);
    }

    function handleEndTime() {
        if (this.eventModel.start_time) {
            this.eventModel.end_time = new Date(this.eventModel.start_time.getTime() + 30 *60000);
        }
        this.setEndDate();
    }

    function handleEndDate() {
        if (this.eventModel.start_time){            
            this.selectedDate = new Date(this.eventModel.start_time.getTime())
            if (this.selectedDate.getHours() >= 23 && this.selectedDate.getMinutes() >= 30 ) {
                let start = new Date(this.eventModel.start);
                this.eventModel.end = new Date(start.setDate(start.getDate() + 1));
            } else {
                this.eventModel.end = this.eventModel.start
            }           
        } else {
            this.eventModel.end = this.eventModel.start
        } 
    }

    function addRemainder() {
        if (remainders.length < 5) {
            setRemainders(remainders.concat({value: null}))
        } else {
            message.error('Maximum remainder reached.');
        }
    }

    function removeRemainder(i) {
        setRemainders(remainders => {
            let left = remainders.slice(0, i);
            let right = remainders.slice(i + 1);
            return [...left, ...right];
        })
    }

    function selectReminders(e, i) {
        let remain = remainders;
        let setRemain = [];
        remain.forEach((element, index) => {
            if(index == i) {
                setRemain = setRemain.concat({value: e});
            } else {
                setRemain = setRemain.concat({value: element.value});
            }
        });
        setRemainders(setRemain);
    }

    const fvalid = () => {
        let flag = false;
        if(!title || !title.trim()) {
                flag = true;
                return flag;
        }
        if(selectedType == 'meeting') {
            if(!startDate || !startTime) {
                flag = true;
            } else if(!endDate || !endTime) {
                flag = true;
            } else if(videoMode == 'custom' && (!videoModeUrl || !videoModeUrl.trim())) {
                flag = true;
            }
        } else if(selectedType == 'keydate') {
            if(!startDate) {
                flag = true;
            }
        }
        return flag; 
    }

    const dropdownChange = (e) => {
        if (e) {
            document.body.classList.add('dropdown-open');
        } else {
            document.body.classList.remove('dropdown-open');
        }
    }

    return (
        <Drawer
            title={props.edit ? "Edit Event": "Add New Event"}
            className="add-event-drawer"
            placement="top"
            height={"100%"}
            visible={props.visible}
            onClose={onClose}
            >
                <Row className="sub-header case-name-header">
                    <Col span={24} className="case-name-bar">
                        <CaseStatusIcon caseId={caseInfo.case_id} case_status={caseInfo.case_status} case_type={caseInfo.case_type} size={32}/>
                        <span className="pl16 case-name">{ caseInfo?.case_name }</span>
                        <span className="pl8">
                            { !caseInfo?.isFav ?<StarIcon />:<StarFillIcon /> }
                        </span>
                    </Col>
                </Row>
                <Spin spinning={loading} delay={500}>
                    <div className="event-container">
                        <p className="event-type">Event Type</p>
                        <button className={(selectedType == "meeting"?"meet-btn selected":"meet-btn")} onClick={() => setEventType('meeting')}><QuestionAnswer/>Meeting</button>
                        <button className={(selectedType == "keydate"?"key-btn selected":"key-btn")} onClick={() => setEventType('keydate')}><DateRange/>Key Date</button>
                        {/* <span className="info"><Info/></span> */}
                        <Form form={form} layout="vertical" className="event-form">
                            <Row>
                                <Col span={24}>
                                    <p className="meet-title">Event Title</p>
                                    <Input placeholder={"Event Title"} onChange={tit} maxLength="100" value={title}/>
                                </Col>
                            </Row>  

                            {   selectedType == "meeting" &&
                                (
                                    <>
                                        <Row>
                                            <Col>
                                                <p className="date-time-head">Date & Time</p>
                                                <div className="time-selector">
                                                    <Row>
                                                        <Col span={12}>
                                                            <DatePicker value={startDate} onChange={startDa} onOpenChange={(e) => dropdownChange(e)} format={"DD MMM YYYY"} />
                                                        </Col>
                                                        <Col span={12}>
                                                            <TimePicker value={startTime} onChange={startTi} use12Hours format="h:mm A" minuteStep={15} className="timer" onOpenChange={(e) => dropdownChange(e)}></TimePicker>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p className="event-type">To</p>
                                                <Row>
                                                    <Col span={12}>
                                                        <DatePicker value={endDate} onChange={endDa} onOpenChange={(e) => dropdownChange(e)} format={"DD MMM YYYY"}/>
                                                    </Col>
                                                    <Col span={12}>
                                                        <TimePicker value={endTime} onChange={endTi} use12Hours format="h:mm A" minuteStep={15} className="timer" onOpenChange={(e) => dropdownChange(e)}></TimePicker>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </>
                                )
                            }
                            {   selectedType == "keydate" &&
                                (
                                    <Row>
                                        <Col span={12}>
                                            <p className="date-time-head">Date</p>
                                            <div className="time-selector">
                                                <DatePicker value={startDate} onChange={startDa} format={"DD MMM YYYY"}/>
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <p className="event-type">Time <span>(Optional)</span></p>
                                            <TimePicker value={startTime} onChange={startTi} use12Hours format="h:mm A" minuteStep={15} className="timer"></TimePicker>
                                        </Col>
                                    </Row>
                                )
                            }
                            {   timeZoneId &&
                                <Row>
                                    <Col className="timeZone">
                                        <Row>
                                            <Col span={6} className="label">
                                                <label>Time Zone:</label>
                                            </Col>
                                            <Col span={18}>
                                                <Select defaultValue={timeZoneId} value={timeZoneId} name="Timezone" onChange={setEventTimezone} bordered={false} onDropdownVisibleChange={(e) => dropdownChange(e)}>
                                                    { timeZoneDetails && timeZoneDetails.length > 0 
                                                        ? timeZoneDetails.map((zone, index) => (
                                                            <React.Fragment key={"timezone_"+index}>
                                                                <Option value={zone.zone_id}>(UTC {zone.offset}) {zone.zone_name}</Option>
                                                            </React.Fragment>
                                                        ))
                                                        :
                                                            <></>
                                                    }
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            }
                            
                            {/* <p className="date-time-head">Date & Time</p>
                            <div className="time-selector">
                                <DatePicker onChange={startDa} />
                                <TimePicker onChange={startTi} use12Hours format="h:mm A" minuteStep={15} className="timer"></TimePicker>
                            </div>
                            <div>
                                <p className="event-type">To</p>
                                <DatePicker onChange={endDa}/>
                                <TimePicker onChange={endTi} use12Hours format="h:mm A" minuteStep={15} className="timer"></TimePicker>
                            </div> */}
                            {/* <Form.Item label="Time Zone" name="Timezone" className="w100 mb0">
                                <Select
                                    // defaultValue={timeZoneId}
                                    style={{width: '100%'}}
                                    dropdownMatchSelectWidth={false}
                                    placeholder="Select"
                                    onChange={setEventTimezone} 
                                    optionLabelProp={'label'}
                                >
                                    {timeZoneDetails.map((item, index) => (
                                        <Select.Option value={item.zone_id} label={"(UTC " + item.offset + ") " + item.zone_name} key={"zone_"+index}>
                                            {"(UTC " + item.offset + ") " + item.zone_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item> */}
                            <Row>
                                <Col span={24}>
                                    <p>Participants {selectedType == "keydate" ? <span>(Optional)</span>: ''}</p>
                                    <Select
                                        dropdownMatchSelectWidth={false}
                                        placeholder="Select"
                                        mode="multiple"
                                        allowClear 
                                        showArrow
                                        onChange={selectParties}
                                        name="participants"
                                        value={selectedParties}
                                        onDropdownVisibleChange={(e) => dropdownChange(e)}
                                    >
                                        {caseParties.map((item, index) => (
                                        <Select.Option value={item.u_id} key={"party_select_"+index}>
                                            {item.name}
                                        </Select.Option>
                                        ))}
                                    </Select>              
                                </Col>
                            </Row>
                            {   props.edit &&
                                (
                                    <Row>
                                        <Col>
                                            <p>Organizer</p>
                                            <h5>{ organiser.name }</h5>
                                            <span>{ organiser.role_name }</span>             
                                        </Col>
                                    </Row>
                                )
                            }
                            {   selectedType == "meeting" &&
                                (
                                    <Row>
                                        <Col span={24}>
                                            <p className="conf-head">Conferencing</p>
                                            <Radio.Group value={videoMode} onChange={videoSelect}>
                                                <Space direction="vertical">
                                                    <Radio value={'default'} >Add RDO Video Conferencing</Radio>
                                                    <Radio value={'custom'}><Input value={videoModeUrl} placeholder="Add your custom meeting link" disabled={videoMode == 'custom'?false:true} className="custom-link" onChange={videoUrl}/></Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Col>
                                    </Row>
                                )
                            }
                            <Row>
                                <Col span={24}>
                                    <p className="event-type">Description (Optional)</p>
                                    <TextArea className="txt-area" onChange={desc} value={description}></TextArea>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <p className="event-type">Reminder (Optional)</p>
                                    {remainders.length < 4 && (<a className="ev-add-remainder" onClick={() => addRemainder()}> + Add Reminder </a>)}
                                    {  remainders && remainders.length > 0 &&
                                        (
                                            <div>
                                                { remainders.map((item, i) => {
                                                    return (
                                                        <Row key={"reminder_key_"+i}>
                                                            <Col span={24}>
                                                                <Select
                                                                    placeholder="Select"
                                                                    name="reminder"
                                                                    onChange={(e) => selectReminders(e, i)}
                                                                    value={item.value}
                                                                    key={i}
                                                                    onDropdownVisibleChange={(e) => dropdownChange(e)}
                                                                >
                                                                    {remainderList.map((remainder, index) => (
                                                                        <Select.Option value={remainder.value} key={"reminder_select_"+index}>
                                                                            {remainder.text}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                                {i != 0 && (<DeleteIcon onClick={() => removeRemainder(i)} />)}
                                                            </Col>
                                                        </Row>
                                                    )
                                                })}
                                            </div>
                                        )
                                    }
                                </Col>
                            </Row>


                            {/* <Form.Item>
                                <Form.Item label="Participants" name="parties" className="w100 mb0">
                                    <Select
                                        style={{ width: '100%' }}
                                        dropdownMatchSelectWidth={false}
                                        placeholder="Select"
                                        mode="multiple"
                                        allowClear 
                                        onChange={selectParties}
                                    >
                                        {caseParties.map((item, index) => (
                                            <Select.Option value={item.u_id} key={index}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Form.Item>
                            <p className="conf-head"> Conferencing</p>
                            <Radio.Group value={videoMode} onChange={videoSelect}>
                                <Space direction="vertical">
                                    <Radio value={'default'} >Add RDO Video Conferencing</Radio>
                                    <Radio value={'custom'}><Input placeholder="Add your custom meeting link" disabled={videoMode == 1?false:true} className="custom-link" onChange={videoUrl}/></Radio>
                                </Space>
                            </Radio.Group>
                            <p className="event-type">Description (Optional)</p>
                            <TextArea className="txt-area" onChange={desc}></TextArea> */}
                        </Form>
                    </div>
                    <Row gutter={32} className="event-footer">
                        <Col span={12}>
                            <Button type="default" onClick={onClose}>Cancel</Button>
                        </Col>
                        <Col span={12}>                    
                            <Button type="primary" className="invite-btn" disabled={fvalid()} loading={buttonLoading} onClick={() => createAppointment()}>{ props.edit?'Update':'Submit'}</Button>
                        </Col>
                    </Row>
                </Spin>
        </Drawer>
    );       
}
export default CalanderView;