import React, { useState, useEffect } from "react";
import userService from "../../../logics/services/user.service";
import apiService from "../../../logics/services/api.service";
import { Row, Col, Steps, Tabs, Input, Menu, Dropdown, Button, Checkbox, Card, Image, Upload, Progress, Avatar, Drawer, Space, Select, message, Spin, Modal } from 'antd';
import Icon from '@ant-design/icons';
import ArbitrationSvg from  '../../../assets/svg-icons/arbitration.svg';
import StarSvg from  '../../../assets/svg-icons/star.svg';
import StarFillSvg from  '../../../assets/svg-icons/star_fill.svg';

import CaseCreateSvg from  '../../../assets/svg-icons/case_create.svg';
import AwaitingResponseSvg from  '../../../assets/svg-icons/awaiting_response.svg';
import CaseInvitationResponseSvg from  '../../../assets/svg-icons/case_invitation_response.svg';
import CaseDiscussionSvg from  '../../../assets/svg-icons/case_discussion.svg';
import DiscussionSvg from  '../../../assets/svg-icons/discussion.svg';
import CaseMediationSvg from  '../../../assets/svg-icons/case_mediation.svg';
import MediationSvg from  '../../../assets/svg-icons/mediation.svg';
import CaseDecisionSvg from  '../../../assets/svg-icons/case_dc.svg';
import DecisionSvg from  '../../../assets/svg-icons/case-pad.svg';
import CaseClosedSvg from  '../../../assets/svg-icons/case_closed.svg';
import ClosedSvg from  '../../../assets/svg-icons/closed.svg';
import store from "../../../logics/store/case.store";
import { HelpOutlineIcon } from "../../../logics/images";

const ArbitrationIcon = props => <Icon component={ArbitrationSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const StarIcon = props => <Icon component={StarSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const StarFillIcon = props => <Icon component={StarFillSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;

const CaseCreateIcon = props => <Icon component={CaseCreateSvg} style={{ fontSize: 32, verticalAlign: 'middle'}} {...props} />;
const AwaitingResponseIcon = props => <Icon component={AwaitingResponseSvg} style={{ fontSize: 32, verticalAlign: 'middle'}} {...props} />;
const CaseInvitationResponseIcon = props => <Icon component={CaseInvitationResponseSvg} style={{ fontSize: 32, verticalAlign: 'middle'}} {...props} />;
const CaseDiscussionIcon = props => <Icon component={CaseDiscussionSvg} style={{ fontSize: 32, verticalAlign: 'middle'}} {...props} />;
const DiscussionIcon = props => <Icon component={DiscussionSvg} style={{ fontSize: 32, verticalAlign: 'middle'}} {...props} />;
const CaseMediationIcon = props => <Icon component={CaseMediationSvg} style={{ fontSize: 32, verticalAlign: 'middle'}} {...props} />;
const MediationIcon = props => <Icon component={MediationSvg} style={{ fontSize: 32, verticalAlign: 'middle'}} {...props} />;
const CaseDecisionIcon = props => <Icon component={CaseDecisionSvg} style={{ fontSize: 32, verticalAlign: 'middle'}} {...props} />;
const DecisionIcon = props => <Icon component={DecisionSvg} style={{ fontSize: 32, verticalAlign: 'middle'}} {...props} />;
const CaseClosedIcon = props => <Icon component={CaseClosedSvg} style={{ fontSize: 32, verticalAlign: 'middle'}} {...props} />;
const ClosedIcon = props => <Icon component={ClosedSvg} style={{ fontSize: 32, verticalAlign: 'middle'}} {...props} />;
const { TextArea } = Input;

class CaseStatus extends React.Component {
    constructor(props) {
        super(props);
        this.caseId=this.props.caseId;
        this.visible=this.props.visible;
        this.state = {
            caseInfo: store.getState().case,
            loading: false,
            caseStatusVisible: this.visible,
            confirm: false,
            buttonDisabled: true,
            showCloseInfo: false
        };
    }

    componentDidMount = async () => {
        this.user = userService.getUser();
        let element = document.getElementsByClassName('bottom-fixed-menu')[0];
        element.style.display = 'none';
    };

    onClose = () => {
        this.setState({caseStatusVisible: false});
        this.props.closeCaseStatusVisible(false);
        let element = document.getElementsByClassName('bottom-fixed-menu')[0];
        element.style.display = 'flex';
    };

    setShowCloseInfo = () => {
        this.setState({ showCloseInfo: true });
    }

    setHideCloseInfo = () => {
        this.setState({ showCloseInfo: false });
    }

    render() {
        const { caseStatusVisible, buttonDisabled, loading, caseInfo, showCloseInfo } = this.state;
        const { Step } = Steps;
        const index = this.state.index;
        const setIndex = (params) => this.setState({index: params});
        const caseCreation = (
            <span className="status-case-create">
                <CaseCreateIcon />
                <div className="head">
                    <span className="heading">Case Creation</span>
                    <span className="status">Completed</span>
                </div>
            </span>
        );

        const businessResponse = (
            <span className="status-case-create">
                {(caseInfo.case_status==1 || caseInfo.case_status==2) 
                    ? <CaseInvitationResponseIcon />
                    : <AwaitingResponseIcon />
                }
                <div className="head">
                    <span className="heading response">Case Invitations</span>
                    {(caseInfo.case_status==1) && (<span className="status business">Awaiting Response</span>)}
                    {(caseInfo.case_status==2) && (<span className="status business">Awaiting Admin allocation</span>)}
                    {(caseInfo.case_status!=1 && caseInfo.case_status!=2 && caseInfo.case_sub_status!=4) && (<span className="status business">Completed</span>)}
                    {(caseInfo.case_status==5 && caseInfo.case_sub_status==4) && (<span className="status business">Rejected</span>)}
                </div>
            </span>
        );

        const directDialogue = (
            <span className="status-case-create">
                {(caseInfo.case_status==3 && caseInfo.case_type==1) 
                    ? <CaseDiscussionIcon />
                    : <DiscussionIcon />
                }
                <div className="head">
                    <span className="heading" style={{ color: (caseInfo.case_status == 3 && caseInfo.case_type==1) ? 'rgba(19, 103, 150, 1)' : 'rgba(90, 90, 90, 1)'}}>{ caseInfo.allRes[0].rc_name }</span>
                    {(caseInfo.case_status == 3 && caseInfo.case_type == 1) && (<span className="status">In Progress</span>)}
                    {((caseInfo.case_status >= 3 && caseInfo.case_type != 1) || (caseInfo.case_status==5 && caseInfo.case_sub_status != 1 && caseInfo.case_sub_status != 2)) && (<span className="status">Unsuccessful</span>)}
                    {((caseInfo.case_status == 4 && caseInfo.case_type == 1) || (caseInfo.case_status == 5 && (caseInfo.case_sub_status == 1 || caseInfo.case_sub_status == 2)) && caseInfo.case_type == 1) && (<span className="status">Completed</span>)}
                </div>
            </span>
        );

        const mediationTemplate = (
            <span className="status-case-create">
                {(caseInfo.case_status==3 && caseInfo.case_type==2) 
                    ? <CaseMediationIcon />
                    : <MediationIcon />
                }
                <div className="head">
                    <span className="heading" style={{ color: (caseInfo.case_status==3 && caseInfo.case_type==2) ? 'rgba(19, 103, 150, 1)' : 'rgba(90, 90, 90, 1)'}}>{ caseInfo.allRes[1].rc_name }</span>
                    {(caseInfo.case_status==3 && caseInfo.case_type==2) && (<span className="status">In Progress</span>)}
                    {((caseInfo.case_status >= 3 && caseInfo.case_type > 2) || (caseInfo.case_type==2 && caseInfo.case_status == 5 && caseInfo.case_sub_status != 1 && caseInfo.case_sub_status != 2)) && (<span className="status">Unsuccessful</span>)}
                    {((caseInfo.case_status == 4 && caseInfo.case_type==2) || (caseInfo.case_status == 5 && (caseInfo.case_sub_status == 1 || caseInfo.case_sub_status == 2)) && caseInfo.case_type==2) && (<span className="status">Completed</span>)}
                </div>
            </span>
        );

        const caseDecision = (
            <span className="status-case-create">
                {(caseInfo.case_status==3 && caseInfo.case_type==3) 
                    ? <CaseDecisionIcon />
                    : <DecisionIcon />
                }
                <div className="head">
                    <span className="heading" style={{ color: (caseInfo.case_status==3 && caseInfo.case_type==3) ? 'rgba(19, 103, 150, 1)' : 'rgba(90, 90, 90, 1)'}}>{ caseInfo.allRes[2].rc_name }</span>
                    {(caseInfo.case_status==3 && caseInfo.case_type==3) && (<span className="status">In Progress</span>)}
                    {(caseInfo.case_type == 3 && caseInfo.case_status == 5 && caseInfo.case_sub_status != 1 && caseInfo.case_sub_status != 2) && (<span className="status">Unsuccessful</span>)}
                    {((caseInfo.case_status == 4 && caseInfo.case_type == 3) || (caseInfo.case_status == 5 && (caseInfo.case_sub_status == 1 || caseInfo.case_sub_status == 2)) && caseInfo.case_type == 3) && (<span className="status">Completed</span>)}
                </div>
            </span>
        );

        const caseClosed = (
            <span className="status-case-create">
                {(caseInfo.case_status==4 || caseInfo.case_status==5 || caseInfo.case_status==6 || caseInfo.case_status==7 || caseInfo.caseuserstatus?.s_id == 5) && (<CaseClosedIcon />)}
                {(caseInfo.case_status==1 || caseInfo.case_status==2|| caseInfo.case_status==3) && caseInfo.caseuserstatus?.s_id != 5 && (<ClosedIcon />)}
                <div className="head">
                    <span className="heading" style={{ color: (caseInfo.case_status==4 || caseInfo.case_status==5 || caseInfo.case_status==6 || caseInfo.case_status==7 || caseInfo.caseuserstatus?.s_id == 5) ? '#27AE60' : '#999'}}>Case Closed</span>
                    {(caseInfo.case_status==1 || caseInfo.case_status==2 || caseInfo.case_status==3) && caseInfo.caseuserstatus?.s_id != 5 && (<span className="status" style={{ color: (caseInfo.case_status==4 || caseInfo.case_status==5 || caseInfo.case_status==6 || caseInfo.case_status==7 || caseInfo.caseuserstatus?.s_id == 5) ? '#27AE60' : '#999'}}>Not Yet</span>)}
                    {(caseInfo.case_status==4 || caseInfo.case_status==5 || caseInfo.case_status==6 || caseInfo.case_status==7 || caseInfo.caseuserstatus?.s_id == 5) && (<span className="status" style={{ width: 'calc(100% - 80px)' }}>{ caseInfo.caseuserstatus?.s_id == 5?caseInfo.caseusersubstatus.s_label:caseInfo.casesubstatus.s_label }</span>)}
                    {(caseInfo.case_status==4 || caseInfo.case_status==5 || caseInfo.case_status==6 || caseInfo.case_status==7 || caseInfo.caseuserstatus?.s_id == 5) && (caseInfo.casesubstatus?.cs_s_id != 1 && caseInfo.casesubstatus?.cs_s_id != 2 && caseInfo.casesubstatus?.cs_s_id != 10 && caseInfo.caseusersubstatus?.cs_s_id != 1 && caseInfo.caseusersubstatus?.cs_s_id != 2 && caseInfo.caseusersubstatus?.cs_s_id != 10) && (<span style={{ position: 'absolute', right: 0}} onClick={this.setShowCloseInfo}><HelpOutlineIcon /></span>)}
                </div>
            </span>
        );
        return (
            <div>
                <Drawer
                    title="Case Status"
                    className="custom-drawer-popup outcome-view-drawer"
                    placement="top"
                    height={"100%"}
                    onClose={this.onClose}
                    visible={caseStatusVisible}
                    >
                        <Spin spinning={loading} delay={500}>
                            <Row className="sub-header case-name-header">
                                <Col span={20} className="case-name-bar">
                                    {caseInfo?.case_status == 0 && (<CaseCreateIcon/>)}
                                    {(caseInfo?.case_status == 1 || caseInfo?.case_status == 2) && (<CaseInvitationResponseIcon/>)}
                                    {(caseInfo?.case_status == 3 && caseInfo?.case_type == 1) && (<CaseDiscussionIcon/>)}
                                    {(caseInfo?.case_status == 3 && caseInfo?.case_type == 2) && (<CaseMediationIcon/>)}
                                    {(caseInfo?.case_status == 3 && caseInfo?.case_type == 3) && (<CaseDecisionIcon/>)}
                                    {(caseInfo?.case_status == 4 || caseInfo?.case_status == 5 || caseInfo?.case_status == 6 || caseInfo?.case_status == 7) && (<CaseClosedIcon/>)}
                                    <span className="pl16 case-name"><b>{caseInfo ? caseInfo.case_name:''}</b></span>
                                    <span className="pl8">
                                        {(caseInfo && caseInfo.isFav != 1) && (<StarIcon />)}
                                        {(caseInfo && caseInfo.isFav == 1) && (<StarFillIcon />)}
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="status-card">
                                    <Steps current={index} onChange={setIndex} progressDot>
                                        <Step description={caseCreation} className={caseInfo.case_status!=0?"tail":""}/>
                                        <Step description={businessResponse} className={(caseInfo.case_status==1 || caseInfo.case_status==2)?"inprogress":((caseInfo.case_status!=1 || caseInfo.case_status!=2)?"completed":"")}/>
                                        {((caseInfo.resBanner && caseInfo.resBanner.isNeg) && caseInfo.case_status != 1 && caseInfo.case_status != 2 && caseInfo.case_sub_status!=4) && (<Step description={directDialogue} className={(caseInfo.case_status==3 && caseInfo.case_type == 1)?"inprogress":((caseInfo.case_status==4 || caseInfo.case_status==5 || caseInfo.case_status==6 || caseInfo.case_type != 1)?"completed":"")}/>) }
                                        {((caseInfo.resBanner && caseInfo.resBanner.isMed) && caseInfo.case_status != 1 && caseInfo.case_status != 2 && caseInfo.case_type != 1 && caseInfo.case_sub_status!=4) && (<Step description={mediationTemplate} className={(caseInfo.case_status==3 && caseInfo.case_type == 2)?"inprogress":((caseInfo.case_status==4 || caseInfo.case_status==5 || caseInfo.case_status==6 || caseInfo.case_status==7 || caseInfo.case_type > 2)?"completed":"")}/> )}
                                        {((caseInfo.resBanner && caseInfo.resBanner.isArb) && caseInfo.case_status != 1 && caseInfo.case_status != 2 && caseInfo.case_type == 3 && caseInfo.case_sub_status!=4) && (<Step description={caseDecision} className={(caseInfo.case_status==3)?"inprogress":((caseInfo.case_status==4 || caseInfo.case_status==5 || caseInfo.case_status==6 || caseInfo.case_status==7)?"completed":"")}/>)}
                                        <Step description={caseClosed} className={(caseInfo.case_status==1 || caseInfo.case_status==2 || caseInfo.case_status==3)?"inprogress progress":((caseInfo.case_status==4 || caseInfo.case_status==5 || caseInfo.case_status==6 || caseInfo.case_status==7)?"completed closed":"")}/>
                                    </Steps>
                                </Col>
                            </Row>
                        </Spin>
                </Drawer>
                <Modal
                    visible={showCloseInfo}
                    onCancel={this.setHideCloseInfo}
                    className="close-reason-modal"
                    footer={null}
                    title={ caseInfo?.case_sub_status == 3 ? 'Withdrawal Reason' : (caseInfo?.case_sub_status == 4 ? 'Rejected Reason' : 'Case Closed Reason') }
                >
                    {((caseInfo.case_status==4 || caseInfo.case_status==5 || caseInfo.case_status==6 || caseInfo.case_status==7) && caseInfo.casesubstatus.cs_s_id != 1 && caseInfo.casesubstatus.cs_s_id != 2 && caseInfo?.close_reason) &&
                    (<div className="user-info">
                        <Avatar src={caseInfo?.close_reason.user_image}>{caseInfo?.close_reason.p_name}</Avatar>
                        <div className="user-name">
                            { caseInfo?.close_reason.name }
                            <p className="user-position">
                                { caseInfo?.close_reason.role_name }
                            </p>
                            <p className="last-activity">
                                { caseInfo?.case_sub_status == 3?'Withdrawn on':(caseInfo?.case_sub_status == 4 ?'Reject on': 'Closed on') } { (caseInfo?.close_reason.updated_at).utcLocal().dateTimeFormat() }
                            </p>
                        </div>
                        { caseInfo &&
                        (<div>
                            <p className="reason-title">
                                Reason: <span>{ caseInfo.case_sub_status == 3 ? caseInfo.close_reason.withdraw_reason : (caseInfo.case_sub_status == 4 ? caseInfo.close_reason.reject_reason : caseInfo.close_reason.closed_reason) }</span>
                            </p>
                            { (caseInfo.close_reason?.withdraw_comment || caseInfo.close_reason?.reject_comment || caseInfo.close_reason?.closed_comment) && (<p className="reason-title">Comments:</p>)}
                            { (caseInfo.close_reason?.withdraw_comment != '' && caseInfo.close_reason?.withdraw_comment != null) && (<TextArea className="withdraw-reason" readOnly value={caseInfo.close_reason.withdraw_comment}/>)}
                            { (caseInfo.close_reason?.reject_comment != '' && caseInfo.close_reason?.reject_comment  != null) && (<TextArea className="withdraw-reason" readOnly value={caseInfo.close_reason.reject_comment}/>)}
                            { (caseInfo.close_reason?.closed_comment != '' && caseInfo.close_reason?.closed_comment  != null) && (<TextArea className="withdraw-reason" readOnly value={caseInfo.close_reason.closed_comment}/>)}
                        </div>)}
                    </div>)}
                </Modal>
            </div>
        );
    }
}
export default CaseStatus;