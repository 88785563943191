import userService from "./user.service";
import apiService from "./api.service";
const ws = new WebSocket(window['odrWsUrl']);
export const socket = ws;

class WebsocketService {
    constructor() {
        this.caseInfo=null;
    }

    send(data) {
        if(data.caseId) {
            apiService.getSingleCase({
                case_id: data.caseId
            }).then((res) => {
                if (res.status === 'success') {
                    this.caseInfo = res.data;
                    if (this.caseInfo && !data.data.customCase) {
                        data.data.case_name = this.caseInfo?.case_name;
                        data.data.case_id = this.caseInfo?.case_id;

                        this.sendJsonData(data);
                    } else {
                        this.sendJsonData(data);
                    }
                }
            });
        } else {
            this.sendJsonData(data);
        }
    }

    sendJsonData(data) {
        const user = userService.getUser();
        if(data.data.time_display) {
            data.data.time = new Date();
        }
        if (!data.data.customFrom) {
            data.data.from = {
                'u_id': user.id,
                'p_name': user.p_name,
                'user_image': user.image,
                'color': user.color,
                'name': user.name,      
                'role_name': user.role_name      
            }
        }
        ws.send(JSON.stringify(JSON.stringify(data)));
    }
}
export const websocketService = new WebsocketService();