import React, { useState, useEffect } from "react";
import { Row, Col, message, Radio, Avatar,Button, Drawer, Select, Spin, Checkbox, Card, Menu, Dropdown, Popconfirm,DatePicker,Space,TimePicker,Input  } from 'antd';
import {
    useRouteMatch,
    Switch,
    Link,
    useParams,
    useHistory
} from "react-router-dom";
import Icon from "@ant-design/icons";
import userService from "../../../logics/services/user.service";
import apiService from "../../../logics/services/api.service";
import CaseCalendar from 'react-calendar';
import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';
import "../../../logics/prototypes";
import { websocketService, socket } from "../../../logics/services/websocket.service";
import { UserOutlined,DownOutlined  } from '@ant-design/icons';
import { APIURL } from '../../../constants';

import AddEventCalander from "../../../pages/app/CaseView/CalanderView";
import ArbitrationSvg from  '../../../assets/svg-icons/arbitration.svg';
import AddSvg from "../../../assets/svg-icons/add.svg";
import SettingSvg from "../../../assets/svg-icons/settings.svg";
import MenuDropdownSvg from  '../../../assets/svg-icons/keyboard_arrow_down.svg';
import questionAnswer from  '../../../assets/svg-icons/question_answer.svg';
import assignment from  '../../../assets/svg-icons/assignment.svg';
import help from  '../../../assets/svg-icons/help_outline.svg';
import dateRange from  '../../../assets/svg-icons/date_range.svg';
import StarSvg from  '../../../assets/svg-icons/star.svg';
import more_vert from  '../../../assets/svg-icons/more_vert.svg';
import Copy from  '../../../assets/svg-icons/copy.svg';
import ShapeSvg from  '../../../assets/svg-icons/shape.svg';
import meet_waiting from  '../../../assets/svg-icons/meet_waiting.svg';
import meet_maybe from  '../../../assets/svg-icons/meet_maybe.svg';
import meetNotAttend from  '../../../assets/svg-icons/meet_not_attend.svg';
import MenuUpSvg from  '../../../assets/svg-icons/arrow_down.svg';
import SandClockSvg from  '../../../assets/svg-icons/sand-clock.svg';
import Check24pxSvg from  '../../../assets/svg-icons/check_24px.svg';
import MaybeSvg from  '../../../assets/svg-icons/maybe.svg';
import OpenInNewSvg from  '../../../assets/svg-icons/open_in_new.svg';
import infoSvg from '../../../assets/svg-icons/info.svg';
import StarFillSvg from  '../../../assets/svg-icons/star_fill.svg';
import CaseStatusIcon from "./CaseStatusIcon";
import store from "../../../logics/store/case.store";
import { NoEventsIcon } from "../../../logics/images";

const SettingIcon = props => <Icon component={SettingSvg} style={{ fontSize: 24, verticalAlign: 'middle', float: 'right'}} {...props} />;
const AddIcon = props => <Icon component={AddSvg} style={{ fontSize: 24, verticalAlign: 'middle', float: 'right', padding: '0px 10px 0px 0px'}} {...props} />;
const MenuDropdownIcon = props => <Icon component={MenuDropdownSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const QuestionAnswer = props => <Icon component={questionAnswer} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const Assignment = props => <Icon component={assignment} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const Help = props => <Icon component={help} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const DateRange = props => <Icon component={dateRange} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const arbitrationSvg = props => <Icon component={arbitrationSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const StarIcon = props => <Icon component={StarSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const More_vert = props => <Icon component={more_vert} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const copy = props => <Icon component={copy} style={{ fontSize: 36, verticalAlign: 'middle'}} {...props} />;
const ShapeIcon = props => <Icon component={ShapeSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const Waiting = props => <Icon component={meet_waiting} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const Maybe = props => <Icon component={meet_maybe} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const NotAttending = props => <Icon component={meetNotAttend} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const MenuUpIcon = props => <Icon component={MenuUpSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const SandClockIcon = props => <Icon component={SandClockSvg} style={{ fontSize: 20, verticalAlign: 'middle', float: 'right'}} {...props} />;
const Check24pxIcon = props => <Icon component={Check24pxSvg} style={{ fontSize: 20, verticalAlign: 'middle', float: 'right'}} {...props} />;
const MaybeIcon = props => <Icon component={MaybeSvg} style={{ fontSize: 20, verticalAlign: 'middle', float: 'right'}} {...props} />;
const OpenInNewIcon = props => <Icon component={OpenInNewSvg} style={{ fontSize: 20, verticalAlign: 'middle', float: 'right'}} {...props} />;
const Info = props => <Icon component={infoSvg} style={{ fontSize: 20, verticalAlign: 'middle', float: 'right'}} {...props} />;
const StarFillIcon = props => <Icon component={StarFillSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;

const { Option } = Select;
const { TextArea } = Input;

function Calendar() {
    let { caseId, caseDetailView } = useParams();
    const user = userService.getUser();
    const caseInfo = store.getState().case;
    const history = useHistory();
    const [detailLink, setDetailLink] = useState(caseDetailView);
    let link = caseDetailView ? JSON.parse(atob(caseDetailView)): '';
    if(detailLink != caseDetailView) {
        setDetailLink(caseDetailView);
    }
    const [loading, setLoading] = useState(true);
    const [viewDate, setViewDate] = useState(new Date());
    const [eventDays, setEventDays] = useState([]);
    const [events, setEvents] = useState([]);
    const [groupEvents, setGroupEvents] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [eventDetail, setEventDetail] = useState([]);
    const [agentaDrawer, setAgentaDrawer] = useState(false);
    const [filter, setFilter] = useState(false);
    const [filterEventData, setFilterEventData] = useState([]);
    const [meeting, setMeeting] = useState(false);
    const [keydate, setKeydate] = useState(false);
    const [task, setTask] = useState(false);
    const [questionnaire, setQuestionnaire] = useState(false);
    const [timeZoneId, setTimeZoneId] = useState(0);
    const [timeZones, setTimeZones] = useState([]);
    const [timeZoneName, setTimeZoneName] = useState('');
    const [zoneOffset, setZoneOffset] = useState('');
    const [timeZoneMomentDate, setTimeZoneMomentDate] = useState('');
    const [timeZoneStartTime, setTimeZoneStartTime] = useState('');
    const [timeZoneEndTime, setTimeZoneEndTime] = useState('');
    const [meetingStatus, setMeetingStatus] = useState(false);
    const [eventDrawer, setEventDrawer] = useState(false);
    const [CalanderView, setAddEventDrawer] = useState(false);
    const [isEditEvent, setIsEditEvent] = useState(false);
    const [edate, setDate] = useState(new Date());

    useEffect(() => {
        getTimeZones();
        getEvents();
    },[]); //detailLink, eventDetail, viewDate

    useEffect(() => {
        if (eventDetail && eventDetail.timezone && eventDrawer && timeZones.length > 0) {
            setTimeZoneId(eventDetail.timezone);
            let timeZoneMoments;
            let timeZoneMomentStart;
            let timeZoneMomentEnd;
            timeZones.filter(zone => {
                if (zone.zone_id == eventDetail.timezone) {
                    setTimeZoneName(zone.zone_name);
                    setZoneOffset(zone.offset);
                    timeZoneMoments = momentTimeZone.utc(eventDetail.start).tz(zone.zone_name).format("dddd, D MMM YYYY");
                    timeZoneMomentStart = momentTimeZone.utc(eventDetail.start).tz(zone.zone_name).format("hh:mm A");
                    timeZoneMomentEnd = eventDetail.end && momentTimeZone.utc(eventDetail.end).tz(zone.zone_name).format("hh:mm A");
                }
            });
            timeZoneMoments = timeZoneMoments != '' ? timeZoneMoments: moment.utc(eventDetail.start).local().format("dddd, D MMM YYYY");
            timeZoneMomentStart = timeZoneMomentStart != '' ? timeZoneMomentStart: moment.utc(eventDetail.start).local().format("hh:mm A");
            timeZoneMomentEnd = timeZoneMomentEnd != '' ? timeZoneMomentEnd: eventDetail.end ? moment.utc(eventDetail.end).local().format("hh:mm A"): '';
            setTimeZoneMomentDate(timeZoneMoments);
            setTimeZoneStartTime(timeZoneMomentStart);
            setTimeZoneEndTime(timeZoneMomentEnd);
        }
    }, [eventDetail, timeZones, eventDrawer]);

    socket.onmessage = resData => {
        let res = (resData && resData.data) ? JSON.parse(resData.data): [];
        if (res && res.data && res.data.users && res.data.users.indexOf(user.id) != -1) {
            if (res.method == 'calendar' && res.data.type == 'event-response') {
                getEvents();
            }
        }
    }

    const changeCalendarDate = (value) => {
        setDate(value);
        setViewDate(value);
        selectedDateEvents(value);
    }

    const selectedDateEvents = (value, eventData = events) => {
        let isHDisplay;
        let eventList = [];
        eventData.forEach(e => {
            if (isEventDate(e.start, value) && !e.is_deleted) {
                if (isToday(e.start) && !isHDisplay && e.start.getTime() > viewDate.getTime()) {
                    eventList.concat({
                        title: value,
                        start: value,
                        isHourDisplay: true,
                        type: 'hour'
                    });
                    isHDisplay = true;
                }
                eventList.push(e);                    
            }
        });
        setEventList(eventList);
    }

    const isToday = (date) => {
        const today = new Date()
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };

    const isEventDate = (date, value) => {
        const today = value;
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };

    const getEvents = (filterData = filterEventData) => {
        apiService.getEventsList({
            case_id: caseId
        }).then(res => {
            if (res.status == 'success') {
                let e = res.data;
                let rejectedApp = res.rejectedApp;
                e.forEach(element => {
                    element.start = new Date(element.start);
                    element.end = element.end?new Date(element.end):null;
                    if(link && link.link_id == element.ap_id && (element.assignee.findIndex(ele => ele.u_id === user.id) !== -1 || element.organiser.u_id == user.id) ) {
                        if (element.is_deleted != 1) {
                            setViewDate(element.start);
                            // onShowDrawer();
                            onShowEventDrawer(element);
                        } else {
                            message.error('Organizer deleted this event.');
                        }   
                    }
                });
                if(rejectedApp.findIndex(rej => rej.ap_id == link.link_id) !== -1) {
                    message.error('error','You denied this meeting.');
                }
                let filterDeleted = e.filter(e => !e.is_deleted);
                let filteredEvents = filterData && filterData.length > 0 ? filterDeleted.filter(e => filterData.indexOf(e.type) != -1): filterDeleted;
                setEvents(filteredEvents);
                if(events) {
                    let eventDay = [];
                    filteredEvents.forEach(event => {
                        eventDay.push(moment(event.start_date).format("DD-MM-yyyy"));
                    })
                    setEventDays(eventDay);
                }
                selectedDateEvents(edate, filteredEvents);
                setLoading(false);
            }
        })
    }

    const onShowDrawer = () => {
        setAgentaDrawer(true);
        setGroupEvents(groupBy(events, 'start_date'));
        setTimeout(() => {
            var container = document.getElementById('agenda-date-Today');        
            if(container) container.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 500);
    }

    const onCloseDrawer = () => {
        setAgentaDrawer(false);
    }   

    const onAddEventDrawer = () => {
        setIsEditEvent(false);
        setEventDetail('');
        setAddEventDrawer(true);
    }

    const closeAddEventDrawer = (event) => {
        setAddEventDrawer(false);
        getEvents();
    }

    const onShowEventDrawer = (event) => {
        setEventDrawer(true);
        let eventData = event;
        if (eventData.notify_before) {
            let remainderArray = eventData.notify_before.split(',');
            var remainder = [];
            remainderArray.forEach(element => {
                remainder.push({value:(element*60)});
            });
            eventData.remainders =  remainder;
        } else {
            eventData.remainders =  [{
                value: null
            }];
        }
        setEventDetail(eventData);
        // setTimeZoneId(event.timezone);
        // let timeZoneMoments;
        // let timeZoneMomentStart;
        // let timeZoneMomentEnd;
        // timeZones.filter(zone => {
        //     if (zone.zone_id == event.timezone) {
        //         setTimeZoneName(zone.zone_name);
        //         setZoneOffset(zone.offset);
        //         timeZoneMoments = momentTimeZone.utc(eventData.start).tz(zone.zone_name).format("dddd, D MMM YYYY");
        //         timeZoneMomentStart = momentTimeZone.utc(eventData.start).tz(zone.zone_name).format("hh:mm A");
        //         timeZoneMomentEnd = eventData.end && momentTimeZone.utc(eventData.end).tz(zone.zone_name).format("hh:mm A");
        //     }
        // });
        // timeZoneMoments = timeZoneMoments != '' ? timeZoneMoments: moment.utc(eventData.start).local().format("dddd, D MMM YYYY");
        // timeZoneMomentStart = timeZoneMomentStart != '' ? timeZoneMomentStart: moment.utc(eventData.start).local().format("hh:mm A");
        // timeZoneMomentEnd = timeZoneMomentEnd != '' ? timeZoneMomentEnd: eventData.end ? moment.utc(eventData.end).local().format("hh:mm A"): '';
        // setTimeZoneMomentDate(timeZoneMoments);
        // setTimeZoneStartTime(timeZoneMomentStart);
        // setTimeZoneEndTime(timeZoneMomentEnd);
    }

    const onCloseEventView = () => {
        setEventDrawer(false);
        history.push(`/app/case/${caseId}/events`)
    }
    
    const groupBy = (collection, keyGetter) => {
        if (!collection) {
            return null;
        }
        const groupedCollection = collection.reduce((previous, current) => {
            if (!previous[current[keyGetter]]) {
                previous[current[keyGetter]] = [current];
            } else {
                previous[current[keyGetter]].push(current);
            }
            return previous;
        }, {});
        return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
    }

    const convertKey = (date) => {
        const today = new Date();
        date = new Date(date);
        if (date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()) {
            return 'Today';
        } else if ((date.getDate() - 1) === (today.getDate()) &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()) {
            return 'Tomorrow';
        }
        return '';
    }

    const showFilter = () => {
        setFilter(!filter)
    }

    const handleEventType = (val) => {
        setLoading(true);
        let value = val.target.value;
        let filterData = filterEventData;
        if(val.target.checked) {
            filterData.push(value);
        } else {
            filterData = filterData.filter(item => { return item !== value });
        }
        if(value == "meeting") {
            setMeeting(val.target.checked);
        } else if(value == "keydate") {
            setKeydate(val.target.checked);
        } else if(value == "task") {
            setTask(val.target.checked);
        } else if(value == "questionnaire") {
            setQuestionnaire(val.target.checked);
        }
        setFilterEventData(filterData);
        getEvents(filterData);
    }

    const resetFilter = () => {
        setLoading(true);
        setFilterEventData([]);
        setMeeting(false);
        setKeydate(false);
        setTask(false);
        setQuestionnaire(false);
        getEvents([]);
        //showFilter();
    }

    const isPastTime = (date) => {
        return isPastDay(date) && new Date().getTime() > new Date(date).getTime();
    }

    const isPastDay = (date) => {
        const today = new Date()
        return moment(date).format("yyyy-MM-dd") <= moment(today).format("yyyy-MM-dd");
    };

    const navigateToPage = (data) => {
        let link;
        switch (data.type) {
            case 'task':
                link = {
                    link_id: data.tk_id
                };
                history.push('/app/case/'+(data.case_id)+'/tasks/'+btoa(JSON.stringify(link)));
                break;
            case 'questionnaire':
                link = {
                    link_id: data.tk_id
                };
                history.push('/app/case/'+(data.case_id)+'/questionnaires/'+btoa(JSON.stringify(link)));
                break;
            default:
                history.push('/app/case/'+(data.case_id)+'/overview');
                break;
        }
    }

    const getTimeZones = () => {
        apiService.getTimeZone()
            .then(res => {
                if (res.status == 'success') {
                    setTimeZones(res.data);
                }
            });
    }

    const copyLink = (link) => {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = link;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        message.success('Link copied to clipboard.');
    }

    const meetingResponseChange = (event) => {
        setMeetingStatus(true);
        var uniqueParticipant = [];
        eventDetail.assignee_ids?.forEach( e => {
            uniqueParticipant.push(+e);
        })
        uniqueParticipant.push(eventDetail.organiser.u_id);
        apiService.responseToAppointment({
            ap_id: eventDetail.ap_id,
            status: event.target.value 
        }).then(res => {
            setMeetingStatus(false);
            if (res.status == 'success') {
                onCloseEventView();
                message.success(res.message);
                setViewDate(eventDetail.start);
                link = undefined;
                getEvents();
                websocketService.send({
                    method: 'calendar',
                    isDisplay: true,
                    data: {
                        users: uniqueParticipant, // this.eventDetail.assignee_ids.push(this.eventDetail.organiser),
                        content: res.instant_msg,
                        type: 'event-response'
                    }
                });
            } else {
                message.error(res.message);
            }
        });
    }

    const deleteEvent = () => {
        setLoading(true);
        var uniqueParticipant = [];
        eventDetail.assignee_ids?.forEach( e => {
            uniqueParticipant.push(+e);
        })
        uniqueParticipant.push(eventDetail.organiser.u_id);
        apiService.deleteAppointment({
            ap_id: eventDetail.ap_id
        }).then(res => {
            setLoading(false);
            if (res.status == 'success') {
                onCloseEventView();
                message.success(res.message);
                link = undefined;
                getEvents();
                websocketService.send({
                    method: 'calendar',
                    isDisplay: true,
                    data: {
                        users: uniqueParticipant,
                        content: res.instant_msg,
                        type: 'event-deleted'
                    }
                });
            } else {
                message.error(res.message);
            }
        },() => {
        });  
    }

    function editEvent(eventInfo) {
        setIsEditEvent(true);
        setAddEventDrawer(true);
        setEventDrawer(false);
    }

    const menu = (
        <Menu>
            {(eventDetail.organiser?.u_id == user.id && !eventDetail.repeat_event && !isPastTime(eventDetail.start)) && (<Menu.Item key="1" onClick={() => editEvent(eventDetail)}>Edit</Menu.Item>)}
            {(eventDetail.organiser?.u_id == user.id && !eventDetail.repeat_event && !isPastTime(eventDetail.start)) && (<Menu.Item key="2"><Popconfirm
                                                                        title="Are you sure to delete this event?"
                                                                        onConfirm={() => deleteEvent()}
                                                                    >
                                                                        Delete
                                                                    </Popconfirm></Menu.Item>)}
            <Menu.Item key="3"><a href={APIURL + "calendar/downloadICS?ap_id=" + eventDetail.ap_id + "&token=" + user.token} download="event.ics">Download .ICS file</a></Menu.Item>
        </Menu>
    );
    
    const redirectProfile= () => {
        history.push('/app/account/calendar');
    }

    return (
        <div>
            <Row className="page-header">
                <Col span={12}>
                    Calendar
                </Col>
                <Col span={12}>
                    <SettingIcon onClick={() => redirectProfile()} />
                    <AddIcon onClick={() => onAddEventDrawer()}/>
                </Col>
                {CalanderView && (<AddEventCalander caseId={caseId} visible={CalanderView} edit={isEditEvent} event={eventDetail} closeCalenderView={closeAddEventDrawer}/>)}
            </Row>

            <Spin spinning={loading} delay={500}>
                <div style={{ height: 'calc(100vh - 200px)', overflow: 'auto', paddingBottom: '16px' }}>
                    <Row className="calendar-block-container">
                        <Col span={24}>
                            <CaseCalendar 
                                onChange={changeCalendarDate} 
                                value={edate} 
                                tileClassName={({ date, view }) => {
                                    if(eventDays.find(x=>x===moment(date).format("DD-MM-YYYY"))){
                                        return  'highlight'
                                    }
                                }}
                            />
                            
                        </Col>
                    </Row>
                    <div className="viewTab">
                        <Row className="viewAll">
                            <Col span={5}>
                                <span onClick={onShowDrawer}>View All</span>
                            </Col>
                            {   (filterEventData && filterEventData.length > 0) &&
                                (
                                    <Col span={4}>
                                        <span className="filter-reset" onClick={resetFilter}>Reset</span>
                                    </Col>
                                )
                            }
                            <Col span={(filterEventData && filterEventData.length > 0) ? 13:18}>
                                <span className="filter" onClick={showFilter}>Filter
                                    {filter ?  <MenuUpIcon/> : <MenuDropdownIcon/>}
                                </span>
                            </Col>
                        </Row>
                        {   filter && 
                            (
                                <Card>
                                    <Row className="mb8">
                                        <Col span={20}>
                                            <Checkbox id="meeting-event-check" checked={meeting} onChange={handleEventType} value="meeting">Meetings</Checkbox>
                                        </Col>
                                        <Col span={4}>
                                            <span className="meeting-round">&nbsp;</span>
                                        </Col>
                                    </Row>
                                    <Row className="mb8">
                                        <Col span={20}>
                                            <Checkbox id="keydate-event-check" checked={keydate} onChange={handleEventType} value="keydate">Key Date</Checkbox>
                                        </Col>
                                        <Col span={4}>
                                            <span className="keydate-round">&nbsp;</span>
                                        </Col>
                                    </Row>
                                    <Row className="mb8">
                                        <Col span={20}>
                                            <Checkbox id="task-event-check" checked={task} onChange={handleEventType} value="task">Task</Checkbox>
                                        </Col>
                                        <Col span={4}>
                                            <span className="task-round">&nbsp;</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={20}>
                                            <Checkbox id="questionnaire-event-check" checked={questionnaire} onChange={handleEventType} value="questionnaire">Questionnaire</Checkbox>
                                        </Col>
                                        <Col span={4}>
                                            <span className="questionnaire-round">&nbsp;</span>
                                        </Col>
                                    </Row>
                                </Card>
                            )
                        }
                    </div>  
                    <div className="day">{ isToday(viewDate) ? 'Today': moment(viewDate).format("dddd, D MMMM YYYY") }                   
                    </div>
                    {   eventList && eventList.length > 0 
                        ? eventList.map((item, index) => (
                            <div className="dailyAppoint" key={index} onClick={() =>((item.type == 'task' || item.type == 'questionnaire') && !item.is_deleted) ? navigateToPage(item): onShowEventDrawer(item)}>
                                <span>
                                    {   item.type == 'meeting' ? <QuestionAnswer/>: item.type == 'keydate' ? <DateRange/>: item.type == 'task' ? <Assignment/>: item.type == 'questionnaire' ? <Help/>: '' }
                                    <div className={item.type == 'meeting' ? "meeting-verticalLine": item.type == 'keydate' ? "keydate-verticalLine": item.type == 'task' ? "task-verticalLine": item.type == 'questionnaire' ?"questionnaire-verticalLine": ''}>
                                        <div className="meet_desc">
                                            { item.title }
                                            <p>{String(item.start).timeFormat()} {item && item.end ? " - "+String(item.end).timeFormat(): ''}</p>
                                        </div>               
                                    </div>
                                </span>
                            </div>
                        ))
                        :<div className="dailyAppoint">
                            <span>
                                No data
                            </span>
                        </div>
                    }
                </div>
            </Spin>

            <Drawer
                title="Calendar"
                className="calendar-agenta-drawer"
                placement="bottom"
                height={"100%"}
                onClose={onCloseDrawer}
                visible={agentaDrawer}
            >
                <Row className="sub-header case-name-header">
                    <Col span={24} className="case-name-bar">
                        <CaseStatusIcon caseId={caseInfo?.case_id} case_status={caseInfo?.case_status} case_type={caseInfo?.case_type} size={32}/>
                        <span className="pl16 case-name">{ caseInfo?.case_name }</span>
                        <span className="pl8">
                            { !caseInfo?.isFav ?<StarIcon />:<StarFillIcon /> }
                        </span>
                    </Col>
                </Row>
                {   groupEvents && groupEvents.length > 0
                    ? groupEvents.map((groups, index) => (
                        <div key={index}>
                            <div className="day-band" id={"agenda-date-" + convertKey(groups.key)}>{ convertKey(groups.key) } &nbsp; <span> { groups.key.dateLongFormat() }</span></div>
                            {   groups.value && groups.value.length > 0 &&
                                (
                                    groups.value.map((event, index) => (
                                        <div className="dailyAppoint" onClick={() =>((event.type == 'task' || event.type == 'questionnaire') && !event.is_deleted) ? navigateToPage(event): onShowEventDrawer(event)} key={index}>
                                            <span>
                                                {   event.type == 'meeting' ? <QuestionAnswer/>: event.type == 'keydate' ? <DateRange/>: event.type == 'task' ? <Assignment/>: event.type == 'questionnaire' ? <Help/>: '' }
                                                <div className={event.type == 'meeting' ? "meeting-verticalLine": event.type == 'keydate' ? "keydate-verticalLine": event.type == 'task' ? "task-verticalLine": event.type == 'questionnaire' ?"questionnaire-verticalLine": ''}>
                                                    <div className="meet_desc">
                                                        { event.title }
                                                        <Row>
                                                            <Col span={12}>
                                                                <p>{String(event.start).timeFormat()} {event && event.end ? " - "+String(event.end).timeFormat(): ''}</p>
                                                            </Col>
                                                            <Col span={12}>
                                                                {((event.type == 'task' || event.type == 'questionnaire') && event.tStatus && event.tStatus.status == 'Pending' && isPastTime(event.start)) && (<p className="event-overdue">Overdue</p>)}
                                                                {((event.type == 'task' || event.type == 'questionnaire') && event.tStatus && event.tStatus.status == 'Completed') && (<p className="event-completed">Completed</p>)}
                                                                
                                                                <div>
                                                                    {((event.type == 'keydate') && isPastTime(event.start)) && (<p className="event-expired">Expired</p>)}
                                                                    {(event.type == 'meeting' && (event.myStatus == 'Accepted' || event.is_organiser)) && (<Check24pxIcon/>)}
                                                                    {(event.type == 'meeting' && event.myStatus == 'Maybe') && (<MaybeIcon/>)}
                                                                    {(event.type == 'meeting' && event.myStatus == 'Pending') && (<SandClockIcon/>)}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>               
                                                </div>
                                            </span>
                                        </div>
                                    ))
                                )
                            }
                        </div>
                    ))
                    :(<div className="no-cases-info textCenter">
                        <NoEventsIcon />
                        <h5>No Events on this case yet!</h5>
                        <p>This page will display information about your Case's Events / Key Date’s / Tasks.</p>
                    </div>)
                }
            </Drawer>
            <Drawer
                title={eventDetail?.title}
                className="event-view-drawer"
                placement="top"
                height={"100%"}
                onClose={onCloseEventView}
                visible={eventDrawer}
            >
                { eventDetail && 
                    (
                        <>
                            <Spin spinning={loading}>
                                <div className={"attendingBtn "+eventDetail.type}>
                                    {   (eventDetail.organiser?.u_id == user.id || eventDetail.type != 'meeting') &&
                                        (
                                            <Row justify="space-between">
                                                <Col style={{ textTransform: 'capitalize' }}>
                                                    { eventDetail.type == 'keydate'?'Key Date':eventDetail.type }
                                                </Col>
                                                <Col>
                                                    <Dropdown placement="bottomRight" trigger={['click']} overlay={menu}>
                                                        <More_vert/>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                    {   (eventDetail.organiser?.u_id != user.id && eventDetail.type == 'meeting') &&
                                        (
                                            <>
                                                <Radio.Group className="groupBtn" onChange={meetingResponseChange} defaultValue={eventDetail.myStatus} >
                                                    <Radio.Button value="Accepted" disabled={meetingStatus}>Attending</Radio.Button>
                                                    <Radio.Button value="Maybe" disabled={meetingStatus}>Maybe</Radio.Button>
                                                    <Radio.Button value="Rejected" disabled={meetingStatus}>Not Attending</Radio.Button>
                                                </Radio.Group>

                                                <Dropdown placement="bottomRight" overlay={menu}>
                                                    <More_vert/>
                                                </Dropdown>
                                            </>
                                        )
                                    }
                                </div>
                                <div className="dateContainer">
                                    <p className="dateHead"> Date & Time </p>
                                    <p className="dateTime">{timeZoneMomentDate} <span className="timeFormat">{timeZoneStartTime} { eventDetail.end && (<span>{timeZoneEndTime && " To "+timeZoneEndTime}</span>)}</span></p>
                                    <div className="timeZone">
                                        Timezone:
                                        { timeZones && timeZones.length > 0  && 
                                            (<Select defaultValue={eventDetail.timezone} value={eventDetail.timezone} disabled name="timezone" bordered={false}>                                        
                                                {timeZones.map((zone, index) => (
                                                    <React.Fragment key={"zone_i_"+index}>
                                                        <Option value={zone.zone_id} label={"(UTC "+(zone.offset)+") "+zone.zone_name}>(UTC {zone.offset}) {zone.zone_name}</Option>
                                                    </React.Fragment>
                                                ))}
                                            </Select>)
                                        }
                                    </div>
                                </div>
                                <div>
                                    <p className="organizer">Organizer</p>
                                    <table className="orgDetails">
                                        <tbody>
                                            {   eventDetail.organiser &&
                                                (   
                                                    <tr>
                                                        <td rowSpan={2}>
                                                            <Avatar src={eventDetail.organiser.user_image}>{eventDetail.organiser.p_name}</Avatar>
                                                        </td>
                                                        <td>
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>{ eventDetail.organiser.name }</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{ eventDetail.organiser.role_name }</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <p className="organizer">Participants</p>
                                    <table style={{width:"100%"}}>
                                        <tbody>
                                            {   (eventDetail && eventDetail.assignee && eventDetail.assignee.length > 0)
                                                ?   eventDetail.assignee.map((party, index) => (
                                                        <tr key={index}>
                                                            <td style={{width:"17%"}}>
                                                                <Avatar src={party.user_image}>{party.p_name}</Avatar>
                                                            </td>
                                                            <td>
                                                                <table>
                                                                    <tbody>
                                                                        <tr className="name">
                                                                            <td>{ party.name }</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{ party.role_name }</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            {   eventDetail.type == 'meeting' &&
                                                                (
                                                                    <td>
                                                                        {party.status == 'Maybe' ? <Maybe/>: party.status == 'Accepted' ? <ShapeIcon/>: party.status == 'Rejected' ? <NotAttending/>: <Waiting/> }
                                                                    </td>
                                                                )
                                                            }
                                                        </tr>
                                                ))
                                                :<tr>
                                                    <td className="pl16">No Participants</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {   eventDetail.type == 'meeting' &&
                                    (
                                        <div>
                                            <p className="organizer mb8">Meeting Link</p>
                                            <p className="meet" style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '16px'}}>{eventDetail.conference_url?eventDetail.conference_url:location.origin+'/app/meeting/'+eventDetail.conference_id }<span style={{ fontSize: '24px', paddingLeft: '16px'}}><Copy onClick={() => copyLink(eventDetail.conference_url?eventDetail.conference_url:location.origin+'/app/meeting/'+eventDetail.conference_id)}/></span></p>
                                        </div>
                                    )
                                }
                                <div>
                                    <p className="organizer">Description (Optional)</p>
                                    <p className="description">{eventDetail.description }</p>
                                </div>
                            </Spin>
                        </>
                    )
                }                
            </Drawer>
        </div>
    );
}
export default Calendar;