import { Button, Card, Col, Drawer, Form, Input, Row, Select, Upload, Steps, Checkbox, Progress, Collapse, Avatar, Radio, Divider, Switch, Spin, message, Modal, InputNumber, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ArrowRightIcon, CloseIcon, DeleteIcon, MenuIcon, SortDownIcon } from "../../logics/images";
import userService from "../../logics/services/user.service";
import apiService from "../../logics/services/api.service";
import { websocketService, socket } from "../../logics/services/websocket.service";
import Icon from '@ant-design/icons';
import PhoneInput from "react-phone-input-2";
import startsWith from 'lodash.startswith';
import store from "../../logics/store/case.store";
import { APIURL } from '../../constants';
import * as $ from 'jquery';

import MobImg from '../../assets/images/mobile.png';
import GalleryImg from '../../assets/images/Shape.png';
import CaseDiscussionSvg from  '../../assets/svg-icons/case_discussion.svg';
import CaseMediationSvg from  '../../assets/svg-icons/case_mediation.svg';
import CaseDecisionSvg from  '../../assets/svg-icons/case_dc.svg';
import InfoSvg from  '../../assets/svg-icons/info.svg';
import PdfSvg from '../../assets/svg-icons/document_pdf.svg';
import EditSvg from '../../assets/svg-icons/edit.svg';
import IndividualSvg from '../../assets/svg-icons/individual.svg';
import OrganizationSvg from '../../assets/svg-icons/organization.svg';
import ArrowDownSvg from  '../../assets/svg-icons/arrow_down.svg';
import GallerySvg from  '../../assets/svg-icons/gallery.svg';
import PdfImg from  '../../assets/no-preview/pdf.png';
import ExcelImg from  '../../assets/no-preview/excel.png';
import WordImg from  '../../assets/no-preview/word.png';
import PptImg from  '../../assets/no-preview/ppt.png';
import VideoImg from  '../../assets/no-preview/video.png';
import TextImg from  '../../assets/no-preview/text.png';
import ImageImg from  '../../assets/no-preview/image.png';
import AudioImg from  '../../assets/no-preview/audio.png';

const { Step } = Steps;
const { TextArea } = Input;
const { Panel } = Collapse;

const CaseDiscussionIcon = prop => <Icon component={CaseDiscussionSvg} fill="none" style={{ fontSize: 32, verticalAlign: 'middle'}} {...prop} />;
const CaseMediationIcon = prop => <Icon component={CaseMediationSvg} style={{ fontSize: 32, verticalAlign: 'middle'}} {...prop} />;
const CaseDecisionIcon = prop => <Icon component={CaseDecisionSvg} style={{ fontSize: 32, verticalAlign: 'middle'}} {...prop} />;
const InfoIcon = prop => <Icon component={InfoSvg} style={{ fontSize: 16, marginRight: '6px'}} {...prop} />;
const PdfIcon = props => <Icon component={PdfSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginRight: '8px'}} {...props} />;
const EditIcon = props => <Icon component={EditSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const IndividualIcon = props => <Icon component={IndividualSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const OrganizationIcon = props => <Icon component={OrganizationSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const ArrowTopIcon = props => <Icon component={ArrowDownSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const ArrowDownIcon = props => <Icon component={ArrowDownSvg} className="arrow-down-nav-icon" style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const GalleryIcon = props => <Icon component={GallerySvg} style={{ fontSize: 18 }} {...props} />;

class CreateCaseView extends React.Component {

    constructor(props) {

        super(props);
        this.user = userService.getUser();
        this.settings = store.getState().settings;
        this.currency = store.getState().currency;
        this.customMessage = store.getState().customMessage;
        this.roles = store.getState().roles;
        this.caseId=this.props.match.params.caseId;
        this.isUploadable = /\.(jpe?g|gif|bmp|png|svg|tiff?|mp3|mp4|3gp|flv|wmv|mkv|avi|webm|ogg|pdf|doc|docx|csv|xls|xlsx|ppt|pptx|txt)$/i;
        this.history = this.props.history;
        this.uploadList = [];
        this.roleLabel = '';
        this.defaultRoleList = [];
        this.allRoleList = [];
        this.partiesList = [];
        this.isDuplicate = false;
        this.isDubEr;
        this.maxFilesEr;

        this.state = {
            visible: false,
            maxPartyCount: 10,
            resolutionViewMore: false,
            selectedResolution: '',
            participantVisible: false,
            rankMediatorVisible: false,
            paymentVisible: false,
            paymentStatusVisible: false,
            draftCaseInfo: {},
            current: 0,
            impactList: [],
            seekingList: [],
            loadingDraft: false,
            categoryList: [],
            subCategoryList: [],
            resolutionList: [],
            currencyList: [],
            caseRegisterModel: [],
            uploadList: [],
            fileList: [],
            roleList: [],
            allRoleList: [],
            claimantList: [],
            respondentList: [],
            partiesList: [],
            repList: [],
            partyAdd: true,
            partyAddLoading: false,
            editPartyType: '',
            editIndex: '',
            editPartyIndex: '',
            editPartyRole: '',
            editPartyRoleId: 0,
            editPartyEmail: '',
            isConfirmDelete: false,
            deleteLoading: false,
            deleteFileIndex: '',
            selectedParty: '',
            isConfirmDeleteParty: false,
            feeInfo: false,
            isDisplayPayment: false,
            paymentGatewayList: [],
            currencyId: 'USD',
            cSymbol: '$',
            pluginSessionId: '',
            paymentLoading: false,
            addClaimant: false,
            addRespondant: false,
            nextLoading: false,
            addPartyModel: {
                type: 0,
                u_id: '',
                name: '',
                p_name: '',
                first_name: '',
                last_name: '',
                email: '',
                link_email: '',
                phone: '',
                role: '',
                role_id: '',
                role_label: '',
                org_name: '',
                address1: '',
                address2: '',
                default: false,
                defaultEmail: false,
                invite_id: '',
                order: 0
            },
            isVisibleTerms: false,
            creatorRole: '',
            makePaymentDisable: false
        }
    }

    componentDidUpdate = () => {
        if (this.caseId && this.roleLabel == '') {
            apiService.checkDraftCase({
                    case_id: this.caseId
                }).then((res) => {
                    if (res.status === 'success') {
                        this.roleLabel = res.data.case_created_by;
                    }
            });
        }
        this.settings = (!this.settings) ? store.getState().settings: this.settings;
        this.currency = (!this.currency) ? store.getState().currency: this.currency;
        this.customMessage = (!this.customMessage) ? store.getState().customMessage: this.customMessage;
        this.roles = (!this.roles) ? store.getState().roles: this.roles;
    }

    componentDidMount = async () => {
        if (this.user?.role_label == 'Head Admin') {
            history.push("/app/allcases");
        }
        this.getCategories(this.state.caseRegisterModel.category);
        if (this.currency) {
            this.getCurrency();
        } else {
            apiService.getCurrencyList()
            .then((res) => {
                if (res.status === 'success') {
                    let currencySymbolList = [];
                    res.data.forEach(e => {
                        currencySymbolList[e.cr_id.toLowerCase()] = e.symbol;
                    });
                    this.currency = currencySymbolList;
                    this.getCurrency();
                }
            });
        }
        if (this.caseId) {
            this.setState({loadingDraft: true});
            apiService.checkDraftCase({
                    case_id: this.caseId
                }).then((res) => {
                    this.setState({loadingDraft: false});
                    if (res.status === 'error') {
                        this.onViewClose();
                    } else {
                        let draft = res.data;
                        this.setState({
                            draftCaseInfo: draft,
                            creatorRole: res.data.case_created_role
                        })
                        this.roleLabel = res.data.case_created_role.role_label;
                        this.setState(prevState => ({
                            caseRegisterModel: {
                                ...prevState.caseRegisterModel,
                                description: draft.case_description,
                                category: draft.category,
                                sub_category: draft.sub_category,
                                claim_amount: draft.claim_value,
                                claim_amount_value: draft.claim_value,
                                claim_currency: draft.claim_currency,
                                case_type: draft.case_type,
                                role_label: draft.case_created_by
                        }}));
                        this.getCategories(draft.category);
                        if (this.state.categoryList && this.state.categoryList.length > 0) {
                            this.state.categoryList.forEach(e => {
                                if (e.c_id == draft.category) {
                                    this.setState({subCategoryList: e.sub});
                                }
                            });
                        } else {
                            apiService.getCategories()
                            .then((res) => {
                                if (res.status == 'success') {
                                    this.setState({categoryList: res.data});
                                    res.data.forEach(e => {
                                        if (e.c_id == draft.category) {
                                            this.setState({subCategoryList: e.sub});
                                        }
                                    });
                                }
                            });
                        }
                        this.setState({current: draft.case_step});
                        try {
                            let files = JSON.parse(res.data.case_files);
                            if (Array.isArray(files)) {
                                this.setState({fileList: files});
                            }
                            let list = JSON.parse(res.data.custom_data_block);
                            if (list.impacts) {
                                this.setState({impactList: list.impacts[this.user.id]});
                            }
                            if (list.outcome_seeking) {
                                this.setState({seekingList: list.outcome_seeking[this.user.id]});
                            }
                        } catch (error) {
                            
                        }
                        let claimant = [];
                        let respondent = [];
                        let parties = [];
                        res.data.invites.forEach(e => {
                            let defaultValue = false;
                            let defaultEmailValue = false;
                            defaultValue = (defaultValue ? true: (this.user && this.user.email == e.email ? true: (claimant.length == 0 && (this.state.caseRegisterModel.role_label == 'Representative' || this.state.caseRegisterModel.role_label == 'Legal Representative') ? true: false)));
                            defaultEmailValue = (defaultEmailValue ? true: (this.user && this.user.email == e.email ? true: false));
                            let address = e.address.split(' ; ');
                            let party = {
                                type: Number(e.role),
                                u_id: e.u_uid,
                                name: e.first_name+' '+e.last_name,
                                p_name: e.first_name[0]+''+e.last_name[0],
                                image: this.user && this.user.email == e.email?this.user.image:null,
                                first_name: e.first_name,
                                last_name: e.last_name,
                                email: e.email,
                                link_email: e.link_email,
                                phone: e.phone,
                                role: e.role_name,
                                role_id: e.role_id,
                                role_label: e.role_label,
                                org_name: e.org_name,
                                address1: address && address[0]?address[0]:'',
                                address2: address && address[1]?address[1]:'',
                                default: defaultValue,
                                defaultEmail: defaultEmailValue,
                                invite_id: e.invite_id,
                                order: e.order
                            }
                            parties.push(party);
                            if (e.role_label == 'Claimant') {
                                claimant.push(party);
                            } else if (e.role_label == 'Respondent') {
                                respondent.push(party);
                            } else {
                                let count = res.data.invites.filter(e1 => e1.email == e.link_email && e1.role_label == 'Claimant').length;
                                let count1 = res.data.invites.filter(e1 => e1.email == e.link_email && e1.role_label == 'Respondent').length;
                                if (count > 0) {
                                    claimant.push(party);
                                } else if (count1 > 0) {
                                    respondent.push(party);
                                } else if (e.role_label != "Mediator" && e.role_label != "Arbitrator") {
                                    claimant.push(party);
                                }
                            }
                        });
                        claimant.sort((a, b) => a.order - b.order);
                        respondent.sort((a, b) => a.order - b.order);
                        this.setState({claimantList: claimant, respondentList: respondent, partiesList: parties});
                        this.partiesList = parties;
                        this.getRes(draft);
                        this.getCategories();
                        this.getPartyRoles(draft.case_created_by);
                        this.getPaymentGateways();
                    }
                });
        }
    };

    getCurrency = () => {
        let list = [];
        for (let key in this.currency) {
            const element = this.currency[key];
            list.push({
                label: key.toUpperCase()+' ('+element+')',
                value: key.toUpperCase(),
                symbol: element
            });
        }
        this.setState(prevState => ({
            caseRegisterModel: {
                ...prevState.caseRegisterModel,
                claim_currency: this.settings && this.settings.currency?this.settings.currency.cr_id:'USD ($)'
        }}));
        this.setState({currencyList: list, cSymbol: this.settings && this.settings.currency?this.settings.currency.symbol:'$', currencyId: this.settings && this.settings.currency?this.settings.currency.cr_id:'USD'});
    }
        
    showDrawer = () => {
        this.setState({visible: true});
    }

    onClose = () => {
        this.setState({visible: false});
    }

    showResolutionViewMore = (item) => {
        this.setState({selectedResolution: item, resolutionViewMore: true});
    }

    onCloseResolutionViewMore = () => {
        this.setState({resolutionViewMore: false});
    }

    showParticipantDrawer = () => {
        this.setState({
            addPartyModel: {
                type: 0,
                u_id: '',
                name: '',
                p_name: '',
                first_name: '',
                last_name: '',
                email: '',
                link_email: '',
                phone: '',
                role: '',
                role_id: '',
                role_label: '',
                org_name: '',
                address1: '',
                address2: '',
                default: false,
                defaultEmail: false,
                invite_id: '',
                order: 0
        }});
        const l = this.defaultRoleList;
        let li = [];
        l.forEach(e => {
            li.push(e)
        })
        this.setState({roleList: li});
        this.setState({partyAdd: true, participantVisible: true});
        let list = this.partiesList.filter(e => e.role_label == 'Claimant' || e.role_label == 'Respondent');
        let ldisplay = [];
        list.forEach(e => {
            ldisplay.push({
                label: e.name,
                value: e.email
            });
        });
        this.setState({repList: ldisplay, editPartyRole: '', editPartyRoleId: 0});
        setTimeout(() => {
            $('.react-tel-input input').val('');
        }, 100);
    }

    onCloseParticipant = () => {
        this.setState({participantVisible: false});
    }

    showRankMediatorDrawer = () => {
        this.setState({rankMediatorVisible: true});
    }

    onCloseRankMediator = () => {
        this.setState({rankMediatorVisible: false});
    }

    onClosePayment = () => {
        this.setState({paymentVisible: false, paymentLoading: false});
    }

    showPaymentStatusDrawer = () => {
        this.setState({paymentStatusVisible: true});
    }

    onClosePaymentStatus = () => {
        this.setState({paymentStatusVisible: false, paymentLoading: true});
        this.history.push(`/app/case/${this.caseId}/overview`);
    }

    onViewClose = () => {
        this.history.push('/app/create-case');
    }

    addSeekingList = () => {
        this.setState({seekingList: {value: ''}});
    }

    addImpactedList = () => {
        this.setState({impactList: {value: ''}});
    }

    deleteSeeking = (index) => {
        let list = this.state.seekingList.filter((e, i) => { return i !== index;});
        this.setState({seekingList: list});
    }

    deleteImpacts = (index) => {
        let list = this.state.impactList.filter((e,i) => { return i !== index;});
        this.setState({impactList: list});
    }

    changeCurrency = (e) => {
        this.setState(prevState => ({
            caseRegisterModel: {
                ...prevState.caseRegisterModel,
                claim_currency: e
        }}));
    }
      
    onChange = (e) => {
        this.setState(prevState => ({
            caseRegisterModel: {
                ...prevState.caseRegisterModel,
                agreed: e.target.checked
        }}));
    }

    callback = (key) => {
        //console.log(key);
    }

    onPaymentChange = (e) => {
        this.setState(prevState => ({
            caseRegisterModel: {
                ...prevState.caseRegisterModel,
                paymentgateway: e.target.value
        }}));
    };

    onChangeDescription = (e) => {
        this.setState(prevState => ({
            caseRegisterModel: {
                ...prevState.caseRegisterModel,
                description: e.target.value
        }}));
    }
    
    getRes = (modalValue = false) => {  
        let category;
        let sub_category;
        let role_label;
        let case_type;
        if(!modalValue) {
            category = this.state.caseRegisterModel.category;
            sub_category = this.state.caseRegisterModel.sub_category;
            role_label = this.roleLabel;
            case_type = this.state.caseRegisterModel.case_type;
        } else {
            category = modalValue.category;
            sub_category = modalValue.sub_category;
            role_label = modalValue.case_created_by;
            case_type = modalValue.case_type;
        }
        
        this.setState({loadingDraft: true});
        apiService.getUserResolutions({
                category: category,
                sub_category: sub_category,
                role_label: !this.caseId && localStorage.getItem('case_reg_token')?atob(localStorage.getItem('case_reg_token')):role_label
            }).then((res) => {
                this.setState({loadingDraft: false});
                if (res.status == 'success') {
                    this.setState({resolutionList: res.data});
                    if (case_type) {
                        res.data.forEach(e => {
                            if (e.rc_id == case_type) {
                                this.setState({selectedResolution: e, feeInfo: e.feeInfo, isDisplayPayment: e.feeInfo?true:false});
                                console.log("e.feeInfo - ", e.feeInfo);
                            }
                        });
                    }
                }
            });
    }

    getPartyRoles = (role_label = false) => {
        let roleLabel = (!role_label) ? this.state.caseRegisterModel.role_label: role_label;
        apiService.getPartyRoles(!this.caseId && localStorage.getItem('case_reg_roken')?atob(localStorage.getItem('case_reg_roken')):roleLabel)
            .then((res) => {
                if (res.status == 'success') {
                    let list = [];
                    res.data.forEach(element => {
                        list.push({
                            value: element.role_id,
                            role: element.role_label,
                            label: element.role_name
                        });
                    });
                    this.setState({roleList: list, allRoleList: res.roles});
                    let list2 = [];
                    res.data.forEach(element => {
                        list2.push({
                            value: element.role_id,
                            role: element.role_label,
                            label: element.role_name
                        });
                    });
                    this.defaultRoleList = list2;
                    this.allRoleList = res.roles;
                }
            });
    }

    getCategories = (category) => {
        apiService.getCategories()
        .then((res) => {
            if (res.status == 'success') {
                this.setState({categoryList: res.data});
                if (category && !this.state.subCategoryList) {
                    res.data.forEach(e => {
                        if (e.c_id == category) {
                            this.setState({subCategoryList: e.sub});
                        }
                    });
                }
            }
        });
    }

    categoryChange = (e) => {
        this.setState(prevState => ({
            caseRegisterModel: {
                ...prevState.caseRegisterModel,
                category: e, 
                sub_category: null
        }}));
        this.state.categoryList.forEach(c => {
            if (c.c_id == e) {
                this.setState({subCategoryList: c.sub});
            }
        });
    }

    subCategoryChange = (e) => {
        this.setState(prevState => ({
            caseRegisterModel: {
                ...prevState.caseRegisterModel,
                sub_category: e
        }}));
    }

    setImpact = (e, k) => {
        let impactListL = this.state.impactList;
        impactListL.forEach((e1, k1) => {
            if (k1 == k) {
                e1.value = e.target.value;
            }
        });
        this.setState({impactList: impactListL});
    }

    setSeeking = (e, k) => {
        let seekingListL = this.state.seekingList;
        seekingListL.forEach((e1, k1) => {
            if (k1 == k) {
                e1.value = e.target.value;
            }
        });
        this.setState({seekingList: seekingListL});
    }

    formatCurrency = ($event) => {
        var val = $event.target.value; //$event;

        val = val.toString().replace(/,/g, "");
        this.setState(prevState => ({
            caseRegisterModel: {
                ...prevState.caseRegisterModel,
                claim_amount: val
        }}));

        val += '';
        let x = val.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';

        var rgx = /(\d+)(\d{3})/;

        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        this.setState(prevState => ({
            caseRegisterModel: {
                ...prevState.caseRegisterModel,
                claim_amount_value: x1 + x2
        }}));
    }

    handleClaimChange = (e) => {
        const inputValue = e;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
            this.setState(prevState => ({
                caseRegisterModel: {
                    ...prevState.caseRegisterModel,
                    claim_amount: inputValue, 
                    claim_amount_value: inputValue
            }}));
        }
    };

    onChangeRes = (e, item) => {
        this.setState(prevState => ({
            caseRegisterModel: {
                ...prevState.caseRegisterModel,
                case_type: e.target.checked?item.rc_id:null
        }}));
    }

    onClickRes = (item) => {
        if (this.state.caseRegisterModel.case_type != item.rc_id) {
            this.setState(prevState => ({
                caseRegisterModel: {
                ...prevState.caseRegisterModel,
                case_type: item.rc_id
            }}));
        } else if (this.state.caseRegisterModel.case_type == item.rc_id) {
            this.setState(prevState => ({
                caseRegisterModel: {
                ...prevState.caseRegisterModel,
                case_type: undefined
            }}));
        }
    }
    
    beforeUpload = (item, filelist) => {
        if (this.state.fileList.length != 0) {
            this.state.fileList.find(e => {
                if (e.original_name == item.name) {
                    this.isDuplicate = true;
                }
            });
            if (this.isDuplicate) {
                if (!this.isDubEr) {
                    this.isDubEr = true;
                    message.error('Already there is a file in this location with the same name.');
                    setTimeout(() => {
                        this.isDubEr = false;
                    }, 2000);
                }
                this.isDuplicate = false;
                return false;
            }
        }
        if (filelist.length > 10) {
            if (!this.maxFilesEr) {
                this.maxFilesEr = true;
                message.error(this.customMessage['C_UP_MX_FLE_ER']?this.customMessage['C_UP_MX_FLE_ER']:'You can upload maximum 10 files at a time.');
                setTimeout(() => {
                    this.maxFilesEr = false;
                }, 1000);
            }
            return false;
        }

        return new Promise(resolve => {
            const isvalid = this.isUploadable.test(item.name);
            if (!isvalid) {
                message.error(this.customMessage && this.customMessage['C_UP_FLE_TYP_ER']?this.customMessage['C_UP_FLE_TYP_ER']:'This format not supported');
                resolve(false);
                return false;
            }
            const isLt15M = item.size / 1024 / 1024 < 300;
            if (!isLt15M) {
                message.error(this.customMessage && this.customMessage['T_UPL_LRG_ER']?this.customMessage['T_UPL_LRG_ER']:'You can only upload file size less then 300mb');
                resolve(false);
                return false;
            }
            let fType;				
			if(item.type == 'application/vnd.ms-excel' || item.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')	{
				fType = 'excel';
			} else if(item.type == 'application/pdf') {
				fType = 'pdf';
			} else if(item.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || item.type == 'application/msword') {
				fType = 'word';
			} else if(item.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || item.type == 'application/vnd.ms-powerpoint') {
				fType = 'ppt';
			} else if(item.type == 'image/gif') {
				fType = 'gif';
			} else if(item.type == 'image/jpeg' || item.type == 'image/png' || item.type == 'image/svg+xml' || item.type == 'image/bmp' || item.type == 'image/tiff' || item.type == 'image/x-tiff') {
				fType = 'image';
			} else if(item.type == 'audio/mpeg' || item.type == 'audio/ogg') {
				fType = 'audio';
			} else if(item.type == 'video/mp4' || item.type == 'video/3gpp' || item.type == 'video/webm' || item.type == 'video/x-flv' || item.type == 'video/x-ms-wmv' || item.type == 'video/mkv' || item.type == 'video/avi' || item.name.split('.').pop() == 'flv') {
				fType = 'video';
			} else if(item.type == 'text/plain') {
				fType = 'txt';
			}
            item.index = this.state.uploadList.length;
            let uploadListL = this.state.uploadList;
            uploadListL.push({
                file: item, 
                original_name: item.name, 
                ext: fType, 
                file_size: item.size,
                result:{}
            });
            this.setState({uploadList: uploadListL});
            this.uploadList = uploadListL;
            resolve(true);
        });
    }

    fileUpload = (item) => {
        const formData = new FormData();
        let index = item.file.index;
        formData.append('file', item.file);
        formData.append('case_id', this.caseId);
        apiService.uploadCaseFile(formData)
            .then((res) => {
                if (res.status == 'success') {
                    let fileListL = this.state.fileList;
                    fileListL.push({
                        file: item,
                        original_name: res.data.original_name,
                        url: res.data.url,
                        ext: res.data.ext,
                        result: res.data
                    });
                    let uploadListL = this.uploadList;
                    uploadListL[index].file = item;
                    uploadListL[index].result = res.data;
                    this.setState({uploadList: uploadListL});
                    uploadListL.filter((e,i) => {
                        if(e.file.index || e.file.index == 0) {
                            if(e.file.index == index) {
                                delete uploadListL[index];
                            }
                        } else if(e.file.file.index == index) {
                            delete uploadListL[index];
                        }
                    });
                    this.setState({uploadList: uploadListL, fileList: fileListL});
                    this.uploadList = uploadListL;
                } else if (res.status == 'error'){						
                    if (res.type && res.type == 'Duplicate') {
                    } else if(res.type && res.type == 'Virus') {
                    } else {
                        
                    }										
                }
            });
    }

    saveFileDesc = (e, index) => {
        apiService.saveCaseFileDescription({
                case_id: this.caseId,
                index: index,
                description: e.target.value
            }).then(res => {});
    }

    deleteCaseFile = (file, index) => {
        this.setState({deleteFileIndex: index, isConfirmDelete: true});
    }

    deleteFileCancel = () => {
        this.setState({isConfirmDelete: false});
    }

    confirmDeleteFile = () => {
        this.setState({deleteLoading: true});
        apiService.deleteCaseFile({
                case_id: this.caseId,
                index: this.state.deleteFileIndex
            }).then((res) => {
                this.setState({deleteLoading: false});
                if (res.status == 'success') {
                    message.success(res.message);
                    this.setState({isConfirmDelete: false});
                    let list = this.state.fileList.filter((e, i) => { return i !== this.state.deleteFileIndex;});
                    this.setState({fileList: list});
                    let deleteIndex = this.state.fileList.filter((e, i) => { return e.f_id;}).length;
                    let uList = this.state.uploadList;
                    uList = uList.filter((e,i) => { return i != this.state.deleteFileIndex - deleteIndex;});
                    this.setState({uploadList: uList});
                }
            });
    }

    getRoleWithOrder = (role, roleLabel, order, linkEmail) => {
        let claimants = this.state.claimantList.filter((e, k) => !e.link_email).length;
        let respondents = this.state.respondentList.filter((e, k) => !e.link_email).length;
        if ((roleLabel == "Claimant" && claimants > 1) || (roleLabel == "Respondent" && respondents > 1)) {
            return role+" - "+role.charAt(0)+order;
        } else if (linkEmail) {
            let index = this.state.partiesList.findIndex(e => e.email == linkEmail);
            if((this.state.partiesList[index].role_label == "Claimant" && claimants > 1) || (this.state.partiesList[index].role_label == "Respondent" && respondents > 1)) {
                return index != -1?role+' - '+this.state.partiesList[index].role.charAt(0)+this.state.partiesList[index].order:'';
            } else {
                return role;
            }
        } else {
            return role;
        }
    }

    partyRole = (email) =>  {
        let claimants = this.state.claimantList.filter((e, k) => !e.link_email).length;
        let respondents = this.state.respondentList.filter((e, k) => !e.link_email).length;
        let index = this.state.partiesList.findIndex(e => e.email == email);
        if ((this.state.partiesList[index].role_label == "Claimant" && claimants > 1) || (this.state.partiesList[index].role_label == "Respondent" && respondents > 1)) {
            return index != -1?this.state.partiesList[index].role+' - '+this.state.partiesList[index].role.charAt(0)+this.state.partiesList[index].order:'';
        } else {
            return this.state.partiesList[index].role;
        }
    }

    addCaseParty = () => {
        let nameReg = new RegExp(/^[a-zA-Z0-9#’/, \-\', \p{Lu}\p{Ll}+( \p{Lu}\p{Ll}+)*$]+$/); //(/^[a-zA-Z \-\']+$/);
        let orgReg = new RegExp(/^[a-zA-Z0-9#.&’/, \-\', \p{Lu}\p{Ll}+( \p{Lu}\p{Ll}+)*$]+$/); //(/^[a-zA-Z \-\']+$/);
        let addressReg = new RegExp(/^[a-zA-Z0-9#.’/, \-\', \p{Lu}\p{Ll}+( \p{Lu}\p{Ll}+)*$]+$/);
        let emailReg = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z ]{2,10}$/);
        if (!this.state.addPartyModel.first_name || !nameReg.test(this.state.addPartyModel.first_name)) {
            message.error('Please provide valid First Name to add a participant.');
        } else if(!this.state.addPartyModel.last_name || !nameReg.test(this.state.addPartyModel.last_name)) {
            message.error('Please provide valid Last Name to add a participant.');
        } else if(this.state.addPartyModel.address1 && !addressReg.test(this.state.addPartyModel.address1)) {
            message.error('Please provide valid Address to add a participant.');
        } else if(this.state.addPartyModel.address2 && !addressReg.test(this.state.addPartyModel.address2)) {
            message.error('Please provide valid Address to add a participant.');
        } else if(this.state.addPartyModel.type == 1 && (!this.state.addPartyModel.org_name || !orgReg.test(this.state.addPartyModel.org_name))) {
            message.error('Please provide valid Organization Name to add a participant.');
        } else if (this.state.partyAdd) {
            let emailCount = this.partiesList.filter(e => e.email == this.state.addPartyModel.email).length;
            let claimants = this.partiesList.filter(e => e.role_label == 'Claimant').length;
            let respondents = this.partiesList.filter(e => e.role_label == 'Respondent').length;

            if (emailCount > 0 || this.state.addPartyModel.email.toLowerCase() == this.user.email.toLowerCase()) {
                message.error('This email address has already been added.')
            } 
            // else if (this.state.addPartyModel.phone && $('#addpartyForm #phoneInput').hasClass('ng-invalid')) {
            //     message.error('error', 'Please provide valid Phone Number to add a participant.');
            // } 
            else if (this.state.addPartyModel.role_label && this.state.addPartyModel.role_label != 'Claimant' && this.state.addPartyModel.role_label != 'Respondent' && !this.state.addPartyModel.link_email) {
                message.error('Please select the disputant he/she is representing.');
            } else if (this.state.addPartyModel.type == 1 && !this.state.addPartyModel.org_name) {
                message.error('Please provide organization name.');
            } else {
                this.setState({partyAddLoading: true});
                let order = 0;
                if (this.state.addPartyModel.role_label == 'Claimant') {
                    order = (claimants > 0) ? claimants + 1: 1;
                } else if (this.state.addPartyModel.role_label == 'Respondent') {
                    order = (respondents > 0) ? respondents + 1: 1;
                }
                this.setState(prevState => ({
                    addPartyModel: {
                        ...prevState.addPartyModel,
                        order: order,
                        first_name: this.state.addPartyModel.first_name,
                        last_name: this.state.addPartyModel.last_name,
                        org_name: this.state.addPartyModel.org_name,
                        email: this.state.addPartyModel.email,
                        phone: this.state.addPartyModel.phone,
                        address1: this.state.addPartyModel.address1,
                        address2: this.state.addPartyModel.address2,
                        link_email: this.state.addPartyModel.link_email
                }}));
                let party = {
                    first_name: this.state.addPartyModel.first_name,
                    last_name: this.state.addPartyModel.last_name,
                    u_id: this.state.addPartyModel.u_id,
                    email: this.state.addPartyModel.email,
                    phone: this.state.addPartyModel.phone,
                    name: '',
                    p_name: '',
                    image: '',
                    org_name: this.state.addPartyModel.org_name,
                    link_email: this.state.addPartyModel.link_email,
                    type: this.state.addPartyModel.type,
                    role: this.state.addPartyModel.role,
                    role_id: this.state.addPartyModel.role_id,
                    role_label: this.state.addPartyModel.role_label,
                    address1: this.state.addPartyModel.address1,
                    address2: this.state.addPartyModel.address2,
                    default: this.state.addPartyModel.default,
                    defaultEmail: this.state.addPartyModel.defaultEmail,
                    order: order,
                    invite_id: ''
                }
                apiService.addCaseParty({
                        party: party,
                        case_id: this.caseId,
                        role_id: this.state.addPartyModel.role_id,
                        old_role_id: 0,
                        type: 'add'    
                    }).then((res) => {
                        this.setState({partyAddLoading: false});
                        if (res.status == 'success') {
                            this.setState({participantVisible: false});
                            this.setState(prevState => ({
                                addPartyModel: {
                                    ...prevState.addPartyModel,
                                    u_id: res.data?.id,
                                    name: this.state.addPartyModel.first_name+' '+this.state.addPartyModel.last_name,
                                    p_name: this.state.addPartyModel.first_name[0]+''+this.state.addPartyModel.last_name[0],
                                    invite_id: res.invite_id
                            }}));
                            party.u_id = res.data?.id;
                            party.name = this.state.addPartyModel.first_name+' '+this.state.addPartyModel.last_name;
                            party.p_name = this.state.addPartyModel.first_name[0]+''+this.state.addPartyModel.last_name[0];
                            party.invite_id = res.invite_id;
                            if (this.state.addPartyModel.role_label == 'Claimant') {
                                this.setState(prevState => ({
                                    addPartyModel: {
                                        ...prevState.addPartyModel,
                                        default: (this.state.claimantList.length == 0 && (this.state.caseRegisterModel.role_label == 'Representative' || this.state.caseRegisterModel.role_label == 'Legal Representative')) ? true: false,
                                }}));
                                party.default = (this.state.claimantList.length == 0 && (this.state.caseRegisterModel.role_label == 'Representative' || this.state.caseRegisterModel.role_label == 'Legal Representative')) ? true: false;
                                let claimants = this.state.claimantList;
                                claimants.push(party);
                                this.setState({claimantList: claimants});
                            } else if (this.state.addPartyModel.role_label == 'Respondent') {
                                let respondents = this.state.respondentList;
                                respondents.push(party);
                                this.setState({respondentList: respondents});
                            } else {
                                let count = this.state.claimantList.filter(e => e.email == this.state.addPartyModel.link_email).length;
                                let count1 = this.state.respondentList.filter(e => e.email == this.state.addPartyModel.link_email).length;
                                if (count > 0) {
                                    let claimants = this.state.claimantList;
                                    claimants.push(party);
                                    this.setState({claimantList: claimants});
                                } else if (count1 > 0) {
                                    let respondents = this.state.respondentList;
                                    respondents.push(party);
                                    this.setState({respondentList: respondents});
                                }
                            }
                            let claimantListL = this.state.claimantList;
                            let respondentListL = this.state.respondentList;
                            if (this.state.claimantList.length == 1 && (this.state.caseRegisterModel.role_label == 'Representative' || this.state.caseRegisterModel.role_label == 'Legal Representative') && (this.state.addPartyModel.role_label == 'Claimant')) {
                                let role = this.allRoleList.filter(e => e.role_label === this.state.caseRegisterModel.role_label);
                                let address = this.user.address?.split(' ; ');
                                let party = {
                                    first_name: this.user.first_name,
                                    last_name: this.user.last_name,
                                    u_id: this.user.id,
                                    email: this.user.email,
                                    link_email: this.state.addPartyModel.email,
                                    phone: this.user.phone,
                                    name: this.user.name,
                                    p_name: this.user.p_name,
                                    org_name: this.user.org_name,
                                    image: this.user.image,
                                    type: this.user.org_name?1:0,
                                    role: role && role[0]?.role_name,
                                    role_id: role && role[0]?.role_id,
                                    role_label: role && role[0]?.role_label,
                                    address1: address && address[0]?address[0]:'',
                                    address2: address && address[1]?address[1]:'',
                                    default: true,
                                    defaultEmail: true
                                };
                                if (this.state.addPartyModel.role_label == 'Claimant') {
                                    claimantListL.push(party);
                                } else {
                                    respondentListL.push(party);
                                }
                                apiService.addCaseParty({
                                    party: party,
                                    case_id: this.caseId,
                                    role_id: party.role_id,
                                    type: 'add'
                                }).then((res) => {
    
                                });
                            }
                            let parties = claimantListL.concat(respondentListL);
                            this.setState({claimantList: claimantListL, respondentList: respondentListL, partiesList: parties});
                            this.partiesList = parties;
                        } else {
                            message.error(res.message.replaceAll('<br/>', '\n\n'));
                        }
                    });
            }
        } else {
            let emailCount = 0;
            let order = 1;
            let claimants = this.partiesList.filter(e => e.role_label == 'Claimant').length;
            let respondents = this.partiesList.filter(e => e.role_label == 'Respondent').length;
            if (this.state.addPartyModel.role_label == 'Claimant') {
                let checkClaimant = this.partiesList.filter(e => e.email == this.state.editPartyEmail && e.role_label == 'Claimant').length;
                if(checkClaimant == 0) {
                    order = (claimants > 0) ? claimants + 1: 1;
                    this.setState(prevState => ({
                        addPartyModel: {
                            ...prevState.addPartyModel,
                            order: order
                    }}));
                }
            } else if (this.state.addPartyModel.role_label == 'Respondent') {
                let checkRespondant = this.partiesList.filter(e => e.email == this.state.editPartyEmail && e.role_label == 'Respondent').length;
                if(checkRespondant == 0) {
                    order = (respondents > 0) ? respondents + 1: 1;
                    this.setState(prevState => ({
                        addPartyModel: {
                            ...prevState.addPartyModel,
                            order: order
                    }}));
                }
            }
            emailCount = this.partiesList.filter((e, k) => e.email.toLowerCase() == this.state.addPartyModel.email.toLowerCase() && k != this.state.editPartyIndex).length;
            if (emailCount > 0) {
                message.error('This email address has already been added.')
            }
            // else if (this.state.addPartyModel.phone && $('#addpartyForm #phoneInput').hasClass('ng-invalid')) {
            //     this.msgService.setMesssage('error', 'Please provide valid Phone Number to add a participant.');
            // } 
            else if (this.state.addPartyModel.role_label != 'Claimant' && this.state.addPartyModel.role_label != 'Respondent' && !this.state.addPartyModel.link_email) {
                message.error('Please select the disputant he/she is representing.');
            } else if (this.state.addPartyModel.type == 1 && !this.state.addPartyModel.org_name) {
                message.error('Please provide organization name.');
            } else {
                this.setState({partyAddLoading: false});
                let party = {
                    first_name: this.state.addPartyModel.first_name,
                    last_name: this.state.addPartyModel.last_name,
                    u_id: this.state.addPartyModel.u_id,
                    email: this.state.addPartyModel.email,
                    phone: this.state.addPartyModel.phone,
                    name: '',
                    p_name: '',
                    image: '',
                    org_name: this.state.addPartyModel.org_name,
                    link_email: this.state.addPartyModel.link_email,
                    type: this.state.addPartyModel.type,
                    role: this.state.addPartyModel.role,
                    role_id: this.state.addPartyModel.role_id,
                    role_label: this.state.addPartyModel.role_label,
                    address1: this.state.addPartyModel.address1,
                    address2: this.state.addPartyModel.address2,
                    default: this.state.addPartyModel.default,
                    defaultEmail: this.state.addPartyModel.defaultEmail,
                    order: order,
                    invite_id: this.state.addPartyModel.invite_id
                }
                apiService.addCaseParty({
                    party: party,
                    case_id: this.caseId,
                    role_id: this.state.addPartyModel.role_id,
                    old_role_id: this.state.editPartyRoleId,
                    type: 'edit'
                }).then((res) => {
                    this.setState({partyAddLoading: false});
                    if (res.status == 'success') {
                        this.setState({participantVisible: false});
                        this.setState(prevState => ({
                            addPartyModel: {
                                ...prevState.addPartyModel,
                                u_id: res.data?.id,
                                name: this.state.addPartyModel.first_name+' '+this.state.addPartyModel.last_name,
                                p_name: this.state.addPartyModel.first_name[0]+''+this.state.addPartyModel.last_name[0],
                        }}));
                        party.u_id = res.data?.id;
                        party.name = this.state.addPartyModel.first_name+' '+this.state.addPartyModel.last_name;
                        party.p_name = this.state.addPartyModel.first_name[0]+''+this.state.addPartyModel.last_name[0];
                        if (res.invite_id) {
                            this.setState(prevState => ({
                                addPartyModel: {
                                    ...prevState.addPartyModel,
                                    invite_id: res.invite_id
                            }}));
                        }
                        let prevRole = '', prevLink = '', prevLinkType = '';
                        if (this.state.editPartyType == 0) {
                            prevRole = this.state.claimantList[this.state.editIndex].role_label;
                            prevLink = this.state.claimantList[this.state.editIndex].link_email;
                            if (prevLink) {
                                prevLinkType = '0';
                            } else {
                                prevLinkType = '1';
                            }
                        } else if (this.state.editPartyType == 1) {
                            prevRole = this.state.respondentList[this.state.editIndex].role_label;
                            prevLink = this.state.respondentList[this.state.editIndex].link_email;
                            if (prevLink) {
                                prevLinkType = '1';
                            } else {
                                prevLinkType = '0';
                            }
                        }
                        let parties = this.partiesList;
                        let claimants = this.state.claimantList;
                        let respondents = this.state.respondentList;
                        if (this.state.addPartyModel.role_label == 'Claimant' && prevRole == 'Claimant') {
                            claimants[this.state.editIndex] = this.state.addPartyModel;
                        } else if (this.state.addPartyModel.role_label == 'Respondent' && prevRole == 'Respondent') {
                            respondents[this.state.editIndex] = this.state.addPartyModel;
                        } else if (this.state.addPartyModel.role_label == 'Claimant' && prevRole == 'Respondent') {
                            respondents.splice(this.state.editIndex, 1);
                            this.changeOrder(respondents);
                            claimants.push(this.state.addPartyModel);
                            let linkedRespondants = respondents.filter(e => e.link_email == this.state.addPartyModel.email);
                            if(linkedRespondants.length > 0) {
                                linkedRespondants.forEach(e => {
                                    claimants.push(e);
                                });
                            }
                            respondents = respondents.filter(e => e.link_email != this.state.addPartyModel.email);
                        } else if (this.state.addPartyModel.role_label == 'Respondent' && prevRole == 'Claimant') {
                            claimants.splice(this.state.editIndex, 1);
                            this.changeOrder(claimants);
                            respondents.push(this.state.addPartyModel);
                            let linkedClaimants = claimants.filter(e => e.link_email == this.state.addPartyModel.email);
                            if(linkedClaimants.length > 0) {
                                linkedClaimants.forEach(e => {
                                    respondents.push(e);
                                });
                            }
                            claimants = claimants.filter(e => e.link_email != this.state.addPartyModel.email);
                        } else {
                            let count = claimants.filter(e => e.email == this.state.addPartyModel.link_email).length;
                            let count1 = respondents.filter(e => e.email == this.state.addPartyModel.link_email).length;
                            if (count > 0 && prevLinkType == '0') {
                                claimants[this.state.editIndex] = this.state.addPartyModel;
                            } else if (count > 0 && prevLinkType != '0') {
                                respondents.splice(this.state.editIndex, 1);
                                this.changeOrder(respondents);
                                claimants.push(this.state.addPartyModel);
                                let linkedRespondants = respondents.filter(e => e.link_email == this.state.addPartyModel.email);
                                if(linkedRespondants.length > 0) {
                                    linkedRespondants.forEach(e => {
                                        claimants.push(e);
                                    });
                                }
                                respondents = respondents.filter(e => e.link_email != this.state.addPartyModel.email);
                            } else if (count1 > 0 && prevLinkType == '1') {
                                respondents[this.editIndex] = this.state.addPartyModel;
                            } else if (count1 > 0 && prevLinkType != '1') {
                                claimants.splice(this.state.editIndex, 1);
                                this.changeOrder(claimants);
                                respondents.push(this.state.addPartyModel);
                                let linkedClaimants = claimants.filter(e => e.link_email == this.state.addPartyModel.email);
                                if(linkedClaimants.length > 0) {
                                    linkedClaimants.forEach(e => {
                                        respondents.push(e);
                                    });
                                }
                                claimants = claimants.filter(e => e.link_email != this.state.addPartyModel.email);
                            }
                        }
                        parties = claimants.concat(respondents);
                        let linkParties = parties.filter(e => e.link_email == this.state.editPartyEmail);
                        if(linkParties.length > 0) {
                            linkParties.forEach(e => {
                                e.link_email = this.state.addPartyModel.email;
                            })
                        }
                        this.setState({partiesList: parties, claimantList: claimants, respondentList: respondents });
                        this.partiesList = parties;
                    } else {
                        message.error(res.message.replaceAll('<br/>', '\n\n'));
                    }
                });
            }
        }
    }

    changeOrder(list) {
        if(list.length > 0){
            list.forEach((e, i) => {
                if(e.link_email == '' || e.link_email == null) {
                    let order = i + 1;
                    e.order = order;
                }
            })
        }
    }

    deleteParty = (party, index) => {
        this.setState({isConfirmDeleteParty: true, selectedParty: party});
    }

    deletePartyCancel = () => {
        this.setState({isConfirmDeleteParty: false});
    }

    confirmDeleteParty = () => {
        this.setState({deleteLoading: true});
        apiService.deleteCaseParty({
                case_id: this.caseId,
                invite_id: this.state.selectedParty.invite_id,
                email: this.state.selectedParty.email,
                role_id: this.state.selectedParty.role_id
            }).then((res) => {
                this.setState({deleteLoading: false});
                if (res.status == 'success') {
                    this.setState({isConfirmDeleteParty: false});
                    let parties = [];
                    let claimants = [];
                    let respondents = [];
                    res.data.invites.forEach(e => {
                        let address = e.address.split(' ; ');
                        let party = {
                            type: Number(e.role),
                            u_id: e.u_uid,
                            name: e.first_name+' '+e.last_name,
                            p_name: e.first_name[0]+''+e.last_name[0],
                            image: this.user.email == e.email?this.user.image:null,
                            first_name: e.first_name,
                            last_name: e.last_name,
                            email: e.email,
                            link_email: e.link_email,
                            phone: e.phone,
                            role: e.role_name,
                            role_id: e.role_id,
                            role_label: e.role_label,
                            org_name: e.org_name,
                            address1: address && address[0]?address[0]:'',
                            address2: address && address[1]?address[1]:'',
                            default: this.user && this.user.email == e.email?true:false,
                            defaultEmail: this.user && this.user.email == e.email?true:false,
                            invite_id: e.invite_id,
                            order: e.order
                        }
                        parties.push(party);
                        if (e.role_label == 'Claimant') {
                            party.default = (this.user.email == e.email ? true: (claimants.length == 0 && (this.state.caseRegisterModel.role_label == 'Representative' || this.state.caseRegisterModel.role_label == 'Legal Representative'))) ? true: false;
                            claimants.push(party);
                        } else if (e.role_label == 'Respondent') {
                            respondents.push(party);
                        } else {
                            let count = res.data.invites.filter(e1 => e1.email == e.link_email && e1.role_label == 'Claimant').length;
                            let count1 = res.data.invites.filter(e1 => e1.email == e.link_email && e1.role_label == 'Respondent').length;
                            if (count > 0) {
                                claimants.push(party);
                            } else if (count1 > 0) {
                                respondents.push(party);
                            } else if (e.role_label != "Mediator" && e.role_label != "Arbitrator") {
                                claimants.push(party);
                            }
                        }
                    });
                    this.setState({partiesList: parties, claimantList: claimants, respondentList: respondents});
                    this.partiesList = parties;
                }
            });
    }

    editParty = (party, index, type) => {
        this.setState({selectedParty: party, partyAdd: false, participantVisible: true});
        let pindex = this.partiesList.findIndex(e => e.email == party.email);
        this.setState({editPartyType: type, editIndex: index, editPartyIndex: pindex, editPartyRole: party.role_label, editPartyRoleId: party.role_id, editPartyEmail: party.email});
        this.setState(prevState => ({
            addPartyModel: {
                ...prevState.addPartyModel,
                type: party.type,
                u_id: party.u_id,
                name: party.name,
                p_name: party.p_name,
                first_name: party.first_name,
                last_name: party.last_name,
                email: party.email,
                link_email: party.link_email,
                phone: party.phone,
                role: party.role,
                role_id: party.role_id,
                role_label: party.role_label,
                org_name: party.org_name,
                address1: party.address1,
                address2: party.address2,
                default: party.default,
                defaultEmail: party.defaultEmail,
                invite_id: party.invite_id,
                order: party.order
        }}));
        let l = this.state.roleList.filter(e => e.value == party.role_id);
        if (l.length == 0) {
            let m = this.state.allRoleList.filter(e => e.role_id == party.role_id);
            if(m) {
                let n = this.state.roleList;
                n.push({
                    value: m[0].role_id,
                    role: m[0].role_label,
                    label: m[0].role_name
                });
                let li = [];
                n.forEach(e => {
                    li.push(e)
                })
                this.setState({roleList: li});
            }
        } else {
            const l = this.defaultRoleList;
            let li = [];
            l.forEach(e => {
                li.push(e)
            })
            this.setState({roleList: li});
        }
        let list = this.partiesList.filter(e => e.role_label == 'Claimant' || e.role_label == 'Respondent');
        let ldisplay = [];
        list.forEach(e => {
            ldisplay.push({
                label: e.name,
                value: e.email
            });
        });
        this.setState({repList: ldisplay});
        if (!party.phone) {
            setTimeout(() => {
                $('.react-tel-input input').val('');
            }, 100);
        }
    }

    onTypeChange = (e) => {
        this.setState(prevState => ({
            addPartyModel: {
                ...prevState.addPartyModel,
                type: e.target.value
        }}));
    }

    onRepOf = (e) => {
        this.setState(prevState => ({
            addPartyModel: {
                ...prevState.addPartyModel,
                link_email: e
        }}));
    }

    onOrgName = (e) => {
        this.setState(prevState => ({
            addPartyModel: {
                ...prevState.addPartyModel,
                org_name: e.target.value
        }}));
    }

    onChangeFirstName = (e) => {
        this.setState(prevState => ({
            addPartyModel: {
                ...prevState.addPartyModel,
                first_name: e.target.value
        }}));
    }

    onChangeLastName = (e) => {
        this.setState(prevState => ({
            addPartyModel: {
                ...prevState.addPartyModel,
                last_name: e.target.value
        }}));
    }

    onChangeEmail = (e) => {
        this.setState(prevState => ({
            addPartyModel: {
                ...prevState.addPartyModel,
                email: e.target.value
        }}));
    }

    onChangePhone = (e) => {
        this.setState(prevState => ({
            addPartyModel: {
                ...prevState.addPartyModel,
                phone: e
        }}));
    }

    onChangeAddress1 = (e) => {
        this.setState(prevState => ({
            addPartyModel: {
                ...prevState.addPartyModel,
                address1: e.target.value
        }}));
    }

    onChangeAddress2 = (e) => {
        this.setState(prevState => ({
            addPartyModel: {
                ...prevState.addPartyModel,
                address2: e.target.value
        }}));
    }

    onRoleChange = (value) => {
        this.state.roleList.forEach(e => {
            if (e.value == value) {
                this.setState(prevState => ({
                    addPartyModel: {
                        ...prevState.addPartyModel,
                        role_id: value,
                        role_label: e.role,
                        role: e.label
                }}));
                if(this.state.partyAdd) {
                    if(e.role == 'Claimant' && this.state.claimantList.length >= this.state.maxPartyCount) {
                        this.setState({addClaimant: true});
                        message.error('Maximum number of users reached for this role.');
                    } else {
                        this.setState({addClaimant: false});
                    }
                    if(e.role == 'Respondent' && this.state.respondentList.length >= this.state.maxPartyCount) {
                        this.setState({addRespondant: true});
                        message.error('Maximum number of users reached for this role.');
                    } else {
                        this.setState({addRespondant: false});
                    }
                } else {
                    if(e.role == 'Claimant' && this.state.editPartyRole != e.role && this.state.claimantList.length >= this.state.maxPartyCount) {
                        this.setState({addClaimant: true});
                        message.error('Maximum number of users reached for this role.');
                    } else {
                        this.setState({addClaimant: false});
                    }
                    if(e.role == 'Respondent' && this.state.editPartyRole != e.role && this.state.respondentList.length >= this.state.maxPartyCount) {
                        this.setState({addRespondant: true});
                        message.error('Maximum number of users reached for this role.');
                    } else {
                        this.setState({addRespondant: false});
                    }
                }
            }
        });
    }

    previous = () => {
        this.setState({current: this.state.current - 1});
    }

    changeCurrent = (index) => {
        this.setState({current: index});
    }

    validateForm = () => {
        let res = [];
        let count;
        switch (this.state.current) {
            case 0:                
                res = [];
                if (!this.state.caseRegisterModel.category && (!this.state.caseRegisterModel.description || this.state.caseRegisterModel.description.trim().length == 0)) res.push('Please select a Category and proceed to enter Description.')
                else if (!this.state.caseRegisterModel.category) res.push('Please select a Category.')
                else if (!this.state.caseRegisterModel.description || this.state.caseRegisterModel.description.trim().length == 0) res.push('Please enter the Description.')
                count = this.state.impactList.filter(e => e.value == '' || e.value.trim().length == 0).length;
                if (count > 0) res.push('To successfully move forward, a user must either: (1) click the trash can button on the optional text box and delete the text and box; or (2) or enter a summary. If you do not do either you cannot navigate forward.');
                let count2 = this.state.seekingList.filter(e => e.value == '' || e.value.trim().length == 0).length;
                if (count == 0 && count2 > 0) res.push('To successfully move forward, a user must either: (1) click the trash can button on the optional text box and delete the text and box; or (2) or enter a summary. If you do not do either you cannot navigate forward.');
                break;
            case 1:
                res = [];
                if (!this.state.caseRegisterModel.case_type) res.push('Please select a Resolution Choice for the case.');
                break
            case 3:
                res = [];
                count = this.state.claimantList.filter(e => e.role_label == 'Claimant').length;
                let count1 = this.state.respondentList.filter(e => e.role_label == 'Respondent').length;
                let claimantRole = this.roles ? this.roles.Claimant: '';
                let respondentRole = this.roles ? this.roles.Respondent: '';
                if (count == 0) {
                    res.push('Please add at-least one '+claimantRole+' and one '+respondentRole+' to the case.')
                } else if (count1 == 0) {
                    res.push('Please add at-least one '+claimantRole+' and one '+respondentRole+' to the case.')
                }
                break
            default:
                res = [];
                break;
        }
        return res;
    }

    next = () => {
        this.setState({nextLoading: true});
        let res = this.validateForm();
        if (res.length == 0) {
            if (this.state.current == 0) {
                apiService.draftCase({
                        category: this.state.caseRegisterModel.category,
                        sub_category: this.state.caseRegisterModel.sub_category,
                        description: this.state.caseRegisterModel.description,
                        claim_amount: this.state.caseRegisterModel.claim_amount,
                        claim_currency: this.state.caseRegisterModel.claim_currency,
                        impact_reason: this.state.impactList,
                        seeking_reason: this.state.seekingList,
                        step: 0,
                        case_id: this.caseId,
                        role_label: !this.caseId && localStorage.getItem('case_reg_token')?atob(localStorage.getItem('case_reg_token')):this.state.caseRegisterModel.role_label
                    }).then((res) => {
                        this.setState({nextLoading: false});
                        if (res.status == 'success') {
                            message.success(res.message);
                            this.setState({current: this.state.current + 1});
                            if (res.case_id) {
                                this.history.push('/app/case-create/'+res.case_id);
                                this.caseId = res.case_id;
                                if (res.case_id && this.roleLabel == '') {
                                    apiService.checkDraftCase({
                                            case_id: res.case_id
                                        }).then((res) => {
                                            if (res.status === 'success') {
                                                this.roleLabel = res.data.case_created_by;
                                                this.setState({creatorRole: res.data.case_created_role})
                                                this.getRes();
                                            }
                                    });
                                }
                            }
                        }
                    })
            } else if (this.state.current == 1) {
                this.setState(prevState => ({
                    caseRegisterModel: {
                        ...prevState.caseRegisterModel,
                        role_label: this.roleLabel
                }}));
                this.getPartyRoles(this.roleLabel);
                apiService.draftCase({
                       case_type: this.state.caseRegisterModel.case_type,
                       step: 1,
                       case_id: this.caseId 
                    }).then((res) => {
                        this.setState({nextLoading: false});
                        if (res.status == 'success') {
                            message.success(res.message);
                            this.setState({current: this.state.current + 1});
                        }
                    });
            } else if (this.state.current == 2) {
                apiService.draftCase({
                    step: 2,
                    case_id: this.caseId
                }).then((res) => {
                    this.setState({nextLoading: false});
                    if (res.status == 'success') {
                        message.success(res.message);
                        this.setState({current: this.state.current + 1});
                    }
                });
                if (this.partiesList.length == 0 && this.user && this.state.caseRegisterModel.role_label == 'Claimant') {
                    let role = this.allRoleList.filter(e => e.role_label === 'Claimant');
                    let address = this.user.address?.split(' ; ');
                    let party = {
                        first_name: this.user.first_name,
                        last_name: this.user.last_name,
                        u_id: this.user.id,
                        email: this.user.email,
                        phone: this.user.phone,
                        name: this.user.name,
                        p_name: this.user.p_name,
                        image: this.user.image,
                        org_name: this.user.org_name,
                        type: this.user.org_name?1:0,
                        role: role && role[0]?.role_name,
                        role_id: role && role[0]?.role_id,
                        role_label: role && role[0]?.role_label,
                        address1: address && address[0]?address[0]:'',
                        address2: address && address[1]?address[1]:'',
                        default: true,
                        defaultEmail: true,
                        order: 1,
                        invite_id: ''
                    };
                    let claimants = [party];
                    this.setState({claimantList: claimants});
                    let parties = claimants.concat(this.state.respondentList);
                    this.setState({partiesList: parties});
                    this.partiesList = parties;
                    apiService.addCaseParty({
                        party: party,
                        case_id: this.caseId,
                        role_id: party.role_id,
                        type: 'add'
                    }).then((res) => {

                    });
                }
            } else if (this.state.current == 3) {
                apiService.draftCase({
                    step: 3,
                    case_id: this.caseId
                }).then((res) => {
                    this.setState({nextLoading: false});
                    if (res.status == 'success') {
                        message.success(res.message);
                        this.setState({current: this.state.current + 1});
                    }
                });
                this.getRes();
                this.getCategories();
                this.getPaymentGateways();
            } else {
                this.getRes();
                this.getCategories();
                this.getPaymentGateways();
                this.setState({nextLoading: false});
            }
        } else {
            this.setState({nextLoading: false});
            message.error(res.join('\n\n'));
            return;
        }
        // if(this.state.current != 4) {
        //     setCurrent(this.state.current + 1);
        // } else {
        //     setPaymentVisible(true);
        // }
    }

    createCase = (isRedirect = false) => {
        let neutralId = null;
        let neutralSelection = true;
        if (this.state.caseRegisterModel.case_type > 1) {
            if ((this.state.caseRegisterModel.role_label == 'Mediator' && this.state.caseRegisterModel.case_type == 2) || (this.state.caseRegisterModel.role_label == 'Arbitrator' && this.state.caseRegisterModel.case_type == 3)) {
                neutralId = this.user.id;
                neutralSelection = false;
            }
        }
        apiService.createCaseFromDraft({
            case_id: this.caseId,
            neutral_id: neutralId,
            selection_type: neutralSelection
        }).then((res) => {
            if (res.status == 'success') {
                this.setState({loadingDraft: false});
                if (res.notifications && res.notifications.parties && res.notifications.parties.length > 0) {
                    message.success(res.message);
                    res.notifications.parties.forEach((e, i) => {
                        websocketService.send({
                            method:'case',
                            isDisplay: true,
                            data: {
                                users: e,
                                content: res.notifications.instant_msg[i],
                                type: 'case_invite',
                                time_display: false,
                                case_name: res.notifications.case_name
                            }
                        });
                    });
                }
                if(isRedirect) {
                    this.history.push(`/app/case/${this.caseId}/overview`);
                }
            }
        });
    }

    getPaymentGateways = () => {
        apiService.getPaymentGateways()
            .then(res => {
                if (res.status == 'success') {
                    this.setState({paymentGatewayList: res.data});
                }
            });
    }

    createCaseOrPay = () => {
        if (!this.state.isDisplayPayment) {
            this.setState({loadingDraft: true});
            this.createCase(true);
        } else {
            this.setState({paymentVisible: true});
        }
    }

    makePayment = () => {
        if (!this.state.caseRegisterModel.paymentgateway) {
            message.error('Please select payment gateway.');
        } else if (!this.state.caseRegisterModel.agreed) {
            message.error('Please agree the terms and conditions.');
        } else {
            this.setState({makePaymentDisable: true});
            let gateway;
            this.state.paymentGatewayList.forEach(e => {
                if (e.p_g_id == this.state.caseRegisterModel.paymentgateway) {
                    gateway = e;
                }
            });
            if (gateway && gateway.name == 'burton') {
                this.setState({paymentLoading: true});
                apiService.getPaymentPlugin({                
                        amount: this.state.feeInfo.total_fee
                    }).then(res => {
                        if(res.status == 'success') { 
                            let pluginSessionId = res.data.plugin_session_id;
                            this.setState({pluginSessionId: res.data.plugin_session_id});
                            let paymentUrl = res.data.plugin_url.split('?');
                            let chatScript = document.createElement("script");
                            chatScript.type = "text/javascript";
                            chatScript.async = true;
                            chatScript.src = paymentUrl[0];
                            document.getElementsByTagName('head')[0].appendChild(chatScript);       
                            setTimeout(() => {
                                this.showPayment(pluginSessionId);
                            }, 3000); 
                        } else if(res.status == 'error') {
                            this.setState({paymentLoading: false, makePaymentDisable: false});
                            message.error(res.message);
                        }
                    }); 
            } else if (gateway && gateway.name == 'stripe') {
                // if (!Stripe) {
                    let stripeScript = document.createElement("script");
                    stripeScript.type = "text/javascript";
                    stripeScript.async = true;
                    stripeScript.src = "https://js.stripe.com/v3/";
                    document.getElementsByTagName('head')[0].appendChild(stripeScript); 
                // }
                setTimeout(() => {                        
                    //this.showStripePayment(gateway);
                }, 2000);
            }
        }
    }

    showPayment = (pluginSessionId) => {
        if(window.i3Plugins == undefined) { 
            this.showPayment(pluginSessionId);
            return;
        }
        let _this = this;
        window.i3Plugins.onPluginCreated = function(type, plugin) {   
            if (type == 'payment') {                                                                                            
                plugin.onEmbed = function(msg) {                                                                                                    
                    return "burton-pay";                       
                }
                plugin.onSale = function(msg) {
                    _this.createCase();
                    apiService.completeRegisterPayment({
                            case_id: _this.caseId,
                            currency_code: _this.state.currencyId,
                            amount: _this.state.feeInfo?.total_fee,
                            response: msg,
                            gateway: 'burton'
                        }).then(res => {
                            if(res.status == 'success') {
                                _this.showPaymentStatusDrawer();
                            }
                        });                                             
                    this.hide(); // Call the plugin's hide function to hide it
                }
                plugin.onCancel = function(msg) { 
                    _this.setState({paymentLoading: false});
                    this.hide(); // Call the plugin's hide function to hide it
                }   
                plugin.onReady = function(msg) {
                    _this.setState({paymentLoading: false});
                    var values = {                                
                        contact: {
                            email: _this.user.email,
                            first_name: _this.user.first_name,
                            last_name: _this.user.last_name,
                            // address1: '300 Oak Street',
                            // address2: '',
                            // city: 'Detroit',
                            // country: 'USA',
                            // state: 'WA',
                            // postal_code: '90210'
                        },                                
                    }                                                                  
                    plugin.setValues(values);                    
                } 
                plugin.onSubmitError = function(msg) {
                    let showErr = false;
                    if(msg.fields.length != 0 && msg.fields[0] == 'expiration_date') {
                        var error = {
                            messages: [
                                'Expiration Year is invalid',
                            ],
                            fields: [
                                'expiration_date',
                            ]
                        }
                        showErr = true;
                    } else if(msg.fields.length != 0 && msg.messages.length == 0 && msg.fields[0] == 'routing_number') {
                        // let message = msg.messages.length == 0 ? 'Routing Number is required' : msg.messages[0];  
                        if(msg.messages.length == 0) {            
                            var error = {
                                messages: [
                                    'Routing Number is required',
                                ],
                                fields: [
                                    'routing_number',
                                ]
                            }
                        }    
                        showErr = true;
                    } else if(msg.fields.length != 0 && msg.fields[0] == 'card_number') {
                        var error = {
                            messages: [
                                'Card Number is required'
                            ],
                            fields: [
                                'card_number'
                            ]
                        }
                        showErr = true;
                    } 

                    if(showErr)   
                        plugin.setValidationErrors(error);
                }
                plugin.onRequestError = function(msg) {
                    console.log(msg);
                }                                                                                             
            }
        }
        window.i3Plugins.launch(pluginSessionId);
    }

    render() {

        const { visible, resolutionViewMore, selectedResolution, participantVisible, rankMediatorVisible, paymentVisible, paymentStatusVisible, current, impactList, seekingList, loadingDraft, categoryList, subCategoryList, resolutionList, currencyList, caseRegisterModel, uploadList, fileList, roleList, allRoleList, claimantList, respondentList, partiesList, repList, partyAdd, partyAddLoading, isConfirmDelete, deleteLoading, isConfirmDeleteParty, feeInfo, isDisplayPayment, paymentGatewayList, currencyId, cSymbol, paymentLoading, addClaimant, addRespondant, nextLoading, addPartyModel, draftCaseInfo, isVisibleTerms, creatorRole, makePaymentDisable } = this.state;

        const selectBefore = (
            <Select defaultValue={caseRegisterModel.claim_currency?caseRegisterModel.claim_currency:'USD ($)'} onChange={(e) => this.changeCurrency(e)}>
                { currencyList.map((item, key) => (<Select.Option value={item.value} key={'cr_'+key}>{item.label}</Select.Option>))}
            </Select>
        )

        const disableSelectBefore = (
            <Select defaultValue={caseRegisterModel.claim_currency?caseRegisterModel.claim_currency:'USD ($)'} onChange={(e) => this.changeCurrency(e)} disabled>
                { currencyList.map((item, key) => (<Select.Option value={item.value} key={'cr_'+key}>{item.label}</Select.Option>))}
            </Select>
        )

        return (<div className="create-case-container">
            <Row className="header">
                <Col className="textCenter">
                    <CloseIcon onClick={this.onViewClose} />
                </Col>
                <Col span={22} className="textCenter heading">
                    Case Registration
                </Col>
            </Row>
            <Spin spinning={loadingDraft}>
                <div className="create-inner-section">
                    { current == 0 && (
                        <>
                            <Row className="create-case-header" justify="space-between">
                                <Col className="left">
                                    <span className="icon">1</span> Case Information
                                </Col>
                                <Col className="right">
                                    <div>
                                        Step 1 of 5 <span className="ml8" onClick={ this.showDrawer }><MenuIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="p16 step1">
                                <Col span={24}>
                                    <Form
                                        layout={"vertical"}
                                        >
                                            { draftCaseInfo && draftCaseInfo.case_addtl_ref_no && (<Row><Col span={24}>
                                                <h5 class="addtl-info">Case Number: <span>{ draftCaseInfo.case_addtl_ref_no }</span></h5>
                                                <h5 class="addtl-info mb24">Case Name: <span>{ draftCaseInfo.case_title }</span></h5>
                                            </Col></Row>)}
                                            <Row>
                                                <Col span={24} className="mb16">
                                                    <label className="info-label">Select the category and sub-category relevant to your case.</label>
                                                </Col>
                                            </Row>
                                            <Form.Item label="Case Category:">
                                                <Select placeholder="Choose the case category" value={caseRegisterModel.category} onChange={(e) => this.categoryChange(e)}>
                                                    { categoryList.map((list, index) => (
                                                        <Select.Option value={list.c_id}>{list.c_name}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item label={<><span className="form-label">Sub-category:</span><span className="label-optional"> &nbsp;(optional)</span></>}>
                                                <Select placeholder="Choose the case sub-category" value={caseRegisterModel.sub_category} onChange={(e) => this.subCategoryChange(e)}>
                                                    { subCategoryList.map((list, index) => (
                                                        <Select.Option value={list.c_id}>{list.c_name}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item className="head-label" label="Please give a summary of your dispute.">
                                                <p className="info-txt mb8">Note this will be visible to the other Party.</p>
                                                <span className="char-count">{ caseRegisterModel.description?caseRegisterModel.description.length:0 }/1000 characters left</span>
                                                <TextArea placeholder={this.customMessage && this.customMessage['CR_DESC_PH_TXT']?this.customMessage['CR_DESC_PH_TXT']:'Eg. Enter the case description.'} maxLength="1000" value={caseRegisterModel.description} onKeyUp={(e) => { e.target.value.length > 1000 && e.preventDefault(); }} onChange={(e) => this.onChangeDescription(e)} />
                                            </Form.Item>
                                            {/* <Form.Item className="head-label" label={<><span className="form-label">How you been impacted by the dispute? </span><span className="label-optional"> &nbsp;(optional)</span></>}>
                                                <p className="info-txt mb8">Please add each 'Impact' in order of their importance.</p>
                                                { impactList.map((item, key) => (
                                                    <Form.Item>
                                                        <div className="text-area-header">
                                                            <i className="index">{ key + 1 }</i>
                                                            <DeleteIcon onClick={ () => deleteImpacts(key) } />
                                                            <span className="char-count">{ item.value.length }/255 characters left</span>
                                                        </div>
                                                        <TextArea placeholder={this.customMessage && this.customMessage['CR_IMP_PH_TXT']?this.customMessage['CR_IMP_PH_TXT']:'Eg. Please enter.'} maxLength="255" value={ item.value } onChange={(e) => { setImpact(e, key)}}></TextArea>
                                                    </Form.Item>
                                                ))}
                                                { impactList.length < 5 && (<Button className="add-button w100" type="default" onClick={ addImpactedList }>Add</Button>)}
                                            </Form.Item>
                                            <Form.Item className="head-label" label={<><span className="form-label">What outcome(s) are you seeking? </span><span className="label-optional"> &nbsp;(optional)</span></>}>
                                                <p className="info-txt mb8">Please add each outcome in order of their importance.</p>
                                                { seekingList.map((item, key) => (
                                                    <Form.Item>
                                                        <div className="text-area-header">
                                                            <i className="index">{ key + 1 }</i>
                                                            <DeleteIcon onClick={ () => deleteSeeking(key) } />
                                                            <span className="char-count">{ item.value.length }/255 characters left</span>
                                                        </div>
                                                        <TextArea placeholder={this.customMessage && this.customMessage['CR_OUT_SEEK_PH_TXT']?this.customMessage['CR_OUT_SEEK_PH_TXT']:'Eg. Please enter.'} maxLength="255" value={ item.value } onChange={(e) => { this.setSeeking(e, key)}}></TextArea>
                                                    </Form.Item>
                                                ))}
                                                { seekingList.length < 5 && (<Button className="add-button w100" type="default" onClick={ addSeekingList }>Add</Button>)}
                                            </Form.Item> */}
                                            <Form.Item className="head-label" label={<><span className="form-label">What is the amount you are claiming? </span><span className="label-optional"> &nbsp;(optional)</span></>}>
                                                {/* <p className="info-txt mb8">If applicable, what is the financial value of your case?</p> */}
                                                <InputNumber 
                                                    name="claim_amount"
                                                    className="prop-input" 
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9.,]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    onKeyUp={(event) => {
                                                        if (!/[0-9.,]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    onPaste={(event) => {
                                                        if (!/[0-9.,]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    maxLength={13} 
                                                    step={0.01} 
                                                    addonBefore={selectBefore}
                                                    placeholder={"Enter the amount here"}
                                                    onChange={(e) => this.handleClaimChange(e)} onBlur={(e) => this.formatCurrency(e)}
                                                    value={caseRegisterModel.claim_amount_value}
                                                    />
                                            </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </>
                    )}
                    { current == 1 && (
                        <>
                            <Row className="create-case-header" justify="space-between">
                                <Col className="left">
                                    {/* <Badge count={2} style={{ background: '#136796' }} /> Resolution Choice */}
                                    <span className="icon">2</span> Resolution Choice
                                </Col>
                                <Col className="right">
                                    <div>
                                        Step 2 of 5 <span className="ml8" onClick={ this.showDrawer }><MenuIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="p16 step2">
                                <Col span={24}>
                                    <span className="select-mode">Select the mode of Resolution:</span>
                                    { resolutionList.map((item, key) => (                                        
                                        <Card onClick={() => this.onClickRes(item)} title={<><Avatar src={item.icon}></Avatar> <span className="resolution-mode">{ item.rc_name }</span></>} extra={<Checkbox checked={caseRegisterModel.case_type == item.rc_id? true:false} onChange={(e) => this.onChangeRes(e, item)}></Checkbox>} key={key}>
                                            <p className="content">
                                                {item.rc_description?.length > 200 ?
                                                    <span dangerouslySetInnerHTML={{ __html: item.rc_description.slice(0, 200) }} className="my-4"></span>
                                                :
                                                    <span dangerouslySetInnerHTML={{ __html: item.rc_description}} className="my-4"></span>
                                                }
                                                {(item.rc_description.length > 200 || item.feeInfo) && <a onClick={() => this.showResolutionViewMore(item)}> more</a>}
                                            </p>
                                            {/* <div className="know-more-contain"><InfoIcon/> <span className="know-more">Know more</span></div> */}
                                        </Card>
                                    ))}
                                </Col>
                            </Row>
                        </>
                    )}
                    { current == 2 && (
                        <>
                            <Row className="create-case-header" justify="space-between">
                                <Col className="left">
                                    <span className="icon">3</span> Evidence
                                </Col>
                                <Col className="right">
                                    <div>
                                        Step 3 of 5 <span className="ml8" onClick={ this.showDrawer }><MenuIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="p16 step3">
                                <Col span={24}>
                                    <span className="file-label">Do you want to add files to support your case? <p className="file-label-optional">(optional)</p></span>
                                    <p className="file-text"><i>Note that any files you upload will be visible to all Participants in the case.</i></p>
                                    <Card className="upload-details">
                                        <Upload showUploadList={false} multiple={true} beforeUpload={this.beforeUpload} customRequest={this.fileUpload}>
                                            <p className="browse-file">Browse file</p>
                                        </Upload>
                                        <p className="file-size">Maximum upload file size : 300 MB</p>
                                        <p className="file-extension">(JPEG, GIF, BMP, PNG, SVG, TIFF, MP3, MP4, 3GP, FLV, WMV, MKV, AVI, WEBM, OGG, PDF, DOC, DOCX, CSV, XLS, XSLX, PPT, PPTX, TXT)</p>
                                    </Card>
                                    { uploadList.map((item,key) => (<div className="file-list-details" key={key}>
                                        <div className="file-list-container">
                                            <div className="file-list">
                                                <GalleryIcon style={{fontSize: 16, marginRight: "8px"}} />
                                                <span>{ item.original_name }</span>
                                            </div>
                                            <Progress showInfo={false} percent={item.result?.f_id?100:50} />
                                        </div>
                                        {/* <div className="delete-icon-contain">
                                            <DeleteIcon className="delete-icon"/>
                                        </div> */}
                                    </div>))}
                                    { fileList.length > 0 && (<div className="uploded-files-details mt8">
                                        <span className="uploded-files">Uploaded Files</span>
                                        <p className="description">We suggest you add a file description so other participants know what the file is for.</p>
                                        { fileList.map((file, key) => (<Card className="preview-file-details mb16" key={key}>
                                            <div className="preview-file-icon">
                                                <DeleteIcon onClick={() => this.deleteCaseFile(file, key)} className="delete-icon"/>
                                            </div>
                                            <div className="preview-file">
                                                { (file.ext == 'png' || file.ext == 'jpg' || file.ext == 'jpeg' || file.ext == 'gif' || file.ext == 'bmp' || file.ext == 'svg') && <img src={file.url} alt="" style={{width: '100%'}} />}  
                                                { (file.ext == 'xls' || file.ext == 'xlsx' || file.ext == 'csv') && <img src={ExcelImg} />}
                                                { (file.ext == 'doc' || file.ext == 'docx') && <img src={WordImg} />}
                                                { (file.ext == 'ppt' || file.ext == 'pptx') && <img src={PptImg} />}
                                                { (file.ext == 'flv' || file.ext == 'wmv' || file.ext == 'avi') && <img src={VideoImg} />}
                                                { file.ext == 'txt' && <img src={TextImg} />}
                                                { (file.ext == 'tiff' || file.ext == 'tif') && <img src={ImageImg} />} 
                                                { file.ext == 'pdf' && <img src={PdfImg} />}
                                                { (file.ext == 'mp3' || file.ext == 'ogg') && <img src={AudioImg} />}     
                                                { (file.ext == 'mp4' || file.ext == '3gp' || file.ext == 'webm') && <img src={VideoImg} />}
                                                {/* <img src={MobImg} alt="img" width="100%"/> */}
                                            </div>
                                            <div className="file-name">
                                                <GalleryIcon /> <p>{file.original_name}</p>
                                            </div>
                                            <div className="file-description">
                                                <TextArea placeholder="Add your file description here" defaultValue={file.description} onBlur={(e) => this.saveFileDesc(e, key)} maxLength={250} />
                                            </div>
                                        </Card>))}
                                    </div>)}  
                                    <Modal className="draft-confirm-delete" title="Confirm" visible={isConfirmDelete} onCancel={() => this.deleteFileCancel()} footer={<><Row gutter={16}><Col span={12}><Button onClick={() => this.confirmDeleteFile()} loading={deleteLoading} disabled={deleteLoading} type="primary">Yes</Button></Col><Col span={12}><Button disabled={deleteLoading} onClick={() => this.deleteFileCancel()} type="link">No</Button></Col></Row></>}>
                                        <p>Are you sure you want to delete this file?</p>
                                    </Modal>
                                </Col>
                            </Row>
                        </>
                    )}
                    { current == 3 && (
                        <>
                            <Row className="create-case-header" justify="space-between">
                                <Col className="left">
                                    {/* <Badge count={2} style={{ background: '#136796' }} /> Resolution Choice */}
                                    <span className="icon">4</span> Invite Parties
                                </Col>
                                <Col className="right">
                                    <div>
                                        Step 4 of 5 <span className="ml8" onClick={ this.showDrawer }><MenuIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="p16 step4">
                                <Col span={24}>
                                    <div className="invite-head">
                                        <Row>
                                            <Col span={14}>
                                                <span className="head-text">Invite Parties & Users</span>
                                            </Col>
                                            <Col span={10}>
                                                <Button type="primary" size="large" className="w100" onClick={ this.showParticipantDrawer }> Add Participant </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row>
                                        <p className="invite-info-text">You will be able to add multiple Participants who should be part of this case. Once you create the case, the invited Participants will receive an email to join the case.</p>
                                    </Row>
                                    <Row>
                                        <Collapse
                                            className="parties-details mb16"
                                            defaultActiveKey={["1"]}
                                            onChange={this.callback}
                                            expandIconPosition={"right"}
                                        >
                                            <Panel header={<>{this.roles ? this.roles.Claimant: 'Claimant'}(s)</>} key="1">
                                                { claimantList.map((party, key) => (<Card className="parties-card mt8" key={key}>
                                                    <Row gutter={12}>
                                                        <Col span={12} className="label">
                                                            Name
                                                        </Col>
                                                        <Col span={12} className="role label right">
                                                            Role
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb12" gutter={12}>
                                                        <Col span={12}>
                                                            <Avatar src={party?.image}>{party.p_name}</Avatar>
                                                            <span className="name-text">{ party.name }</span>
                                                        </Col>
                                                        <Col span={12} className="text right">
                                                            <Tooltip title={ this.getRoleWithOrder(party.role, party.role_label, party.order, party.link_email) }>
                                                                { this.getRoleWithOrder(party.role, party.role_label, party.order, party.link_email) }
                                                            </Tooltip>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={12}>
                                                        <Col span={12} className="label">
                                                            Email
                                                        </Col>
                                                        <Col span={12} className="label right">
                                                            Party
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb12" gutter={12}>
                                                        <Col span={12} className="text" title={ party.email }>
                                                            <Tooltip title={ party.email }>
                                                                { party.email }
                                                            </Tooltip>
                                                        </Col>
                                                        <Col span={12} className="text right">
                                                            <Tooltip title={ party.link_email?this.partyRole(party.link_email):this.getRoleWithOrder(party.role, party.role_label, party.order, party.link_email) }>
                                                                { party.link_email?this.partyRole(party.link_email):this.getRoleWithOrder(party.role, party.role_label, party.order, party.link_email) }
                                                            </Tooltip>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={12}>
                                                        <Col span={12}>
                                                            <Row className="mobile label">Mobile</Row>
                                                            <Row className="mobile-number text">{ party.phone? party.phone: 'No data provided' }</Row>
                                                        </Col>
                                                        <Col span={12} className="icon">
                                                            <EditIcon onClick={() => this.editParty(party, key, 0) } className="edit-icon" />
                                                            <DeleteIcon onClick={() => !party.default && this.deleteParty(party, key)} style={{ opacity: !party.default?'1':'0.5', fontSize: '24px', verticalAlign: 'middle'}} className="delete-icon"/>
                                                        </Col>
                                                    </Row>
                                                </Card>))}
                                                { claimantList.length == 0 && (<Row>
                                                    <p className="end-text">To create a case, you must have at least one {this.roles ? this.roles.Claimant: 'Claimant'}. To add a {this.roles ? this.roles.Claimant: 'Claimant'}, click "Add Participant."</p>
                                                </Row>)}
                                            </Panel>
                                        </Collapse>

                                        <Collapse
                                            className="parties-details mb16"
                                            defaultActiveKey={["2"]}
                                            onChange={this.callback}
                                            expandIconPosition={"right"}
                                        >
                                            <Panel header={<>{this.roles ? this.roles.Respondent: 'Respondent'}(s)</>} key="2">
                                                { respondentList.map((party, key) => (<Card className="parties-card mt8" key={key}>
                                                    <Row gutter={12}>
                                                        <Col span={12} className="label">
                                                            Name
                                                        </Col>
                                                        <Col span={12} className="role label right">
                                                            Role
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb12" gutter={12}>
                                                        <Col span={12}>
                                                            <Avatar src={ party.image }>{ party.p_name }</Avatar>
                                                            <span className="name-text">{ party.name }</span>
                                                        </Col>
                                                        <Col span={12} className="text right">
                                                            <Tooltip title={ this.getRoleWithOrder(party.role, party.role_label, party.order, party.link_email) }>
                                                                { this.getRoleWithOrder(party.role, party.role_label, party.order, party.link_email) }
                                                            </Tooltip>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={12}>
                                                        <Col span={12} className="label">
                                                            Email
                                                        </Col>
                                                        <Col span={12} className="label right">
                                                            Party
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb12" gutter={12}>
                                                        <Col span={12} className="text">
                                                            <Tooltip title={ party.email }>
                                                                { party.email }
                                                            </Tooltip>
                                                        </Col>
                                                        <Col span={12} className="text right">
                                                            <Tooltip title={ party.link_email?this.partyRole(party.link_email):this.getRoleWithOrder(party.role, party.role_label, party.order, party.link_email) }>
                                                                { party.link_email?this.partyRole(party.link_email):this.getRoleWithOrder(party.role, party.role_label, party.order, party.link_email) }
                                                            </Tooltip>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={12}>
                                                        <Col span={12}>
                                                            <Row className="mobile label">Mobile</Row>
                                                            <Row className="mobile-number text">{ party.phone? party.phone: 'No data provided' }</Row>
                                                        </Col>
                                                        <Col span={12} className="icon">
                                                            <EditIcon onClick={() => this.editParty(party, key, 1)} className="edit-icon" />
                                                            <DeleteIcon onClick={() => !party.default && this.deleteParty(party, key)} className="delete-icon"/>
                                                        </Col>
                                                    </Row>
                                                </Card>))}
                                                { respondentList.length == 0 && (<Row>
                                                    <p className="end-text">To create a case, you must have at least one {this.roles ? this.roles.Respondent: 'Respondent'}. To add a {this.roles ? this.roles.Respondent: 'Respondent'}, click "Add Participant."</p>
                                                </Row>)}
                                            </Panel>
                                        </Collapse>
                                        
                                        {   caseRegisterModel.role_label == 'Case Officer' &&
                                            (
                                                <Collapse
                                                    className="parties-details mb16"
                                                    defaultActiveKey={["3"]}
                                                    onChange={this.callback}
                                                    expandIconPosition={"right"}
                                                >
                                                    <Panel header={<>{this.roles ? this.roles[ 'Case Officer' ]: 'BBB Admin'}</>} key="3">
                                                        <Card className="parties-card mt8">
                                                            <Row gutter={12}>
                                                                <Col span={12} className="label">
                                                                    Name
                                                                </Col>
                                                                <Col span={12} className="role label right">
                                                                    Role
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb12" gutter={12}>
                                                                <Col span={12}>
                                                                    <Avatar src={ this.user?.image }>{ this.user?.p_name }</Avatar>
                                                                    <span className="name-text">{ this.user?.name }</span>
                                                                </Col>
                                                                <Col span={12} className="text right">
                                                                    { creatorRole?creatorRole.role_name:(this.user?.role_name) }
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={12}>
                                                                <Col span={12} className="label">
                                                                    Email
                                                                </Col>
                                                                <Col span={12} className="label right">
                                                                    Party
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb12" gutter={12}>
                                                                <Col span={12} className="text">
                                                                    { this.user?.email }
                                                                </Col>
                                                                <Col span={12} className="text right">
                                                                    Not Applicable
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={12}>
                                                                <Col span={12}>
                                                                    <Row className="mobile label">Mobile</Row>
                                                                    <Row className="mobile-number text">{ this.user?.phone? this.user?.phone: 'No data provided' }</Row>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Panel>
                                                </Collapse>
                                            )
                                        }

                                        {   ((caseRegisterModel.role_label == 'Mediator' && caseRegisterModel.case_type == 2) || (caseRegisterModel.role_label == 'Arbitrator' && caseRegisterModel.case_type == 3)) &&
                                            (
                                                <Collapse
                                                    className="parties-details mb16"
                                                    defaultActiveKey={["4"]}
                                                    onChange={this.callback}
                                                    expandIconPosition={"right"}
                                                >
                                                    <Panel header={<>{ caseRegisterModel.case_type == 2?this.roles?.Mediator:this.roles?.Arbitrator }</>} key="4">
                                                        <Card className="parties-card mt8">
                                                            <Row gutter={12}>
                                                                <Col span={12} className="label">
                                                                    Name
                                                                </Col>
                                                                <Col span={12} className="role label right">
                                                                    Role
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb12" gutter={12}>
                                                                <Col span={12}>
                                                                    <Avatar src={ this.user?.image }>{ this.user?.p_name }</Avatar>
                                                                    <span className="name-text">{ this.user?.name }</span>
                                                                </Col>
                                                                <Col span={12} className="text right">
                                                                    { creatorRole?creatorRole.role_name:(this.user?.role_name) }
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={12}>
                                                                <Col span={12} className="label">
                                                                    Email
                                                                </Col>
                                                                <Col span={12} className="label right">
                                                                    Party
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb12" gutter={12}>
                                                                <Col span={12} className="text">
                                                                    { this.user?.email }
                                                                </Col>
                                                                <Col span={12} className="text right">
                                                                    Not Applicable
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={12}>
                                                                <Col span={12}>
                                                                    <Row className="mobile label">Mobile</Row>
                                                                    <Row className="mobile-number text">{ this.user?.phone? this.user?.phone: 'No data provided' }</Row>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Panel>
                                                </Collapse>
                                            )
                                        }
                                    </Row>                                      
                                    <Modal className="draft-confirm-delete" title="Confirm" visible={isConfirmDeleteParty} onCancel={() => this.deletePartyCancel()} footer={<><Row gutter={16}><Col span={12}><Button onClick={() => this.confirmDeleteParty()} loading={deleteLoading} disabled={deleteLoading} type="primary">Yes</Button></Col><Col span={12}><Button disabled={deleteLoading} onClick={() => this.deletePartyCancel()} type="link">No</Button></Col></Row></>}>
                                        <p>Are you sure you want to delete this party?</p>
                                    </Modal>
                                    {/* <Row>
                                        <Collapse
                                            className="mediator-details mb16"
                                            defaultActiveKey={3}
                                            onChange={this.callback}
                                            expandIconPosition={"right"}
                                        >
                                            <Panel header="Mediator" key="3">
                                                <Col>
                                                    <Card className="mediator-card mt8">
                                                        <Row>
                                                            <Col span={24}>
                                                                <Row className="mediator-check-container">
                                                                    <Col span={19}>
                                                                        <p>I want the Platform to allocate the most suitable mediator for my case.</p>
                                                                    </Col>
                                                                    <Col span={3}>
                                                                        <Switch id="mediator-check" checked={true}/>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mediator-choose-container">
                                                                    <Col span={17}>
                                                                        <p>I want to choose my own mediator.</p>
                                                                        <span>Once you and the other party make your selection, we will try and provide the closest match.</span>
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <Button id="mediator-choose" type="primary" onClick={ this.showRankMediatorDrawer }>Change</Button>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            </Panel>
                                        </Collapse>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Card className="rank-preference mb8 ">
                                                <Row className="rank-preference-detail">
                                                    <Col span={2} className="sno">
                                                        {1}
                                                    </Col>
                                                    <Col span={22} className="icon-container">
                                                        <Row className="icon">
                                                            <Col span={16}></Col>
                                                            <Col span={4}><ArrowTopIcon /></Col>
                                                            <Col span={4}><ArrowDownIcon /></Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className="rank-created-detail">
                                                    <Col span={24} className="created-details">
                                                        <Row>
                                                            <Col span={4} className="avatar"><Avatar>JR</Avatar></Col>
                                                            <Col span={16}>
                                                                <Row>
                                                                    <Col className="name">Jane Raymond</Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col className="role">Mediator, Lawyer</Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row className="pt10">
                                                            <Col>
                                                                <span className="bio">Results-driven corporate lawyer with 18+ years of experience ensuring the legality of commercial transactions. Adept at drafting <a className="bio-readmore" style={{color:"#136796"}}>{ '...More' }</a></span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card>
                                            <Card className="rank-preference mb8 ">
                                                <Row className="rank-preference-detail">
                                                    <Col span={2} className="sno">
                                                        {2}
                                                    </Col>
                                                    <Col span={22} className="icon-container">
                                                        <Row className="icon">
                                                            <Col span={16}></Col>
                                                            <Col span={4}><ArrowTopIcon /></Col>
                                                            <Col span={4}><ArrowDownIcon /></Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className="rank-created-detail">
                                                    <Col span={24} className="created-details">
                                                        <Row>
                                                            <Col span={4} className="avatar"><Avatar>JR</Avatar></Col>
                                                            <Col span={16}>
                                                                <Row>
                                                                    <Col className="name">Jane Raymond</Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col className="role">Mediator, Lawyer</Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row className="pt10">
                                                            <Col>
                                                                <span className="bio">Results-driven corporate lawyer with 18+ years of experience ensuring the legality of commercial transactions <a className="bio-readmore" style={{color:"#136796"}}>{ '...More' }</a></span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card>
                                            <Card className="rank-preference mb8 ">
                                                <Row className="rank-preference-detail">
                                                    <Col span={2} className="sno">
                                                        {3}
                                                    </Col>
                                                    <Col span={22} className="icon-container">
                                                        <Row className="icon">
                                                            <Col span={16}></Col>
                                                            <Col span={4}><ArrowTopIcon /></Col>
                                                            <Col span={4}><ArrowDownIcon /></Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className="rank-created-detail">
                                                    <Col span={24} className="created-details">
                                                        <Row>
                                                            <Col span={4} className="avatar"><Avatar>JR</Avatar></Col>
                                                            <Col span={16}>
                                                                <Row>
                                                                    <Col className="name">Jane Raymond</Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col className="role">Mediator, Lawyer</Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row className="pt10">
                                                            <Col>
                                                                <span className="bio">Results-driven corporate lawyer with 18+ years of experience ensuring the legality of commercial transactions. Adept at drafting <a className="bio-readmore" style={{color:"#136796"}}>{ '...More' }</a></span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row> */}
                                </Col>
                            </Row>
                        </>
                    )}
                    { current == 4 && (
                        <>
                            <Row className="create-case-header" justify="space-between">
                                <Col className="left">
                                    {/* <Badge count={2} style={{ background: '#136796' }} /> Resolution Choice */}
                                    <span className="icon">5</span> { isDisplayPayment?'Review & Pay':'Review' }
                                </Col>
                                <Col className="right">
                                    <div>
                                        Step 5 of 5 <span className="ml8" onClick={ this.showDrawer }><MenuIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="step5">
                                <Col span={24}>

                                    <Collapse
                                        className="view-case-collapse mb16"
                                        defaultActiveKey={["1"]}
                                        onChange={this.callback}
                                        expandIconPosition={"right"}
                                    >
                                        <Panel header={<><span className="collapse-sno">1</span> Case Information <EditIcon onClick={() => this.changeCurrent(0)} className="edit-icon" /></>} key="1">
                                            { draftCaseInfo && draftCaseInfo.case_addtl_ref_no && (<Col span={24}>
                                                <h5 class="addtl-info">Case Number: <span>{ draftCaseInfo.case_addtl_ref_no }</span></h5>
                                                <h5 class="addtl-info mb24">Case Name: <span>{ draftCaseInfo.case_title }</span></h5>
                                            </Col>)}
                                            <Row className="mb16">
                                                <Col span={24} className="mb16">
                                                    <label>Select the category and sub-category relevant to your case.</label>
                                                </Col>
                                                <Col span={24}>
                                                    <Row className="mb4">
                                                        <span className="sub-label">Case Category:</span>
                                                    </Row>
                                                    <Row>
                                                        <Select placeholder="Select" value={caseRegisterModel.category} disabled>
                                                            { categoryList.map((list, index) => (
                                                                <Select.Option value={list.c_id}>{list.c_name}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="mb16">
                                                <Col span={24}>
                                                    <Row className="mb4">
                                                        <span className="sub-label">Sub-category:</span>
                                                    </Row>
                                                    <Row>    
                                                        <Select placeholder="No Data" value={caseRegisterModel.sub_category} disabled>
                                                            { subCategoryList.map((list, index) => (
                                                                <Select.Option value={list.c_id}>{list.c_name}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="mb16">
                                                <Col span={24} className="mb4">
                                                    <label>Case Summary</label>
                                                </Col>
                                                <Col span={24}>
                                                    <TextArea value={caseRegisterModel.description} disabled />
                                                </Col>
                                            </Row>
                                            {/* <Row className="mb16">
                                                <Col span={24} className="mb4">
                                                    <label>How you been impacted by the dispute? </label> <span className="optional-text">(optional)</span>
                                                    <p className="info-text">Please add each 'Impact' in order of their importance.</p>
                                                </Col>
                                                { impactList.length == 0 && <Col span={24}>
                                                    <span className="not-answered">Not answered.</span>
                                                </Col>}
                                                { impactList.map((item, key) => (<Col span={24}>
                                                    <TextArea value={item.value} readOnly />
                                                </Col>))}
                                            </Row>
                                            <Row className="mb16">
                                                <Col span={24} className="mb4">
                                                    <label>What outcome(s) are you seeking? </label> <span className="optional-text">(optional)</span>
                                                    <p className="info-text">Please add each outcome in order of their importance.</p>
                                                </Col>
                                                { seekingList.length == 0 && <Col span={24}>
                                                    <span className="not-answered">Not answered.</span>
                                                </Col>}
                                                { seekingList.map((item, key) => (<Col span={24}>
                                                    <TextArea value={item.value} readOnly />
                                                </Col>))}
                                            </Row> */}
                                            <Row className="mb16">
                                                <Col span={24} className="mb4">
                                                    <label>What is the amount you are claiming? </label> <span className="optional-text">(optional)</span>
                                                </Col>
                                                <Col span={24}>
                                                    <InputNumber 
                                                    name="claim_amount"
                                                    className="prop-input" 
                                                    addonBefore={disableSelectBefore}
                                                    placeholder={"Not answered."}
                                                    value={caseRegisterModel.claim_amount_value}
                                                    disabled
                                                    />
                                                </Col>
                                            </Row>
                                        </Panel>
                                    </Collapse>

                                    <Collapse
                                        className="view-case-collapse mb16"
                                        onChange={this.callback}
                                        expandIconPosition={"right"}
                                    >
                                        <Panel header={<><span className="collapse-sno">2</span> Resolution Choice <EditIcon onClick={() => this.changeCurrent(1)} className="edit-icon" /></>} key="2">
                                            <Card title={<><Avatar src={selectedResolution.icon}></Avatar> <span className="resolution-mode">{selectedResolution.rc_name}</span></>}>
                                                <p className="content" dangerouslySetInnerHTML={{ __html: selectedResolution.rc_description}}></p>
                                            </Card>
                                        </Panel>
                                    </Collapse>

                                    <Collapse
                                        className="view-case-collapse mb16"
                                        onChange={this.callback}
                                        expandIconPosition={"right"}
                                    >
                                        <Panel header={<><span className="collapse-sno">3</span> Evidence <EditIcon onClick={() => this.changeCurrent(2)} className="edit-icon" /></>} key="2">
                                            {   fileList && fileList.length > 0 
                                                ?
                                                    fileList.map((file, key) => (<Card className="evidence-card mb8" key={key} title={<><GalleryIcon/> <span className="img-name">{ file.original_name }</span></>}>
                                                    { file.description ? <p className="img-description">{ file.description }</p>: <p className="no-img-description">No File Description provided</p>}
                                                    </Card>))
                                                :
                                                    <span className="no-img-name">Not uploaded</span>
                                            }
                                            
                                        </Panel>
                                    </Collapse>

                                    <Collapse
                                        className="view-case-collapse mb16"
                                        onChange={this.callback}
                                        expandIconPosition={"right"}
                                    >
                                        <Panel header={<><span className="collapse-sno">4</span> Invite Parties <EditIcon onClick={() => this.changeCurrent(3)} className="edit-icon" /></>} key="2">
                                            { partiesList.map((party, key) => (<Card className="parties-card">
                                                <Row gutter={12}>
                                                    <Col span={12} className="label">
                                                        Name
                                                    </Col>
                                                    <Col span={12} className="role label right">
                                                        Role
                                                    </Col>
                                                </Row>
                                                <Row className="mb12" gutter={12}>
                                                    <Col span={12}>
                                                        <Avatar src={party.image}>{ party.p_name }</Avatar>
                                                        <span className="name-text">{ party.name }</span>
                                                    </Col>
                                                    <Col span={12} className="text right">
                                                        { this.getRoleWithOrder(party.role, party.role_label, party.order, party.link_email) }
                                                    </Col>
                                                </Row>
                                            </Card>))}
                                            { caseRegisterModel.role_label == 'Case Officer' && (<Card className="parties-card">
                                                    <Row gutter={12}>
                                                        <Col span={12} className="label">
                                                            Name
                                                        </Col>
                                                        <Col span={12} className="role label right">
                                                            Role
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb12" gutter={12}>
                                                        <Col span={12}>
                                                            <Avatar src={ this.user?.image }>{ this.user?.p_name }</Avatar>
                                                            <span className="name-text">{ this.user?.name }</span>
                                                        </Col>
                                                        <Col span={12} className="text right">
                                                            { creatorRole?creatorRole.role_name:(this.user?.role_name) }
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            )}
                                            { ((caseRegisterModel.role_label == 'Mediator' && caseRegisterModel.case_type == 2) || (caseRegisterModel.role_label == 'Arbitrator' && caseRegisterModel.case_type == 3)) && (
                                                <Card className="parties-card">
                                                    <Row gutter={12}>
                                                        <Col span={12} className="label">
                                                            Name
                                                        </Col>
                                                        <Col span={12} className="role label right">
                                                            Role
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb12" gutter={12}>
                                                        <Col span={12}>
                                                            <Avatar src={ this.user?.image }>{ this.user?.p_name }</Avatar>
                                                            <span className="name-text">{ this.user?.name }</span>
                                                        </Col>
                                                        <Col span={12} className="text right">
                                                            { creatorRole?creatorRole.role_name:(this.user?.role_name) }
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            )}
                                        </Panel>
                                    </Collapse>

                                </Col>
                            </Row>
                        </>
                    )}
                </div>
                <div className="c-footer">
                    <Row gutter={32} style={{ margin: 0 }} justify="space-between">
                        <Col span={12}>
                            { current == 0 && (
                                <Button
                                    type="default"
                                    size="large"
                                    className="cancel-btn w100"
                                    onClick={ this.onViewClose }
                                >
                                    Cancel
                                </Button>
                            )}
                            { current != 0 && (
                                <Button
                                type="default"
                                size="large"
                                className="previous-btn w100"
                                onClick={ this.previous }
                            >
                                Previous
                            </Button>
                            )}
                        </Col>
                        { current != 4 && <Col span={12}>
                            <Spin spinning={nextLoading}>
                                <Button
                                    type="primary"
                                    size="large"
                                    className="w100"
                                    onClick={ this.next }
                                >
                                    Next
                                </Button>
                            </Spin>
                        </Col>}
                        { current == 4 && <Col span={12}>
                            <Button
                                type="primary"
                                size="large"
                                className="w100"
                                onClick={ this.createCaseOrPay }
                            >
                                {isDisplayPayment ? 'Confirm & Pay': 'Create Case'}
                            </Button>
                        </Col>}
                    </Row>
                </div>
            </Spin>

            <Drawer
                placement="bottom"
                closable={true}
                mask={true} 
                title={'Case Registration'}
                className="create-case-step-drawer"
                contentWrapperStyle={{ bottom: 0}}
                onClose={this.onClose}
                visible={visible}
                zIndex={1200}>
                <Steps current={current} direction="vertical">
                    <Step icon={<span>1</span>} title="Case Information" />
                    <Step icon={<span>2</span>} title="Resolution Choice" />
                    <Step icon={<span>3</span>} title="Evidence" />
                    <Step icon={<span>4</span>} title="Invite Parties" />
                    <Step icon={<span>5</span>} title={ isDisplayPayment?'Review & Pay':'Review' } />
                </Steps>
            </Drawer>

            <Drawer
                placement="bottom"
                title={<><Avatar src={selectedResolution.icon}></Avatar> <span className="resolution-mode">{ selectedResolution.rc_name }</span></>}
                closable={false}
                className="resolution-view-drawer"
                contentWrapperStyle={{ bottom: 0}}
                extra={<CloseIcon onClick={this.onCloseResolutionViewMore}/>}
                visible={resolutionViewMore}
                zIndex={1001}
            >
                <Row className="resolution-view-detail">
                    <Row>
                        <Col span={24}>
                            <p className="resolution-description" dangerouslySetInnerHTML={{ __html: selectedResolution.rc_description}}></p>
                        </Col>
                    </Row>
                    { selectedResolution.feeInfo && (<Row>
                        <Col span={24} className="fee-info">
                            <p>{ selectedResolution.rc_name } fee</p>
                            <span className="amount">{ this.settings && this.settings.currency.symbol }{ selectedResolution.feeInfo?.total_fee }</span>
                        </Col>
                    </Row>)}
                    {/* <Row>
                        <Col span={24}>
                            <div className="know-more-contain"><InfoIcon/> <span className="know-more">Know more</span></div>
                        </Col>
                    </Row> */}
                </Row>
            </Drawer>

            <Drawer
                placement="bottom"
                closable={false}
                title={partyAdd?'Add Participant':'Edit Participant'}
                className="add-participants-drawer"
                visible={participantVisible}
                extra={<CloseIcon onClick={this.onCloseParticipant}/>}
            >
                <Form>
                    <Row className="participant-form mb16">
                        <Row className="mb16">
                            <Form.Item name='type' className="w100 mb0">
                                <Col>
                                    <Radio.Group onChange={(e) => this.onTypeChange(e)} defaultValue={addPartyModel.type} value={addPartyModel.type} disabled={addPartyModel.default}>
                                        <Radio.Button title="Individual" value={0} checked={addPartyModel.type == 0}><IndividualIcon /> Individual</Radio.Button>
                                        <Radio.Button title="Organization" value={1} checked={addPartyModel.type == 1}><OrganizationIcon /> Organization</Radio.Button>
                                    </Radio.Group>
                                </Col>
                            </Form.Item>
                        </Row>
                        <Row className="mb16">
                            {/* <Col span={24} className="mb4">
                                <label>Select Participant role</label>
                            </Col> */}
                            <label className="form-label">Select Participant role</label>
                            <Select placeholder="Select Participant role" value={addPartyModel.role_id?addPartyModel.role_id:undefined} disabled={addPartyModel.default}
                                options={roleList}
                                onChange={(e) => this.onRoleChange(e)}
                            />
                        </Row>
                        { (addPartyModel.role_label && addPartyModel.role_label != 'Claimant' && addPartyModel.role_label != 'Respondent') && <Row className="mb16">
                            {/* <Col span={24} className="mb4">
                                <label>Representative of</label>
                            </Col>
                            <Col span={24}> */}
                            <label className="form-label">Representative of</label>
                            <Select placeholder="Select User" value={addPartyModel.link_email?addPartyModel.link_email:undefined} options={repList} onChange={(e) => this.onRepOf(e)}
                            />
                            {/* </Col> */}
                        </Row>}

                        <Divider />

                        { addPartyModel.type == 1 && (
                            <Row className="mb16">
                                {/* <Col span={24} className="mb4">
                                    <label>First name</label>
                                </Col>
                                <Col span={24}> */}
                                <label className="form-label">Organization Name</label>
                                <Input placeholder="Enter organization name" value={addPartyModel.org_name} name="org_name" maxLength={64} onChange={(e) => this.onOrgName(e)} />
                                {/* </Col> */}
                                <Col span={24} className="mt16">
                                    <label>Representative info</label>
                                </Col>
                            </Row>
                        )}

                        <Row className="mb16">
                            {/* <Col span={24} className="mb4">
                                <label>First name</label>
                            </Col>
                            <Col span={24}> */}
                            <label className="form-label">First name</label>
                            <Input placeholder="Enter first name" name="first_name" value={addPartyModel.first_name} maxLength={32} onChange={(e) => this.onChangeFirstName(e)}/>
                            {/* </Col> */}
                        </Row>
                        <Row className="mb16">
                            {/* <Col span={24} className="mb4">
                                <label>Last name</label>
                            </Col>
                            <Col span={24}> */}
                            <label className="form-label">Last name</label>
                            <Input placeholder="Enter last name" name="last_name" value={addPartyModel.last_name} maxLength={32} onChange={(e) => this.onChangeLastName(e)}/>
                            {/* </Col> */}
                        </Row>
                        <Row className="mb16">
                            {/* <Col span={24} className="mb4">
                                <label>Email Address</label>
                            </Col>
                            <Col span={24}> */}
                            <label className="form-label">Email Address</label>
                            <Input placeholder="Enter email address" name="email" disabled={addPartyModel.defaultEmail} value={addPartyModel.email} maxLength={100} onChange={(e) => this.onChangeEmail(e)}/>
                            {/* </Col> */}
                        </Row>
                        <Row className="mb16">
                            {/* <Col span={24} className="mb4">
                                <label>Mobile number </label><span>(optional)</span>
                            </Col>
                            <Col span={24}> */}
                            <label className="form-label">Mobile number<span className="label-optional"> &nbsp;(optional)</span></label>
                            <PhoneInput
                                className="react-tel-input w100"
                                name="phone"
                                country={"us"}
                                preferredCountries={['us']}
                                enableSearch
                                placeholder="+1 201-555-0123"
                                value={addPartyModel.phone}
                                onChange={(e) => this.onChangePhone(e)}
                                style={{ width: "-webkit-fill-available" }}
                                isValid={(inputNumber, country, countries) => {
                                    return countries.some((country) => {
                                        return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                                    });
                                }}
                            />
                            {/* </Col> */}
                        </Row>

                        { addPartyModel.type == 1 && (<>
                            <Divider />
                            <Row className="mb16">
                                <label className="form-label">Organization Address<span className="label-optional"> &nbsp;(optional)</span></label>
                                <Input className="mb8" placeholder="Enter address" name="address1" maxLength={100} value={addPartyModel.address1} onChange={(e) => this.onChangeAddress1(e)}/>
                                <Input placeholder="Enter City/ State/ Country/ Zip code" name="address2" maxLength={100} value={addPartyModel.address2} onChange={(e) => this.onChangeAddress2(e)}/>
                            </Row></>
                        )}
                    </Row>
                    <Row className="add-participant-footer" gutter={32} style={{ margin: 0, bottom: participantVisible?0:'-100px' }} justify="space-between">
                        <Col span={12}>
                            <Button
                                type="link"
                                size="large"
                                className="w100"
                                onClick={ this.onCloseParticipant }
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                type="primary"
                                size="large"
                                className="w100"
                                htmlType="submit"
                                onClick={this.addCaseParty}
                                loading={partyAddLoading}
                                disabled={addClaimant || addRespondant || partyAddLoading}
                            >
                                Add
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Drawer>

            <Drawer
                placement="bottom"
                closable={false}
                title={'Rank Mediator based on your preference'}
                className="rank-mediator-drawer"
                visible={rankMediatorVisible}
                extra={<CloseIcon onClick={this.onCloseRankMediator}/>}
            >
                <Row className="rank-info">
                    <p>Use drag & drop OR arrow to rank Mediator</p>
                </Row>
                <Row className="rank-preference-container">
                    <Card className="rank-preference mb8 ">
                        <Row className="rank-preference-detail">
                            <Col span={2} className="sno">
                                {1}
                            </Col>
                            <Col span={22} className="icon-container">
                                <Row className="icon">
                                    <Col span={16}></Col>
                                    <Col span={4}><ArrowTopIcon /></Col>
                                    <Col span={4}><ArrowDownIcon /></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="rank-created-detail">
                            <Col span={24} className="created-details">
                                <Row>
                                    <Col span={4} className="avatar"><Avatar>JR</Avatar></Col>
                                    <Col span={16}>
                                        <Row>
                                            <Col className="name">Jane Raymond</Col>
                                        </Row>
                                        <Row>
                                            <Col className="role">Mediator, Lawyer</Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="pt10">
                                    <Col>
                                        <span className="bio">Results-driven corporate lawyer with 18+ years of experience ensuring the legality of commercial transactions. Adept at drafting <a className="bio-readmore" style={{color:"#136796"}}>{ '...More' }</a></span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>

                    <Card className="rank-preference mb8 ">
                        <Row className="rank-preference-detail">
                            <Col span={2} className="sno">
                                {2}
                            </Col>
                            <Col span={22} className="icon-container">
                                <Row className="icon">
                                    <Col span={16}></Col>
                                    <Col span={4}><ArrowTopIcon /></Col>
                                    <Col span={4}><ArrowDownIcon /></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="rank-created-detail">
                            <Col span={24} className="created-details">
                                <Row>
                                    <Col span={4} className="avatar"><Avatar>JR</Avatar></Col>
                                    <Col span={16}>
                                        <Row>
                                            <Col className="name">Jane Raymond</Col>
                                        </Row>
                                        <Row>
                                            <Col className="role">Mediator, Lawyer</Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="pt10">
                                    <Col>
                                        <span className="bio">Results-driven corporate lawyer with 18+ years of experience ensuring the legality of commercial transactions. Adept at drafting <a className="bio-readmore" style={{color:"#136796"}}>{ '...More' }</a></span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>

                    <Card className="rank-preference mb8 ">
                        <Row className="rank-preference-detail">
                            <Col span={2} className="sno">
                                {3}
                            </Col>
                            <Col span={22} className="icon-container">
                                <Row className="icon">
                                    <Col span={16}></Col>
                                    <Col span={4}><ArrowTopIcon /></Col>
                                    <Col span={4}><ArrowDownIcon /></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="rank-created-detail">
                            <Col span={24} className="created-details">
                                <Row>
                                    <Col span={4} className="avatar"><Avatar>JR</Avatar></Col>
                                    <Col span={16}>
                                        <Row>
                                            <Col className="name">Jane Raymond</Col>
                                        </Row>
                                        <Row>
                                            <Col className="role">Mediator, Lawyer</Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="pt10">
                                    <Col>
                                        <span className="bio">Results-driven corporate lawyer with 18+ years of experience ensuring the legality of commercial transactions. Adept at drafting <a className="bio-readmore" style={{color:"#136796"}}>{ '...More' }</a></span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Row>
                <Row className="rank-mediator-footer" gutter={32} style={{ margin: 0 }} justify="space-between">
                    <Col span={12}>
                        <Button
                            type="default"
                            size="large"
                            className="w100"
                            onClick={ this.onCloseRankMediator }
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            type="primary"
                            size="large"
                            className="w100"
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Drawer>

            <Drawer
                placement="bottom"
                title={'Payment'}
                closable={false}
                className="payment-drawer"
                contentWrapperStyle={{ bottom: 0}}
                extra={<CloseIcon onClick={this.onClosePayment}/>}
                visible={paymentVisible}
            >
                <Spin spinning={paymentLoading}>
                    <Row className="payment-detail">
                        <Row className="mb24">
                            <Col>
                                <span className="registration-fee">Registration Fee</span> {/* <InfoIcon /> */}
                            </Col>
                        </Row>
                        <Row className="charges-detail">
                            <Row className="mb24">
                                <Col span={14}>
                                    <span className="charge-label">Base fee</span>
                                </Col>
                                <Col span={10} className="charges">
                                    {cSymbol}{feeInfo?.base_fee}
                                </Col>
                            </Row>
                            {   feeInfo && feeInfo.charges &&
                                (
                                    <Row className="mb24">
                                        <Col span={14}>
                                            <span className="charge-label">Other Charges</span>
                                        </Col>
                                        <Col span={10} className="charges">
                                            {cSymbol}{feeInfo?.charges}
                                        </Col>
                                    </Row>
                                )
                            }
                            {   feeInfo && feeInfo.tax &&
                                (
                                    <Row className="mb24">
                                        <Col span={14}>
                                            <span className="charge-label">Tax</span>
                                        </Col>
                                        <Col span={10} className="charges">
                                            {cSymbol}{feeInfo?.tax}
                                        </Col>
                                    </Row>
                                )
                            }

                            <Divider />

                            <Row className="mb24">
                                <Col span={14}>
                                    <span className="charge-label">Total</span>
                                </Col>
                                <Col span={10} className="charges">
                                    {cSymbol}{feeInfo?.total_fee}
                                </Col>
                            </Row>
                        </Row>
                        
                        <Row className="mb24">
                            <Col>
                                <span className="choose-option">Choose your convenient option</span>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                {   paymentGatewayList && paymentGatewayList.length > 0 &&
                                    (
                                        <Radio.Group onChange={this.onPaymentChange}>
                                            {   paymentGatewayList.map((gateway, index) => (
                                                    <Radio value={gateway.p_g_id}>{gateway.name.charAt(0).toUpperCase() + gateway.name.slice(1)}</Radio>
                                                ))
                                            }
                                        </Radio.Group>
                                    )
                                }
                            </Col>
                        </Row>

                        <Row>
                            <Col span={20}>
                                <p className="accept-terms">I acknowledge that I have read and understood the <a className="term-condition" onClick={() => this.setState({ isVisibleTerms: true })}>terms and conditions</a>.  To the best of my knowledge, all of the information included here is correct.</p>
                            </Col>
                            <Col span={4} className="terms-check">
                                <Checkbox onChange={this.onChange}></Checkbox>
                            </Col>
                        </Row>
                    </Row>

                    {   paymentVisible &&
                        (
                            <Row className="payment-footer" gutter={32} style={{ margin: 0 }} justify="space-between">
                                <Col span={12}>
                                    <Button
                                        type="default"
                                        size="large"
                                        className="w100"
                                        onClick={ this.onClosePayment }
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button
                                        type="primary"
                                        size="large"
                                        className="w100"
                                        disabled={makePaymentDisable}
                                        onClick={ this.makePayment }
                                    >
                                        Make Payment
                                    </Button>
                                </Col>
                            </Row>
                        )
                    }
                </Spin>
            </Drawer>
            <Drawer
                title="Terms and Conditions"
                className="custom-drawer-popup"
                placement="bottom"
                height={"100%"}
                visible={isVisibleTerms}
                onClose={() => this.setState({ isVisibleTerms: false })}
            >
                <Card className="terms-condition-content"><div dangerouslySetInnerHTML={{ __html: this.settings?.terms }}></div></Card>
            </Drawer>

            <Drawer
                placement="bottom"
                title={'Case Registration Payments'}
                closable={false}
                className="payment-status-drawer"
                contentWrapperStyle={{ bottom: 0}}
                visible={paymentStatusVisible}
            >
                <Row className="payment-status-detail">
                    <Row>
                        <Col span={24}>
                            <p className="payment-status">Payment Success</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <p className="charges">{cSymbol}{feeInfo?.total_fee}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <a className="download-receipt" href={APIURL + "payment/downloadBurtonReceipt?case_id=" + this.caseId + "&requested_by=" + this.user.id + "&token=" + this.user.token} download>Download Receipt</a>
                        </Col>
                    </Row>
                </Row>

                {   paymentStatusVisible &&
                    (
                        <Row className="payment-status-footer" gutter={32} style={{ margin: 0 }} justify="space-between">
                            <Col span={24}>
                                <Button
                                    type="primary"
                                    size="large"
                                    className="w100"
                                    onClick={this.onClosePaymentStatus}
                                >
                                    Done
                                </Button>
                            </Col>
                        </Row>
                    )
                }
            </Drawer>
        </div>)
    }
}
export default CreateCaseView;