import React from "react";
import { Row, Col, Card, Button, Skeleton } from 'antd';
import { useHistory, Link } from "react-router-dom";
import rdologo from '../../assets/rdo-logo.png';
import { IntroIcon } from "../../logics/images";
import { useSelector } from "react-redux";

function Intro() {
    const settings = useSelector(state => state.settings);
    const history = useHistory();
    const routeChange = () =>{ 
        let path = `/auth/login`; 
        history.push(path);
    }
    return (
        <Row className="max-480 p24 auth-container">
            <Col className="textCenter mb24 mt40" span={24}>
                { settings && <img className="portal-logo" src={settings?.logo} alt="logo" /> }
                { !settings && <Skeleton style={{width: 100, margin: '0 auto'}} active paragraph={false} /> }
            </Col>
            <Col className="mb24" span={24}>
                <Card className="introCard">
                    <div className="textCenter mb24">
                        <IntroIcon />
                    </div>
                    <p className="mb24">You are trying to access the Alaska Court System’s Online Dispute Resolution Platform (AK ODR) from a mobile device.  At this time, You must use a desktop computer for AK ODR.</p>
                    {/* <Link to={{ pathname:`https://resolvedisputes.online` }} target={'_blank'}>Learn More...</Link> */}
                </Card>
            </Col>
            <Col className="mb24" span={24}>
                <Button onClick={routeChange} className="w100" type="primary">Continue</Button>                
            </Col>
            <Col span={24} className="powered-by">
                <p>Powered by <img src={rdologo} width="33" /></p>
            </Col>
        </Row>
    );
}
export default Intro;