import React, { useState, useEffect, useRef } from "react";
import userService from "../../../logics/services/user.service";
import apiService from "../../../logics/services/api.service";
import { Row, Col, Steps, Collapse, Menu, Dropdown, Form, InputNumber, Input, Button, Checkbox, Card, Popconfirm, Upload, Progress, Avatar, Drawer, Space, Select, message, Spin, Switch } from 'antd';
import Icon, { FileImageOutlined, FileWordOutlined, FilePptOutlined, FileTextOutlined } from '@ant-design/icons';
import { websocketService, socket } from "../../../logics/services/websocket.service";
import store from '../../../logics/store/case.store';
import { APIURL } from '../../../constants';
import { encrypt } from '../../../logics/helpers';
import PDFView from "./PdfView";
// import CkEditor from '../../../components/CkEditor';
import CaseStatusIcon from "./CaseStatusIcon";
import ReactQuill from 'react-quill';

import StarSvg from  '../../../assets/svg-icons/star.svg';
import StarFillSvg from  '../../../assets/svg-icons/star_fill.svg';
import { TextArea } from "semantic-ui-react";
import DeleteSvg from '../../../assets/svg-icons/delete_white.svg';
import PdfSvg from '../../../assets/svg-icons/pdf.svg';
import ArrowDownSvg from '../../../assets/svg-icons/arrow_drop_down.svg';
import DownloadSvg from  '../../../assets/svg-icons/download.svg';
import InfoSvg from  '../../../assets/svg-icons/info.svg';
import { EyeIcon, EyeInvisibleIcon } from "../../../logics/images";

const StarIcon = props => <Icon component={StarSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const StarFillIcon = props => <Icon component={StarFillSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const DeleteIcon = props => <Icon component={DeleteSvg} style={{ fontSize: 24, verticalAlign: 'middle' }} {...props} />;
const PdfIcon = props => <Icon component={PdfSvg} style={{ fontSize: 24, verticalAlign: 'middle' }} {...props} />;
const ArrowDownIcon = props => <Icon component={ArrowDownSvg} style={{ fontSize: 24, verticalAlign: 'middle', float: 'right' }} {...props} />;
const DownloadIcon = props => <Icon component={DownloadSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginRight: '8px'}} {...props} />;
const InfoIcon = props => <Icon component={InfoSvg} style={{ fontSize: 24, verticalAlign: 'middle' }} {...props} />;

const { Option } = Select;
const patterns = {
    isImageFilePattern: /\.(jpe?g|gif|bmp|png|svg)$/i,
    isImageNSFilePattern: /\.(tiff)$/i,
    isVideoFilePattern: /\.(mp4|webm|3gp|ogg)$/i,
    isVideoNSFilePattern: /\.(flv|wmv|avi)$/i,
    isAudioFilePattern: /\.(mp3)$/i,
    isPdfFilePattern: /\.(pdf)$/i,
    isDocFilePattern: /\.(doc|docx)$/i,
    isExcelFilePattern: /\.(xls|xlsx|csv)$/i,
    isTxtFilePattern: /\.(txt)$/i,
    isPptFilePattern: /\.(ppt|pptx)$/i,
    isExtractableFilePattern: /\.(gz|tar|rar|g?zip)$/i,
    isViewable: /\.(jpe?g|gif|bmp|png|svg|mp3|mp4|webm|3gp|ogg|pdf|txt)$/i,
    isUploadable: /\.(jpe?g|gif|bmp|png|svg|tiff?|mp3|mp4|3gp|flv|wmv|mkv|avi|webm|ogg|pdf|doc|docx|csv|xls|xlsx|ppt|pptx|txt)$/i
};

class CreateOutcome extends React.Component {
    constructor(props) {
        super(props);
        this.siteSetting=store.getState().settings;
        this.caseId=this.props.caseId;
        this.visible=this.props.visible;
        this.user=this.props.user;
        this.stepsCompleted = [];
        this.selectedProposal = [];
        this.uploadList = [];
        this.state = {
            caseInfo: [],
            loading: false,
            createOutcomeVisible: this.visible,
            confirm: false,
            buttonDisabled: true,
            current: 0,
            description: '',
            proposalAmount: '',
            assignTo: [],
            fvalid: false,
            editProposal: this.props.editProposal,
            addProposal: this.props.addProposal,
            proposalId: this.props.proposalId,
            signedPassword: '',
            submitTerms: false,
            amendTerms: false,
            amdWdlTerms: false,
            proposerSign: false,
            previewFileName: '',
            previewFileId: 0,
            previewFileDrawer: false,
            previewPdfFile: false,
            previewImgFile: false,
            noPreviewFile: false,
            isDownload: false,
            uploadFileData: '',
            permissionDrawerVisible: false,
            isSumbitProposal: false,
            uploadList: [],
            selectedProposal: [],
            caseParties: [],
            judgment_required: false,
            judgment_required_split_amount: false,
            amount_to_pay: '',
            judgment_filing_location: '',
            judgment_case_type: '',
            judgment_filing_type: '',
            filingLocations: [],
            existing_judgment: false,
            checkingJudgment: false,
            fee_to_be_paid_by: 0,
            isJudgmentInfo: false
        };
    }

    componentDidMount = async () => {
        let response;
        const caseId = this.caseId;
        this.user = userService.getUser();
        // apiService.getSingleCase({
        //     case_id: caseId,
        // }).then((res) => {
        //     if (res.status === "success") {
                this.setState({caseInfo: store.getState().case});
        //     }
        // });
        let element = document.getElementsByClassName('bottom-fixed-menu')[0];
        element.style.display = 'none';

        if (!this.state.editProposal && this.state.addProposal) {
            this.fvalid();
            this.setState({selectedProposal: [], description: '', proposalAmount: ''});
            this.setState({loading: true});
            this.getParties();
        }        
        
        if (this.state.editProposal && !this.state.addProposal) {
            this.setState({loading: true});
            this.viewProposal();
            this.getParties();
        }
        setTimeout(() => {
            // document.getElementById("proposal-steps").classList.remove("ant-steps-vertical");
        });
        if (this.siteSetting.judgment_required) {
            apiService.getFilingLocations()
                .then((res) => {
                    this.setState({ filingLocations: res.data});
                });
        }
    };

    getParties() {
        apiService.getParties({
            case_id: this.caseId,
            cs_id: this.state.proposalId
        }).then(res => {
            if (res.status === 'success') { 
                this.setState({loading: false, assignTo: [], caseParties: res.data});
                res.data.forEach(e => {
                    if (this.state.editProposal) {
                        this.setState({assignTo: this.state.assignTo.concat({
                            'party_id': e.u_id,
                            'permission': e.permissions,      
                            'cs_st_id': e.cs_st_id ? e.cs_st_id : null,
                            'role_label':e.role_label,
                            'name': e.name
                        })});
                    } else {
                        let permission = this.user.id == e.u_id ? e.permissionLists[0] : e.permissions;                        
                        // if (e.role_label == "Case Officer") {
                        //     if (e.permissionLists.includes('Can View')) {
                        //         permission = 'Can View';
                        //     }
                        // }
                        e.permissions = permission;
                        this.setState({assignTo: this.state.assignTo.concat({
                            'party_id': e.u_id,
                            'permission': permission,      
                            'cs_st_id': e.cs_st_id ? e.cs_st_id : null,
                            'role_label':e.role_label,
                            'name': e.name
                        })});
                    }
                });
                let mustComplete = [];
                this.state.assignTo.filter(e => e.role_label == 'Claimant' || e.role_label == 'Respondent')
                    .forEach(e => {                
                        if (e.permission == 'Accept & Sign' || e.permission == 'Propose & Sign') {
                            mustComplete.push(e.party_id);
                        }
                    });
                this.setState({ checkingJudgment: true});
                apiService.checkJudgmentTracking({
                        assignTo: mustComplete,
                        case_id: this.caseId
                    }).then((res) => {
                        this.setState({ checkingJudgment: false});
                        if (res.status == 'success' && res.data) {
                            this.setState(
                                { 
                                    existing_judgment: true,
                                    judgment_filing_location: Number(res.data.judgment_filing_location),
                                    judgment_filing_type: res.data.judgment_filing_type,
                                    judgment_case_type: res.data.judgment_case_type,
                                    fee_to_be_paid_by: res.data.fee_to_be_paid_by,
                                    amount_to_pay: undefined
                                })
                        } else {
                            this.setState({existing_judgment: false});
                        }
                    });
            }
        });
    }

    viewProposal() {
        this.setState({loading: true});
		apiService.viewProposal({
            cs_id: this.state.proposalId,
            case_id: this.caseId
        }).then(res => {
            this.setState({loading: false});
            if (res.status == 'success') {
                this.selectedProposal = res.data.proposed_info;
                this.setState({selectedProposal: this.selectedProposal, description: this.selectedProposal.description, proposalAmount: this.selectedProposal.proposal_figure});
                this.setState({proposalAmount: this.selectedProposal.proposal_figure});
                if (this.state.current == 0 && (this.selectedProposal.description == '' || this.selectedProposal.description == null || ((this.selectedProposal.description || '').trim().length === 0))) {
                    this.setState({fvalid: true});
                }
                this.setState(
                    {
                        judgment_required: this.selectedProposal.judgment_required?true:false,
                        amount_to_pay: this.selectedProposal.judgment_amount,
                        judgment_required_split_amount: this.selectedProposal.judgment_split_amount?true:false,
                        judgment_filing_location: Number(this.selectedProposal.judgment_filing_location),
                        fee_to_be_paid_by: Number(this.selectedProposal.fee_to_be_paid_by),
                        judgment_filing_type: this.selectedProposal.judgment_filing_type,
                        judgment_case_type: this.selectedProposal.judgment_case_type
                    });
            }
        })
	}

    onClose = () => {
        this.setState({taskViewVisible: false, assignTo: [], caseParties: [], uploadList: []});
        this.props.closeCreateOutcomeVisible(false);
        let element = document.getElementsByClassName('bottom-fixed-menu')[0];
        element.style.display = 'flex';
    }

    next() {
        this.setState({current: 1});
        this.stepsCompleted[0] = true;
    }

    preview() {
        let assigneeCheck = [];
        this.state.assignTo.forEach(e => {
            if(e.permission == 'Accept & Sign' && (e.role_label == 'Claimant' || e.role_label == 'Respondent')) {
                assigneeCheck = assigneeCheck.concat(e);
            }                    
        });
        if(this.user.role_label == "Respondent" || this.user.role_label == "Claimant") {
            if(assigneeCheck.length == 0) {
                message.error('Please select the Accept & Sign option for the opposite party.');
                return;
            }
        } else {
            if(assigneeCheck.length < 2) {
                message.error('Please select the Accept & Sign option for the opposite party.');
                return;
            }
        }
        if (this.state.isJudgmentInfo) {
            if (this.state.judgment_required && (!(this.state.caseInfo?.case_addtl_ref_no && this.state.caseInfo?.case_addtl_ref_no != 'null') && (!this.state.judgment_filing_location || !this.state.judgment_case_type))) {
                if (!(this.state.caseInfo?.case_addtl_ref_no && this.state.caseInfo?.case_addtl_ref_no != 'null')) {
                    if (!this.state.judgment_filing_location) {
                        message.error('Please select the Filing location');
                        return;
                    }
                }
                if (!this.state.fee_to_be_paid_by) {
                    message.error('Please select the Fee To Be Paid by');
                    return;
                }
            }
            this.setState({ isJudgmentInfo: false });
        }
        this.setState({current: 2});
        this.stepsCompleted[1] = true;
    }

    previous(step) {
        this.setState({current: step});
        this.stepsCompleted[step] = false;
    }

    handleProposalDescription = (value, delta, source, editor) =>  {
        this.setState({description: value});
        if (editor.getText() != '' && editor.getText() != null && editor.getText().trim().length > 0) {
            this.setState({fvalid: false});
        } else {
            this.setState({fvalid: true});
        }
    }

    handleProposalAmount = (e) =>  {
        this.setState({proposalAmount: e});
    }

    fvalid() {
        if(this.state.current == 0 && (this.state.description == '' || this.state.description == null || ((this.state.description || '').trim().length === 0))) {
            this.setState({fvalid: true});
        }
    }

    beforeUpload = (item) => {

        let isFile= { 
            isUploadable: /\.(jpe?g|png|pdf|doc|docx|ppt|pptx|txt)$/i
        };

        const isvalid = isFile.isUploadable.test(item.name);
        if (!isvalid) {
            message.error('You can only upload images and documents');
            return Upload.LIST_IGNORE;
        }

        const isLt15M = item.size / 1024 /1024 < 300;
        if (!isLt15M) {
            message.error('You can only upload file size less then 300mb');
            return Upload.LIST_IGNORE;
        }

        this.uploadIndex = this.uploadList.length;
        if ((this.uploadIndex + 1) > 10) {
            let count = (this.uploadIndex + 1) == 11 ? true : false;
            if(count) {
                message.error('Each proposal can have a maximum of 10 attachments.');
            }    
            return Upload.LIST_IGNORE;
        }
        item.icon = this.getFileIcon(item.type);

        let isDuplicate = false;
        if(this.uploadList.length != 0) {
            this.uploadList.find(e => {
                if(e.item.name == item.name) {
                    isDuplicate = true;                        
                } 
            });
        }

        if(this.state.editProposal && this.selectedProposal && this.selectedProposal.filesInfo) {
            this.selectedProposal.filesInfo.find(e => {
                if(e.orginal_name == item.name) {
                    isDuplicate = true;
                }
            });
        }

        if(isDuplicate) {
            message.error('Already there is a file in this location with the same name.');
            return Upload.LIST_IGNORE;                
        } else {
            this.setState({uploadList: this.state.uploadList.concat({item})});
            this.uploadList = this.uploadList.concat({item});
        }
    }

    getFileIcon(type) {
        let svgIcon = '';
        if(type == 'application/pdf') {
            svgIcon = <PdfIcon />;
        } else if(type == 'image/jpeg' || type == 'image/png') {
            svgIcon = <FileImageOutlined style={{fontSize: '22px', verticalAlign: 'middle'}} />;
        } else if(type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || type == 'application/msword') {
            svgIcon = <FileWordOutlined style={{fontSize: '22px', verticalAlign: 'middle'}} />;
        } else if(type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || type == 'application/vnd.ms-powerpoint') {
            svgIcon = <FilePptOutlined style={{fontSize: '22px', verticalAlign: 'middle'}} />;
        } else if(type == 'text/plain') {
            svgIcon = <FileTextOutlined style={{fontSize: '22px', verticalAlign: 'middle'}} />;
        }

        return svgIcon;
    }

    dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }

    removeFile(index, name, fileId, fileChk) {
        this.selectedFileId = fileId;
        this.selectedFileIndex = index;
        this.selectedFile = name;
        this.existFileCheck = fileChk;  
        this.showDeleteModal = true;

        if(fileChk && fileId != 0) {
            apiService.deleteProposFile({
				f_id: fileId,
				case_id: this.caseId,
                cs_id: this.state.proposalId
			}).then(res => { 
                if(res.status = 'success') {
                    this.selectedProposal.filesInfo.splice(index, 1);
                    this.setState({selectedProposal: this.selectedProposal});
                }
            });            
        } else {
            this.uploadList.splice(index, 1);
            this.setState({uploadList: this.uploadList});
        }   
    }

    getPermission(partyId) {
		let assignTo = this.state.assignTo.filter(e => e.party_id == partyId);	
		return assignTo && assignTo[0] && assignTo[0].permission ? assignTo[0].permission : '';	
	}

    handleChangePermission = (permission, partyId) =>  {
        if(permission == 'Propose & Sign') {
            this.setState({submitTerms: false, amendTerms: false});
        } else if(permission == 'Propose') {
            this.setState({amdWdlTerms: false, proposerSign: false, signedPassword: ''});
        }

        let assignTo = this.state.assignTo;
		assignTo.filter(e => { 
			if(e.party_id == partyId) {
				e.permission = permission;
			}
		});
        this.setState({assignTo: assignTo});
		
        let caseParties = this.state.caseParties;
		caseParties.forEach(e => {
			if(e.u_id == partyId) {
				e.permissions = permission;
			}
		});
        this.setState({caseParties: caseParties});

        let mustComplete = [];
        this.state.assignTo.filter(e => e.role_label == 'Claimant' || e.role_label == 'Respondent')
            .forEach(e => {                
                if (e.permission == 'Accept & Sign' || e.permission == 'Propose & Sign') {
                    mustComplete.push(e.party_id);
                }
            });
        this.setState({ checkingJudgment: true});
        apiService.checkJudgmentTracking({
                assignTo: mustComplete,
                case_id: this.caseId
            }).then((res) => {
                this.setState({ checkingJudgment: false});
                if (res.status == 'success' && res.data) {
                    this.setState(
                        { 
                            existing_judgment: true,
                            judgment_filing_location: Number(res.data.judgment_filing_location),
                            judgment_filing_type: res.data.judgment_filing_type,
                            judgment_case_type: res.data.judgment_case_type,
                            fee_to_be_paid_by: res.data.fee_to_be_paid_by,
                            amount_to_pay: undefined
                        })
                } else {
                    let amount = undefined;
                    if (!(this.state.caseInfo?.case_addtl_ref_no && this.state.caseInfo?.case_addtl_ref_no != 'null')) {
                        amount = this.siteSetting?.judgment_required_amount; 
                    }
                    this.setState({ 
                        existing_judgment: false,
                        judgment_filing_location: undefined,
                        judgment_filing_type: undefined,
                        judgment_case_type: undefined,
                        fee_to_be_paid_by: undefined,
                        amount_to_pay: amount
                    });
                }
            });
    }

    showPreview(f) {
        this.isImage = patterns.isImageFilePattern.test(f.item.name);
        this.isPdf = patterns.isPdfFilePattern.test(f.item.name);
        this.isDoc = patterns.isDocFilePattern.test(f.item.name);
        this.isTxt = patterns.isTxtFilePattern.test(f.item.name);
        this.isExcel = patterns.isExcelFilePattern.test(f.item.name);
        this.isPpt = patterns.isPptFilePattern.test(f.item.name);
        this.isNSImage = patterns.isImageNSFilePattern.test(f.item.name);
        this.isViewable = patterns.isViewable.test(f.item.name);
        if(this.isPdf) {
            this.setState({previewFileDrawer: true, previewPdfFile: true, isDownload: false, previewFileName: f.item.name, uploadFileData: URL.createObjectURL(f.item)});
        } else if (this.isImage) {
            this.setState({previewFileDrawer: true, previewImgFile: true, isDownload: false, previewFileName: f.item.name, uploadFileData: URL.createObjectURL(f.item)});
        } else {
            this.setState({previewFileDrawer: true, noPreviewFile: true, isDownload: false, previewFileName: f.item.name, uploadFileData: URL.createObjectURL(f.item)});
        }
        
    }

    existingShowPreview(f) {
        apiService.getFileData({
            f_id: f.f_id
        }).then((res) => {
            if (res.status == 'success') {
                this.selectedFile = res.data;	
                this.isImage = patterns.isImageFilePattern.test(this.selectedFile.file.file_name);
                this.isPdf = patterns.isPdfFilePattern.test(this.selectedFile.file.file_name);
                this.isDoc = patterns.isDocFilePattern.test(this.selectedFile.file.file_name);
                this.isTxt = patterns.isTxtFilePattern.test(this.selectedFile.file.file_name);
                this.isExcel = patterns.isExcelFilePattern.test(this.selectedFile.file.file_name);
                this.isPpt = patterns.isPptFilePattern.test(this.selectedFile.file.file_name);
                this.isNSImage = patterns.isImageNSFilePattern.test(this.selectedFile.file.file_name);
                this.isViewable = patterns.isViewable.test(this.selectedFile.file.file_name);
                if(this.isPdf) {
                    this.setState({previewFileDrawer: true, previewPdfFile: true, isDownload: false, previewFileName: f.orginal_name, uploadFileData: res.data.url});
                } else if (this.isImage) {
                    this.setState({previewFileDrawer: true, previewImgFile: true, isDownload: false, previewFileName: f.orginal_name, uploadFileData: res.data.url});
                } else {
                    this.setState({previewFileDrawer: true, noPreviewFile: true, isDownload: false, previewFileName: f.orginal_name, uploadFileData: res.data.url});
                }
            }
        });
    }

    onShowPreviewFileDrawer() {
        this.setState({previewFileDrawer: false, previewPdfFile: false, previewImgFile: false, noPreviewFile: false, previewFileName: '', uploadFileData: ''});
    }

    handleSubmitTerms = (submitTerms) =>  {
        this.setState({submitTerms: !submitTerms});
    }

    handleAmendTerms = (amendTerms) =>  {
        this.setState({amendTerms: !amendTerms});
    }

    handleProposerSign = (proposerSign) =>  {
        this.setState({proposerSign: !proposerSign});
    }
    
    handleAmdWdlTerms = (amdWdlTerms) =>  {
        this.setState({amdWdlTerms: !amdWdlTerms});
    }

    signPasswordChange = (e) =>  {
        this.setState({signedPassword: e.target.value});
    }

    createProposal(submit_type) {
        this.setState({signedPassword: '', submitTerms: false, amendTerms: false}); 
        if (submit_type == 'draft') { 
            this.setState({isSumbitProposal: false}); 
            this.sumbitProposal(submit_type);
        } else {
            this.setState({isSumbitProposal: true});
            setTimeout(() => {
                var container = document.getElementById('create-outcome-sign');
                if(container) container.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }, 500);
        }
    }

    sumbitProposal(submit_type) {
        this.setState({loading: true});
        this.formData = new FormData();
        let assignTo = [];	
        let parties = [];
		this.state.assignTo.forEach(e => {
			if(e.permission != '' && e.permission != null && e.permission != 'No Access') {
				assignTo.push(e);
                parties.push(e.party_id);
			}
		});	
        this.uploadList.forEach(e => {
            this.formData.append('files[]', e.item)
        });
        this.formData.append('case_id', this.caseId.toString());
        this.formData.append('description', this.state.description);
        this.formData.append('proposal_amount', this.state.proposalAmount);
        this.formData.append('assign_to', JSON.stringify(assignTo));
        this.formData.append('proposer_sign', this.state.proposerSign.toString());
        this.formData.append('submit_type', submit_type);
        this.formData.append('judgment_required', this.state.judgment_required);
        this.formData.append('judgment_amount', this.state.amount_to_pay?this.state.amount_to_pay:null);
        this.formData.append('judgment_split_amount', this.state.judgment_required_split_amount);
        this.formData.append('judgment_filing_location', this.state.judgment_filing_location?this.state.judgment_filing_location:null);
        this.formData.append('fee_to_be_paid_by', this.state.fee_to_be_paid_by?this.state.fee_to_be_paid_by:null);
        this.formData.append('judgment_case_type', this.state.judgment_case_type?this.state.judgment_case_type:null);
        this.formData.append('judgment_filing_type', this.state.judgment_filing_type?this.state.judgment_filing_type:null);
        if(this.state.proposerSign) {
            this.formData.append('password', encrypt(this.state.signedPassword));
        }
                         
        apiService.createProposal(this.formData)
        .then(res => {
            this.setState({loading: false});
            if(res.status == 'success') {
                message.success(res.message);
                this.closeproposal();
                this.setState({description: '', proposalAmount: '', assignTo: [], isSumbitProposal: false});
                if(submit_type != 'draft') {
                    websocketService.send({
                        method: 'proposal',
                        isDisplay: true,
                        data: {
                            users: parties,
                            content: res.instant_msg,
                            type: 'request',
                            time_display: false
                        }
                    });
                }
            } else if(res.status == 'error') {
                message.error(res.message);
            }
        });
    }

    validateUpdateProposal(submit_type) {
        if (submit_type == 'update') {
            this.setState({isSumbitProposal: false});
            this.updateProposal(submit_type);
        } else {
            this.setState({isSumbitProposal: true});
            setTimeout(() => {
                var container = document.getElementById('create-outcome-sign');
                if(container) container.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }, 500);
        }
    }

    updateProposal(submit_type) {
        this.setState({loading: true});
        this.formData = new FormData();
        let assignTo = [];    
        let parties = [];	
		this.state.assignTo.forEach(e => {
			if(e.permission != '' && e.permission != null && e.permission != 'No Access') {
				assignTo.push(e);
                parties.push(e.party_id);
			}
		});	
        this.state.uploadList.forEach(e => {
            this.formData.append('files[]', e.item)
        });
        this.formData.append('case_id', this.caseId.toString());        
        this.formData.append('cs_id', this.state.proposalId.toString());
        this.formData.append('description', this.state.description);
        this.formData.append('proposal_amount', this.state.proposalAmount);
        this.formData.append('assign_to', JSON.stringify(assignTo));
        this.formData.append('proposer_sign', this.state.proposerSign.toString());
        this.formData.append('judgment_required', this.state.judgment_required);
        this.formData.append('judgment_amount', this.state.amount_to_pay?this.state.amount_to_pay:null);
        this.formData.append('judgment_split_amount', this.state.judgment_required_split_amount);
        this.formData.append('judgment_filing_location', this.state.judgment_filing_location?this.state.judgment_filing_location:null);
        this.formData.append('fee_to_be_paid_by', this.state.fee_to_be_paid_by?this.state.fee_to_be_paid_by:null);
        this.formData.append('judgment_case_type', this.state.judgment_case_type?this.state.judgment_case_type:null);
        this.formData.append('judgment_filing_type', this.state.judgment_filing_type?this.state.judgment_filing_type:null);
        if(this.state.proposerSign) {
            this.formData.append('password', encrypt(this.state.signedPassword));
        }
        this.formData.append('submit_type', submit_type);

        apiService.updateProposal(this.formData)
        .then(res => {
            this.setState({loading: false});
            if(res.status == 'success') {
                message.success(res.message);
                this.closeproposal();
                this.setState({description: '', proposalAmount: '', assignTo: [], isSumbitProposal: false});
                if(submit_type != 'update') {
                    websocketService.send({
                        method: 'proposal',
                        isDisplay: true,
                        data: {
                            users: parties,
                            content: res.instant_msg,
                            type: 'update',
                            time_display: false
                        }
                    });
                }     
            } else if(res.status == 'error') {
                message.error(res.message);
            }
        });
    }

    closeproposal() {
        this.setState({current: 0, description: '', proposalAmount: '', assignTo: [], uploadList: [], addProposal: false, submitTerms: false, amendTerms: false, amdWdlTerms: false, proposerSign: false, signedPassword: ''});
        this.stepsCompleted[0] = false;
        this.uploadList = [];
        this.onClose();
    }

    forgotPassword() {
        this.setState({loading: true});
        apiService.logout()
        .then((res) => {
            if (res.status === 'success') {
                userService.removeUser();
                this.props.history.push("/auth/forgotpassword");
            }
        });
    }

    showPermissionDrawer(e) {
        this.setState({permissionDrawerVisible: true});
        e.stopPropagation();
    }

    closePermissionDrawer() {
        this.setState({permissionDrawerVisible: false});
    }

    changeJudgment($event) {
        this.setState({judgment_required: $event});
    }

    showJudgmentInfo() {
        let assigneeCheck = [];
        this.state.assignTo.forEach(e => {
            if(e.permission == 'Accept & Sign' && (e.role_label == 'Claimant' || e.role_label == 'Respondent')) {
                assigneeCheck = assigneeCheck.concat(e);
            }                    
        });
        if(this.user.role_label == "Respondent" || this.user.role_label == "Claimant") {
            if(assigneeCheck.length == 0) {
                message.error('Please select the Accept & Sign option for the opposite party.');
                return;
            }
        } else {
            if(assigneeCheck.length < 2) {
                message.error('Please select the Accept & Sign option for the opposite party.');
                return;
            }
        }
        this.setState({ isJudgmentInfo: true });
    }

    closeJudgmentInfo() {
        this.setState({isJudgmentInfo: false});
    }

    filterPartyPermission() {
        return this.state.assignTo.filter(e => (e.permission != 'No Access' && (e.role_label == 'Claimant' || e.role_label == 'Respondent')));
    }

    setFilingLocation(e) {
        this.setState({judgment_filing_location: e});
    }

    setFeeBy(e) {
        this.setState({fee_to_be_paid_by: e})
    }

    render() {
        const { createOutcomeVisible, loading, caseInfo, current, fvalid, description, proposalAmount, uploadList, selectedProposal, caseParties, editProposal, submitTerms, amendTerms, signedPassword, amdWdlTerms, proposerSign, previewFileName, previewFileDrawer, previewFileId, previewPdfFile, previewImgFile, noPreviewFile, uploadFileData, isDownload, permissionDrawerVisible, isSumbitProposal, judgment_required, judgment_case_type, judgment_filing_location, judgment_filing_type, judgment_required_split_amount, judgment_required_info, checkingJudgment, amount_to_pay, existing_judgment, fee_to_be_paid_by, isJudgmentInfo, filingLocations } = this.state;
        const { Step } = Steps;
        const { Panel } = Collapse;

        return (
            <div>
                <Drawer
                    title={ editProposal ? 'Edit Proposal' : 'Create Proposal' }
                    className="custom-drawer-popup create-outcome-drawer"
                    placement="top"
                    height={"100%"}
                    onClose={this.onClose}
                    visible={createOutcomeVisible}
                >
                        <Spin spinning={loading} delay={500}>
                            <Row className="sub-header case-name-header">
                                <Col span={20} className="case-name-bar">
                                    <CaseStatusIcon caseId={this.caseId} case_status={caseInfo.case_status} case_type={caseInfo.case_type} size={24}/>
                                    <span className="pl16 case-name"><b>{caseInfo ? caseInfo.case_name:''}</b></span>
                                    <span className="pl8">
                                        { !caseInfo?.isFav ? <StarIcon />: <StarFillIcon />}
                                    </span>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col className="steps">
                                    <Steps current={current} progressDot className="drawer-steps" id="proposal-steps">
                                        <Step />
                                        <Step />
                                        <Step />
                                    </Steps>
                                </Col>
                            </Row>
                            <div style={{ display: (current === 0?'block':'none')}}>
                                <Row>
                                    <Col span={24} className="outcome-step-one">
                                        <p className="outcome-content">Step 1 - Tell us about your Proposal </p>
                                        <div className="steps-content">
                                            <Form
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                className="outcome-step-one-form"
                                                >
                                                <Form.Item
                                                    label="Details of Proposal"
                                                    name="detailsProposal"
                                                >
                                                    <div style={{ display: 'none', visibility: 'hidden'}}>{description}</div>
                                                    <ReactQuill theme="snow" value={description} 
                                                        modules = {{
                                                            toolbar: [
                                                                ['bold', 'italic', 'underline',],
                                                                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                                            ],
                                                        }}
                                                        formats = {[
                                                            'bold', 'italic', 'underline',
                                                            'list', 'bullet', 'indent'
                                                        ]} 
                                                        placeholder="Please add details of your Proposal here.&#10;Refer to any Attachment(s) if they form part of your Proposal."
                                                        onChange={this.handleProposalDescription} 
                                                    />
                                                    
                                                </Form.Item>
                                                <Form.Item
                                                    label={<>Attachment(s)&nbsp;<span>optional</span></>}
                                                    name="attachments"
                                                >
                                                    <Upload name="upload-doc" showUploadList={false} beforeUpload={(e) => this.beforeUpload(e)} customRequest={this.dummyRequest} multiple={true}>
                                                        <Button type="primary" id="proposal-add-file">{((selectedProposal && selectedProposal.filesInfo && selectedProposal.filesInfo.length > 0) || (this.uploadList.length != 0)) ? "Add more file": "Browse Files"}</Button>
                                                    </Upload>
                                                    {   (selectedProposal && selectedProposal.filesInfo && selectedProposal.filesInfo.length > 0) &&
                                                        (
                                                            <div className="uploaded-files">
                                                                <ul className="uploaded-file-list">
                                                                    {selectedProposal.filesInfo.map((f, index) => (
                                                                        <li className="uploaded-file" key={index}>
                                                                            <span className="file-icon">
                                                                                {/* { f.item.icon } */}
                                                                                {this.getFileIcon(f.mime_type)}
                                                                            </span>
                                                                            <span className="file-name" title={ f.orginal_name }>
                                                                                { f.orginal_name }
                                                                            </span>
                                                                            <span className="file-delete" >
                                                                                <Popconfirm
                                                                                    title="Are you sure you want to remove this file?"
                                                                                    onConfirm={() => this.removeFile(index, f.orginal_name, f.f_id, true)}
                                                                                >
                                                                                    <DeleteIcon />
                                                                                </Popconfirm>
                                                                            </span>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )
                                                    }

                                                    {   this.uploadList.length != 0 &&
                                                        (
                                                            <div className="uploaded-files">
                                                                <ul className="uploaded-file-list">
                                                                    {
                                                                        this.uploadList.map((f, index) => (
                                                                            <li className="uploaded-file" key={index}>
                                                                                <span className="file-icon">
                                                                                    { f.item.icon }
                                                                                </span>                                               
                                                                                <span className="file-name" title={ f && f.item ? f.item.name: '' }>
                                                                                    { f && f.item ? f.item.name: '' }
                                                                                </span>
                                                                                <span className="file-delete" >
                                                                                    <Popconfirm
                                                                                        title="Are you sure you want to remove this file?"
                                                                                        onConfirm={() => this.removeFile(index, f.item.name, 0, false)}
                                                                                    >
                                                                                        <DeleteIcon />
                                                                                    </Popconfirm>
                                                                                </span>
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            </div>
                                                        )
                                                    }
                                                    <span className="doc-include">You can include documents (PDF/DOC/DOCX/PPT/PPTX/TXT) or images ( JPG/PNG) as part of your Proposal.</span>
                                                </Form.Item>
                                                <Form.Item
                                                    label={<>Proposed Figure&nbsp;<span>optional</span></>}
                                                >
                                                    <Form.Item name="proposalFigure" noStyle initialValue={selectedProposal?.proposal_figure}>
                                                        <InputNumber 
                                                            name="proposalFigure"
                                                            className="prop-input" 
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            maxLength={13} 
                                                            step={0.01} 
                                                            onChange={this.handleProposalAmount} 
                                                            value={proposalAmount}
                                                            />
                                                            <div style={{ display: 'none', visibility: 'hidden'}}>{proposalAmount}</div>
                                                    </Form.Item>
                                                    <span className="prop-currency">{ this.siteSetting && this.siteSetting.crid ? this.siteSetting.crid : ''  }</span>
                                                    <p className="prop-cont">If your Proposal provides for a monetary figure, then please mention it here.</p>
                                                </Form.Item>
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            { current === 1 && (<div>
                                <Row>
                                    <Col span={24} className="outcome-step-two">
                                        <p className="outcome-content">Step 2 - Tell us who needs to respond to the Proposal and who can view it. </p>
                                        <Card className="require-judgment-card">
                                            <Row className="title-section" justify="space-between">
                                                <Col span={20}>{ this.siteSetting?.judgment_required_info }</Col>
                                                <Col>
                                                    <Switch size="small" name="judgment_required" checked={judgment_required?true:false} onChange={(e) => this.changeJudgment(e)}></Switch>
                                                </Col>
                                            </Row>
                                        </Card>
                                        <Collapse
                                            className="permissions"
                                            defaultActiveKey={["1"]}
                                        >
                                            <Panel header="Permissions" key="1" showArrow={false} extra={<InfoIcon onClick={(e) => this.showPermissionDrawer(e)}/>}>
                                                {   caseParties && caseParties.length > 0 &&
                                                    (
                                                        caseParties.map((party, index) => (
                                                            <div className="perm-user-details" key={index}>
                                                            <div className="user-details">
                                                                <Avatar className="mr8" src={party.image}>{party.p_name}</Avatar>
                                                                <span>
                                                                    <p className="name">{party.name}</p>
                                                                    <p className="role">{party.role}</p>
                                                                </span>
                                                            </div>
                                                            <Select defaultValue={ this.getPermission(party.u_id) != '' ? this.getPermission(party.u_id) : 'Select' } bordered={false} suffixIcon={<ArrowDownIcon />} onChange={(e) => this.handleChangePermission(e, party.u_id)}>
                                                                { party.permissionLists && party.permissionLists.length > 0 
                                                                    ? party.permissionLists.map((permission, index) => (
                                                                        <React.Fragment key={[index]}>
                                                                            <Option value={permission}>{permission}</Option>
                                                                        </React.Fragment>
                                                                    ))
                                                                    :
                                                                        <></>
                                                                }
                                                            </Select>
                                                        </div>
                                                        ))
                                                    )
                                                }
                                            </Panel>
                                        </Collapse>
                                    </Col>
                                </Row>
                            </div>)}
                            { current === 2 && (<div>
                                <div>
                                    <Row>
                                        <Col span={24} className="outcome-step-three">
                                            <p className="outcome-content">Step 3 - Make one last check of your Proposal and press submit once you are ready.</p>
                                            {/* {!editProposal && ((this.getPermission(this.user.id) == 'Propose' || this.getPermission(this.user.id) == 'Can Amend / Withdraw') ? "Submit": "Sign & Submit")}{editProposal && ((this.getPermission(this.user.id) == 'Propose' || this.getPermission(this.user.id) == 'Can Amend / Withdraw') ? "Update": "Sign & Update")}  */}
                                            <div className="steps-content">
                                                <Form
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                className="outcome-step-one-form">
                                                    <Form.Item
                                                        label="Proposal Details"
                                                        name="proposalDetails"
                                                    >
                                                        <p className="mb0" dangerouslySetInnerHTML={{ __html: description }}></p>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label={<>Proposed Figure&nbsp;<span>optional</span></>}
                                                    >
                                                        {   proposalAmount 
                                                            ?
                                                                <span className="currency">{proposalAmount} { this.siteSetting && this.siteSetting.crid ? this.siteSetting.crid : ''  }</span>
                                                            :
                                                                <span>None</span>
                                                        }
                                                    </Form.Item>
                                                    <Form.Item
                                                        label={<>Attachment(s)&nbsp;<span>optional</span></>}
                                                        name="attachments"
                                                    >
                                                        {   ((this.uploadList.length == 0 && !editProposal) || (this.uploadList.length == 0 && editProposal && selectedProposal && selectedProposal.filesInfo && selectedProposal.filesInfo.length == 0)) &&
                                                            (
                                                                <span>No Files Attached</span>
                                                            )
                                                        }
                                                        {   (selectedProposal && selectedProposal.filesInfo && selectedProposal.filesInfo.length > 0) &&
                                                            (
                                                                selectedProposal.filesInfo.map((f, index) => (
                                                                    <div className="uploaded-files" key={index}>
                                                                        <ul className="uploaded-file-list">
                                                                            <li className="uploaded-file" >
                                                                                {/* <span className="file-icon">
                                                                                    { f.item.icon }
                                                                                </span> */}
                                                                                <span className="file-name" title={ f.orginal_name }>
                                                                                    { f.orginal_name }
                                                                                </span>
                                                                                <Button type="primary" className="file-preview" onClick={() => this.existingShowPreview(f)}>Preview</Button>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                ))
                                                            )
                                                        }

                                                        {   (this.uploadList.length != 0) &&
                                                            (
                                                                this.uploadList.map((f, index) => (
                                                                    <div className="uploaded-files" key={index}>
                                                                        <ul className="uploaded-file-list">
                                                                            <li className="uploaded-file" >
                                                                                <span className="file-icon">
                                                                                    { f.item.icon }
                                                                                </span>
                                                                                <span className="file-name" title={ f && f.item ? f.item.name: '' }>
                                                                                    { f && f.item ? f.item.name: '' }
                                                                                </span>
                                                                                <Button type="primary" className="file-preview" onClick={() => this.showPreview(f)}>Preview</Button>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                ))
                                                            )
                                                        }

                                                    </Form.Item>
                                                    <Drawer
                                                        title={previewFileName}
                                                        className="task-file-view-drawer"
                                                        placement="top"
                                                        height={"100%"}
                                                        onClose={() => this.onShowPreviewFileDrawer(previewFileDrawer)}
                                                        visible={previewFileDrawer}
                                                        extra={isDownload && (<a href={ APIURL + "file/downloadFile?f_id=" + previewFileId + "&token=" + this.user.token} download><DownloadIcon /></a>)}
                                                    >
                                                        {previewPdfFile && (<PDFView pdfData={uploadFileData} isUrl={true} />)}
                                                        {previewImgFile && (<div><img src={uploadFileData} alt="img" width="100%"/></div>)}
                                                        {noPreviewFile && (<div className="no-preview-container"><Card><span>No Preview Available</span> {isDownload && (<p>You can Download copy of the file</p>)}</Card></div>)}
                                                    </Drawer>
                                                    <Card className="require-judgment-card">
                                                        <Row className="title-section" justify="space-between">
                                                            <Col span={20}>{ this.siteSetting?.judgment_required_info }</Col>
                                                            <Col>
                                                                <Switch size="small" name="judgment_required" disabled={true} checked={judgment_required?true:false} onChange={(e) => this.changeJudgment(e)}></Switch>
                                                            </Col>
                                                        </Row>
                                                        { judgment_required && !(caseInfo?.case_addtl_ref_no && caseInfo?.case_addtl_ref_no != 'null') && (
                                                            <div className="form-section">
                                                                { !(caseInfo?.case_addtl_ref_no && caseInfo?.case_addtl_ref_no != 'null') && (<Form.Item label="Filing Location">
                                                                    <Select defaultValue={ judgment_filing_location?judgment_filing_location:'Select' } disabled={true} onChange={(e) => this.setFilingLocation(e)} bordered={true} suffixIcon={<ArrowDownIcon />}>
                                                                        { filingLocations.map((item, index) => (
                                                                                <React.Fragment key={[index]}>
                                                                                    <Option value={item.f_l_id}>{item.name}</Option>
                                                                                </React.Fragment>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </Form.Item>)}
                                                                { !(caseInfo?.case_addtl_ref_no && caseInfo?.case_addtl_ref_no != 'null') && judgment_required && !existing_judgment && (<Form.Item label="Fee To Be Paid by">
                                                                    <Select defaultValue={ fee_to_be_paid_by?fee_to_be_paid_by:'Select' } disabled={true} bordered={true} onChange={(e) => this.setFeeBy(e)} suffixIcon={<ArrowDownIcon />}>
                                                                        { this.filterPartyPermission().map((item, index) => (
                                                                                <React.Fragment key={[index]}>
                                                                                    <Option value={item.party_id}>{item.name}</Option>
                                                                                </React.Fragment>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </Form.Item>)}
                                                            </div>
                                                        )}
                                                        { !(caseInfo?.case_addtl_ref_no && caseInfo?.case_addtl_ref_no != 'null') && judgment_required && !existing_judgment && (
                                                            <div className="section-body">
                                                                <Row className="rj-amount-box">
                                                                    <Col className="rj-split-label" span={16}>
                                                                        Judgment Fee
                                                                    </Col>
                                                                    <Col className="rj-amount" span={8}>
                                                                        { amount_to_pay?amount_to_pay:(this.siteSetting.judgment_required_amount) } <span>{ this.siteSetting.currency.cr_id }</span>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )}
                                                    </Card>
                                                    <Card title="Permissions" className="permission-card" extra={<InfoIcon onClick={(e) => this.showPermissionDrawer(e)}/>}>
                                                        <Row>
                                                            <Col span={12} className="left-details">Accept & Sign:</Col>
                                                            <Col span={12}>
                                                                {   (caseParties.filter(arrayItem => arrayItem.permissions.includes('Accept & Sign')).length != 0) && 
                                                                    (
                                                                        <Avatar.Group maxCount={2} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                                                                            {
                                                                                caseParties.filter(arrayItem => arrayItem.permissions.includes('Accept & Sign')).map((party, index) => (
                                                                                    <Avatar src={party.image} key={index}>{party.p_name}</Avatar>
                                                                                ))
                                                                            }
                                                                        </Avatar.Group>
                                                                    )
                                                                }
                                                                {   (caseParties.filter(arrayItem => arrayItem.permissions.includes('Accept & Sign')).length == 0) &&
                                                                    (
                                                                        <span className="permission-none">None</span>
                                                                    )
                                                                }
                                                            </Col>
                                                            <Col span={12} className="left-details">Can Amend  / Withdraw:</Col>
                                                            <Col span={12}>
                                                                {   (caseParties.filter(arrayItem => arrayItem.permissions.includes('Can Amend / Withdraw')).length != 0) && 
                                                                    (
                                                                        <Avatar.Group maxCount={2} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                                                                            {
                                                                                caseParties.filter(arrayItem => arrayItem.permissions.includes('Can Amend / Withdraw')).map((party, index) => (
                                                                                    <Avatar src={party.image} key={index}>{party.p_name}</Avatar>
                                                                                ))
                                                                            }
                                                                        </Avatar.Group>
                                                                    )
                                                                }
                                                                {   (caseParties.filter(arrayItem => arrayItem.permissions.includes('Can Amend / Withdraw')).length == 0) &&
                                                                    (
                                                                        <span className="permission-none">None</span>
                                                                    )
                                                                }
                                                            </Col>
                                                            <Col span={12} className="left-details">Can View:</Col>
                                                            <Col span={12}>
                                                                {   (caseParties.filter(arrayItem => arrayItem.permissions.includes('Can View')).length != 0) && 
                                                                    (
                                                                        <Avatar.Group maxCount={2} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                                                                            {
                                                                                caseParties.filter(arrayItem => arrayItem.permissions.includes('Can View')).map((party, index) => (
                                                                                    <Avatar src={party.image} key={index}>{party.p_name}</Avatar>
                                                                                ))
                                                                            }
                                                                        </Avatar.Group>
                                                                    )
                                                                }
                                                                {   (caseParties.filter(arrayItem => arrayItem.permissions.includes('Can View')).length == 0) &&
                                                                    (
                                                                        <span className="permission-none">None</span>
                                                                    )
                                                                }
                                                            </Col>
                                                            <Col span={12} className="left-details">Need to approve:</Col>
                                                            <Col span={12}>
                                                                {   (caseParties.filter(arrayItem => arrayItem.permissions.includes('Must Approve')).length != 0) && 
                                                                    (
                                                                        <Avatar.Group maxCount={2} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                                                                            {
                                                                                caseParties.filter(arrayItem => arrayItem.permissions.includes('Must Approve')).map((party, index) => (
                                                                                    <Avatar src={party.image} key={index}>{party.p_name}</Avatar>
                                                                                ))
                                                                            }
                                                                        </Avatar.Group>
                                                                    )
                                                                }
                                                                {   (caseParties.filter(arrayItem => arrayItem.permissions.includes('Must Approve')).length == 0) &&
                                                                    (
                                                                        <span className="permission-none">None</span>
                                                                    )
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Card>

                                                    {   (isSumbitProposal && (this.getPermission(this.user.id) == 'Propose' || this.getPermission(this.user.id) == 'Can Amend / Withdraw')) &&
                                                        (
                                                            <Row className="pb45">
                                                                <Col span={24} className="pb8">
                                                                    <Checkbox id="submit-terms" checked={submitTerms} onChange={() => this.handleSubmitTerms(submitTerms)}>I am sure I want to submit this Proposal.</Checkbox>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <Checkbox id="amend-terms" checked={amendTerms} onChange={() => this.handleAmendTerms(amendTerms)}>I understand I cannot amend or withdraw this Proposal once it is accepted by a Signing Party.</Checkbox>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    }
                                                </Form>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                {   (isSumbitProposal && this.getPermission(this.user.id) == 'Propose & Sign') &&
                                    (
                                        <div className="document-sign create-outcome-sign" id="create-outcome-sign">
                                            <p>Please enter your account password to electronically sign to accept this Proposal. Your name, date and timezone will be recorded on the document as evidence of your signature.</p>
                                            <Input.Password id="add-outcome-sign-password" autoFocus placeholder="Enter your password" value={signedPassword} onChange={(e) => this.signPasswordChange(e)} maxLength={32} iconRender={visible => (visible ? <EyeIcon /> : <EyeInvisibleIcon />)} />
                                            <span className="forget-pswd" onClick={() => this.forgotPassword()}>Forgot password?</span>
                                            <Checkbox id="proposer-sign" checked={proposerSign} onChange={() => this.handleProposerSign(proposerSign)}>I consent to completing this process electronically and intend to electronically sign this Proposal as evidence of my acceptance and intention to be bound by it.</Checkbox><br/>
                                            <Checkbox id="amdWdl-terms" checked={amdWdlTerms} onChange={() => this.handleAmdWdlTerms(amdWdlTerms)}>I understand that once the other Party has electronically signed this Proposal, I'll be unable to amend or withdraw this Proposal or withdraw my acceptance of it.</Checkbox>
                                        </div>
                                    )
                                }
                            </div>)}
                            <Row className="all-view-footer">
                                <Col span={(current == 2 && editProposal && selectedProposal.p_status != 0) ? 12: current == 2 ? 8: 12}>
                                    {/* {current == 0 && (<Button type="primary" id="add-outcome-close" className="cancel" onClick={this.onClose}>Close</Button>)} */}
                                    {current == 1 && (<Button type="primary" id="add-outcome-previous" className="cancel" onClick={() => this.previous(0)}>Previous</Button>)}
                                    {current == 2 && (<Button type="primary" id="add-outcome-previous" className="cancel" onClick={() => this.previous(1)}>Previous</Button>)}
                                </Col>
                                {   ((current == 2 && selectedProposal.p_status != 0 && !isSumbitProposal) || (current == 2 && selectedProposal.p_status == 0) || (current == 2 && !editProposal)) &&
                                    (
                                        <Col span={(editProposal && selectedProposal.p_status != 0) ? 12: 8}>
                                            {!editProposal && (<Button type="primary" id="add-outcome-submit" disabled={caseInfo && caseInfo.case_status != 3} onClick={() => this.createProposal('draft')}>Save as Draft</Button>)}

                                            {editProposal && (<Button type="primary" id="add-outcome-submit" disabled={caseInfo && caseInfo.case_status != 3} onClick={() => selectedProposal.p_status == 0 ? this.validateUpdateProposal('update') : this.validateUpdateProposal('sign')}>{selectedProposal.p_status == 0 ? 'Save as Draft' : 'Update'}</Button>)}
                                        </Col>
                                    )
                                }
                                <Col span={(current == 2 && editProposal && selectedProposal.p_status != 0) ? 12: current == 2 ? 8: 12}>
                                    {current == 0 && (<Button type="primary" id="add-outcome-next" disabled={fvalid}  onClick={() => this.next()}>Next</Button>)}
                                    {current == 1 && judgment_required && !isJudgmentInfo && (<Button type="primary" id="add-outcome-preview" disabled={fvalid} onClick={() => this.showJudgmentInfo()}>Next</Button>)}
                                    {current == 1 && (!judgment_required || isJudgmentInfo) && (<Button type="primary" id="add-outcome-preview" disabled={fvalid} onClick={() => this.preview()}>Preview & Submit</Button>)}

                                    {(current == 2 && !editProposal && !isSumbitProposal) && (<Button type="primary" id="add-outcome-preview" onClick={() => this.createProposal('sign')}>Submit</Button>)}

                                    {(current == 2 && editProposal && selectedProposal.p_status == 0 && !isSumbitProposal) && (<Button type="primary" id="add-outcome-preview" onClick={() => this.validateUpdateProposal('sign')}>Submit</Button>)}

                                    {(current == 2 && !editProposal && isSumbitProposal) && (<Button type="primary" id="add-outcome-submit" disabled={this.getPermission(this.user.id) == 'Propose' || this.getPermission(this.user.id) == 'Can Amend / Withdraw'?(!submitTerms || !amendTerms || loading):(!signedPassword || !proposerSign || !amdWdlTerms || loading)} onClick={() => this.sumbitProposal('sign')}>{(this.getPermission(this.user.id) == 'Propose' || this.getPermission(this.user.id) == 'Can Amend / Withdraw') ? "Submit": "Sign & Submit"}</Button>)}
                                    
                                    {(current == 2 && editProposal && isSumbitProposal) && (<Button type="primary" id="add-outcome-submit" disabled={this.getPermission(this.user.id) == 'Propose' || this.getPermission(this.user.id) == 'Can Amend / Withdraw'?(!submitTerms || !amendTerms || loading):(!signedPassword || !proposerSign || !amdWdlTerms || loading)} onClick={() => this.updateProposal('sign')}>{(this.getPermission(this.user.id) == 'Propose' || this.getPermission(this.user.id) == 'Can Amend / Withdraw') ? "Submit": "Sign & Submit"}</Button>)}
                                </Col>
                            </Row>
                        </Spin>
                </Drawer>
                <Drawer 
                    title="Court Judgment"
                    visible={isJudgmentInfo}
                    placement="bottom"
                    mask={false}
                    onClose={() => this.closeJudgmentInfo() }
                    className="signup-info-drawer require-judgment-card"
                >
                    <Row className="title-section" justify="space-between">
                        <Col span={20}>{ this.siteSetting?.judgment_required_info }</Col>
                        <Col>
                            <Switch size="small" name="judgment_required" checked={judgment_required?true:false} onChange={(e) => this.changeJudgment(e)}></Switch>
                        </Col>
                    </Row>
                    { judgment_required && !(caseInfo?.case_addtl_ref_no && caseInfo?.case_addtl_ref_no != 'null') && (
                        <div className="form-section">
                            { !(caseInfo?.case_addtl_ref_no && caseInfo?.case_addtl_ref_no != 'null') && (<Form.Item label="Filing Location">
                                <Select defaultValue={ judgment_filing_location?judgment_filing_location:'Select' } disabled={existing_judgment} onChange={(e) => this.setFilingLocation(e)} bordered={true} suffixIcon={<ArrowDownIcon />}>
                                    { filingLocations.map((item, index) => (
                                            <React.Fragment key={[index]}>
                                                <Option value={item.f_l_id}>{item.name}</Option>
                                            </React.Fragment>
                                        ))
                                    }
                                </Select>
                            </Form.Item>)}
                            { !(caseInfo?.case_addtl_ref_no && caseInfo?.case_addtl_ref_no != 'null') && judgment_required && !existing_judgment && (<Form.Item label="Fee To Be Paid by">
                                <Select defaultValue={ fee_to_be_paid_by?fee_to_be_paid_by:'Select' } disabled={existing_judgment} bordered={true} onChange={(e) => this.setFeeBy(e)} suffixIcon={<ArrowDownIcon />}>
                                    { this.filterPartyPermission().map((item, index) => (
                                            <React.Fragment key={[index]}>
                                                <Option value={item.party_id}>{item.name}</Option>
                                            </React.Fragment>
                                        ))
                                    }
                                </Select>
                            </Form.Item>)}
                        </div>
                    )}
                    { !(caseInfo?.case_addtl_ref_no && caseInfo?.case_addtl_ref_no != 'null') && judgment_required && !existing_judgment && (
                        <div className="section-body">
                            <Row className="rj-amount-box">
                                <Col className="rj-split-label" span={16}>
                                    Judgment Fee
                                </Col>
                                <Col className="rj-amount" span={8}>
                                    { amount_to_pay?amount_to_pay:(this.siteSetting.judgment_required_amount) } <span>{ this.siteSetting.currency.cr_id }</span>
                                </Col>
                            </Row>
                        </div>
                    )}
                </Drawer>
                <Drawer
                    title={ "Permissions" }
                    className="custom-drawer-popup outcome-permission-drawer"
                    placement="bottom"
                    //height={"100%"}
                    onClose={() => this.closePermissionDrawer()}
                    visible={permissionDrawerVisible}
                    >
                        <Row>
                            <Col>
                                <p>Participants in the case may need to perform certain actions in responding to a Proposal or Case Decision&#44; for resolving the case. The explanation of these &quot;Permissions&quot; are:</p><p>(Applicable for Proposals only)<br/>
                                <b>Propose & Sign&#58;</b> Participant making this Proposal is the primary person responsible for this case in their party. This person needs to sign the proposal while submitting it.<br/>
                                <b>Accept & Sign&#58;</b> Primary participant in the other party who needs to accept and sign, thereby binding them to the proposal. Alternately&#44; they may &quot;reject&quot; the proposal.</p>
                                <p>(Applicable for Proposals and Case Decisions)<br/>
                                <b>Can View&#58;</b> Participant can view the details of the Proposal or Case Decision.<br/>
                                <b>Must Approve&#58;</b> This is applicable only if your organization requires approval for a Proposal or Case Decision&#44; before it can be published to other Participants.<br/>
                                <b>No Access&#58;</b> Participant does not need to act on this item&#44; will not have access to it&#44; and will not be able to view any details of the item.</p>
                            </Col>
                        </Row>
                </Drawer>
            </div>
        );
    }
}
export default CreateOutcome;