import { Button, Card, Col, Row, Radio, Steps, Divider, Input, Drawer, Select, Spin, message, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { ArrowRightIcon, DeleteIcon, SortDownIcon } from "../../logics/images";
import apiService from "../../logics/services/api.service";
import { CloseIcon } from "../../logics/images";
import userService from "../../logics/services/user.service";
const { Step } = Steps;

function CreateCase() {
    const history = useHistory();
    const user = userService.getUser();
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [rolesList, setRolesList] = useState([]);
    const [draftList, setDraftList] = useState([]);
    const [searchResult, setSearchResult] = useState(false);
    const [isNewCase, setIsNewCase] = useState();
    const [existingCaseNumber, setExistingCaseNumber] = useState('');
    const [existingCaseTitle, setExistingCaseTitle] = useState('');
    const [existingCaseResponse, setExistingCaseResponse] = useState([]);
    const [caseDocketID, setCaseDocketID] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [isSearch, setIsSearch] = useState(false);
    const [isConfirmDelete, setIsConfirmDelete] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [selectedDraft, setSelectedDraft] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [loadingDraft, seLoadingDraft] = useState(false);

    const showSearchResultDrawer = () => {
        setSearchResult(true);
    }

    const onCloseSearchResult = () => {
        setSearchResult(false);
    }

    useEffect(() => {
        getDrafts();
        if (user?.role_label == 'Head Admin') {
            history.push("/app/allcases");
        }
    }, []);

    const getDrafts = () => {        
        seLoadingDraft(true);
        apiService.getDraftCases()
            .then(res => {
                seLoadingDraft(false);
                if (res.status == 'success') {
                    setDraftList(res.data);
                    if (res.roles) {
                        setRolesList(res.roles);
                    }
                }
            });
    }

    const navigateCreate = () => {        
        localStorage.setItem('case_reg_token', btoa('Claimant'));
        history.push('/app/case-create');
    }

    const navigateCreateDraft = (case_id) => {
        history.push("/app/case-create/"+case_id);
    }

    const isExistingCase = ({ target: { value } }) => {
        setIsNewCase(value);
        setExistingCaseNumber('');
        if(value == 'No') {
            setExistingCaseTitle('');
            setCaseDocketID('');
        }
    };

    const getExistingCaseNumber = (e) => {
        setExistingCaseNumber(e.target.value);
    }

    const resetCaseNumber = () => {
        setExistingCaseNumber('');
        setSelectedRole('');
        setExistingCaseResponse([]);
        setExistingCaseTitle('');
        setCaseDocketID('');
        setIsSearch(false);
    }

    const checkCaseNumberFormat = (num) => {
        let regex = /[0-9]{1}[A-Za-z]{2}[-]{1}[0-9]{2}[-]{1}[0-9]{5}[A-Za-z]{2}$/;
        if (regex.test(num)) {
            return false;
        } else {
            return true;
        }
    }

    const verifyCaseNumber = () => {
        if (checkCaseNumberFormat(existingCaseNumber)) {
            message.error('Please enter valid Case Number');
            return;
        }
        setSearchLoading(true);
        setExistingCaseTitle('');
        setExistingCaseResponse([]);
        apiService.verifyCaseNumber({
            case_number: existingCaseNumber
        }).then(res => {
            if(!searchResult) {
                setSearchResult(true);
            }
            setIsSearch(true);
            setSearchLoading(false);
            if (res.caselistresponseCaseListResponseMessage) {
                setExistingCaseResponse(res.caselistresponseCaseListResponseMessage);
                if (res.caselistresponseCaseListResponseMessage.ncCase) {
                    setExistingCaseTitle(res.caselistresponseCaseListResponseMessage.ncCase.ncCaseTitleText);
                    setCaseDocketID(res.caselistresponseCaseListResponseMessage.ncCase.ncCaseDocketID);
                }
            } else {
                setExistingCaseResponse(res);
            }
        });
    }

    const createNewCase = () => {
        setSearchResult(false);
        setIsNewCase('No');
        setSelectedRole('');
        setExistingCaseNumber('');
        setExistingCaseResponse([]);
        setExistingCaseTitle('');
        setCaseDocketID('');
    }

    const handleChange = (value) => {
        setSelectedRole(value);
    };

    const createClick = () => {
        if (rolesList.length == 0 || selectedRole == user?.role_label) {
            localStorage.removeItem('case_reg_token');
            setLoading(true);
            if (existingCaseTitle && caseDocketID) {
                apiService.draftCase({
                    case_docket_id: caseDocketID,
                    case_title: existingCaseTitle,
                    step: 'initial',
                    api_response: existingCaseResponse
                }).then(res => {
                    setLoading(false);
                    if (res.status == 'success') {
                        history.push("/app/case-create/"+res.case_id);
                    }
                });
            } else {
                history.push("/app/case-create");
            }
        } else {
            setLoading(true);
            apiService.switchCaseCreateRole({
                role_label: selectedRole 
            }).then(res => {
                if (res.status == 'success') {
                    if (selectedRole != user?.role_label) {
                        if (res.data.permission['Create Case']) {
                            localStorage.setItem('case_reg_token', btoa(res.data.role_label));
                            if (existingCaseTitle && caseDocketID) {
                                apiService.draftCase({
                                    case_docket_id: caseDocketID,
                                    case_title: existingCaseTitle,
                                    role_label: res.data.role_label,
                                    step: 'initial',
                                    api_response: existingCaseResponse
                                }).then(res => {
                                    setLoading(false);
                                    if (res.status == 'success') {
                                        history.push("/app/case-create/"+res.case_id);
                                    }
                                });
                            } else {
                                setLoading(false);
                                history.push("/app/case-create");
                            }
                        } else {
                            message.error('Selected role don\'t have create case permission.');
                        }
                    }
                } else {
                    setLoading(false);
                }
            });
        }
    }

    const deleteDraft = (list) => {
        setIsConfirmDelete(true);
        setSelectedDraft(list);
    }

    const deleteCancel = () => {
        setIsConfirmDelete(false)
    }

    const confirmDelete = () => {
        setDeleteLoading(true);
        apiService.deleteDraftCase({
                case_id: selectedDraft.case_id    
            }).then((res) => {
                setDeleteLoading(false);
                if (res.status == 'success') {
                    message.success(res.message);
                    setIsConfirmDelete(false);
                    getDrafts();
                }
            });
    }

    const sortDraftList = () => {
        setSortDirection(sortDirection === '-'?'':'-');
        sortByDate();
    }

    const sortByDate = () => {
        if (sortDirection == '-') {
            let draftLists = draftList.sort((a, b) => {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            });
            setDraftList(draftLists);
        } else {
            let draftLists = draftList.sort((a, b) => {
                return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
            });
            setDraftList(draftLists);
        }
    }

    return (<div className={draftList.length > 1?"case-create-container":"case-create-container draft-list-empty"}>
        <Row>
            <Spin spinning={loadingDraft}>
                { user?.casecount > 0 && <Col span={24} className="header">
                    <Link to={`/app/allcases`} style={{ position: 'absolute', left: '16px'}}><CloseIcon/></Link> Case Registration
                </Col>}
                <Col span={24}>
                    <Card className="register-card">
                        <h4>Hi { user?.name }!</h4>
                        <p>Let’s get started by registering your case and inviting the other party.</p>
                        <Divider />
                        <Row>
                            <Col>
                                <span className="label-text">Do you have a Case Number for an existing case?</span>
                            </Col>
                        </Row>
                        <Row className="existing-case-option">
                            <Col span={24}>
                                <Radio.Group onChange={isExistingCase} value={isNewCase}>
                                    <Radio.Button value="Yes">Yes</Radio.Button>
                                    <Radio.Button value="No">No</Radio.Button>
                                </Radio.Group>
                            </Col>
                        </Row>
                        {   isNewCase == "No" &&
                            (
                                <Row align="bottom" justify="space-around" gutter={24}>
                                    <Spin spinning={loading}>
                                        { rolesList.length > 0 && <Col span={24} className="select-role">
                                                <p>What will be your role in this case?</p>
                                                <Select placeholder="Select Role" style={{width: 'calc(100vw - 72px)'}} dropdownMatchSelectWidth={false} onChange={handleChange}>
                                                { rolesList && rolesList.length > 0 
                                                    ? rolesList.map((role, index) => (
                                                        <React.Fragment key={index}>
                                                            <Option value={role.role_label}>{role.role_name}</Option>
                                                        </React.Fragment>
                                                    ))
                                                    :
                                                        <></>
                                                }
                                                </Select>
                                            </Col>
                                        }
                                        <Col span={24}>
                                            <Button type="primary" className="create-case-btn" onClick={ createClick } disabled={rolesList.length > 0 && !selectedRole}>Create a Case</Button>
                                        </Col>
                                    </Spin>
                                </Row>
                            )
                        }
                        {   isNewCase == "Yes" &&
                            (
                                <>
                                    <Spin spinning={searchLoading}>
                                        <Row>
                                            <Col>
                                                <span className="label-text">Please enter the Case Number of your existing case.</span>
                                            </Col>
                                        </Row>
                                        <Row className="search-container" style={{ marginBottom: 0 }} justify="space-between">
                                            <Col span={24}>
                                                <Input placeholder="ex: 3XX-18-12345XX" style={{ borderRadius: 0 }} value={existingCaseNumber} onChange={ e => getExistingCaseNumber(e)} maxLength="250"/>
                                            
                                                <Button type="primary" style={{ marginLeft: '16px'}} onClick={verifyCaseNumber} disabled={!existingCaseNumber}>Search</Button>
                                            </Col>
                                        </Row>
                                    </Spin>
                                </>
                            )
                        }
                    </Card>
                </Col>
                { draftList.length != 0 && <Col span={24} className="draft-lists">
                    <div className="draft-header">
                        <Row align="middle" justify="space-between">
                            <Col>
                                <h5>My Drafts</h5>
                                <p>Previous Case Registrations awaiting completion</p>
                            </Col>
                            <Col className="sort-icon">
                                <SortDownIcon style={{ transform: sortDirection === '-'?'rotateX(180deg)':'none', fontSize: '24px', verticalAlign: 'middle'}} onClick={() => sortDraftList()} />
                            </Col>
                        </Row>
                    </div>
                    <Row className="draft-container" align="middle">
                        {draftList.map((list, index) => (  <Col span={24} key={index}>
                            <Card key={'draft_'+index}  actions={[
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            <Steps className="pl16 pt16 pb16" current={list.case_step} responsive={false} direction="horizontal" id="create-case-steps">
                                                <Step icon={<span>1</span>} />
                                                <Step icon={<span>2</span>} />
                                                <Step icon={<span>3</span>} />
                                                <Step icon={<span>4</span>} />
                                                <Step icon={<span>5</span>} />
                                            </Steps>
                                        </Col>
                                        <Col className="right-action" onClick={() => navigateCreateDraft(list.case_id)}>
                                            <ArrowRightIcon />
                                        </Col>
                                    </Row>
                                ]}>
                                <i onClick={() => deleteDraft(list)}><DeleteIcon /></i>
                                <p>My Role: { list.role_name }</p>
                                <p>Category: { list.category?list.category:'No data provided' }</p>
                                <p>Sub-Category: { list.sub_category?list.sub_category:'Not applicable' }</p>
                                <p>Description: { list.case_description?(list.case_description.length > 35?list.case_description.substring(0, 35)+'...':list.case_description):'No data provided' }</p>
                                <p className="mb-0">Created on: { list.created_at.utcLocal().dateTimeFormat() }</p>
                            </Card>
                        </Col>
                        ))}
                    </Row>
                </Col>}
            </Spin>
        </Row>
        <Modal className="draft-confirm-delete" title="Confirm" visible={isConfirmDelete} onCancel={() => deleteCancel()} footer={<><Row gutter={16}><Col span={12}><Button onClick={() => confirmDelete()} loading={deleteLoading} disabled={deleteLoading} type="primary">Yes</Button></Col><Col span={12}><Button disabled={deleteLoading} onClick={() => deleteCancel()} type="link">No</Button></Col></Row></>}>
            <p>Are you sure you want to delete this draft?</p>
        </Modal>
        <Drawer
            placement="bottom"
            title={<><span className={existingCaseResponse && !existingCaseResponse.ncCase && !existingCaseTitle && isSearch ? 'search-result-title red': 'search-result-title green'}>Result</span></>}
            closable={false}
            className="search-result-drawer"
            contentWrapperStyle={{ bottom: 0}}
            extra={<CloseIcon onClick={onCloseSearchResult}/>}
            visible={searchResult}
        >
            <Row className="search-result-detail">
                <Row>
                    <Row className="search-container" justify="space-between">
                        <Col span={24}>
                            <Input placeholder="ex: 3XX-18-12345XX" style={{ borderRadius: 0 }} disabled={isSearch} value={existingCaseNumber} onChange={ e => getExistingCaseNumber(e)} maxLength="250"/>
                            {   isSearch 
                                ?
                                    <Button type="primary" style={{ marginLeft: '16px'}} onClick={resetCaseNumber}>Reset</Button>
                                :
                                    <Button type="primary" style={{ marginLeft: '16px'}} onClick={verifyCaseNumber} disabled={!existingCaseNumber}>Search</Button>
                            }
                        </Col>
                    </Row>
                    { searchLoading &&
                        (
                            <Row className="spin-loading">
                                <Spin spinning={searchLoading}></Spin>
                            </Row>
                        )
                    }
                    {   existingCaseResponse && existingCaseResponse.ncCase && existingCaseTitle &&
                        (
                            <>
                                <Row className="result-case">
                                    <Col>
                                        <span>
                                            Case Name:
                                        </span>
                                        <p>
                                            {existingCaseTitle}
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="info-text-container mb16">
                                    <Col span={24}>
                                        <span className="info-text">Create this existing case in our Platform for online resolution.</span>
                                    </Col>
                                </Row>
                                <Row className="create-case-role ml16 mr16">
                                    <Spin spinning={loading}>
                                        { rolesList.length > 0 && <Col span={24} className="select-role">
                                                <p>What will be your role in this case?</p>
                                                <Select placeholder="Select Role" onChange={handleChange}>
                                                    { rolesList && rolesList.length > 0 
                                                        ? rolesList.map((role, index) => (
                                                            <React.Fragment key={index}>
                                                                <Option value={role.role_label}>{role.role_name}</Option>
                                                            </React.Fragment>
                                                        ))
                                                        :
                                                            <></>
                                                    }
                                                </Select>
                                            </Col>
                                        }
                                        <Col className="mb16" span={24}>
                                            <Button type="primary" onClick={ createClick } disabled={rolesList.length > 0 && !selectedRole}>Create a Case</Button>
                                        </Col>
                                    </Spin>
                                </Row>
                            </>
                        )
                    }
                    {   existingCaseResponse && !existingCaseResponse.ncCase && !existingCaseTitle && isSearch &&
                        (
                            <>
                                <Row className="no-result">
                                    <Col>
                                        <p>We could not find this case number. If you entered the case number correctly, here are reasons why your case number might not be found. You can still use ACS ODR. Just select "No" above.  If you come to a resolution and want a judgment, you will need to file your agreement by mail or at the courthouse.</p>
                                    </Col>
                                </Row>
                                <Row className="no-result-or mb16">
                                    <Col>
                                        <p> Or </p>
                                    </Col>
                                </Row>
                                <Row className="mb16 ml16 mr16">
                                    <Col span={24}>
                                        <Button type="primary" style={{ width: '100%'}} onClick={createNewCase}>Create a new Case</Button>
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                </Row>
            </Row>
        </Drawer>
    </div>)
}
export default CreateCase;