import React, { useState } from "react";
import { Row, Col, Card, Avatar, Input, Divider, Skeleton} from 'antd';
import Icon from '@ant-design/icons';
import { useSelector } from 'react-redux';
import userService from "../../../logics/services/user.service";
import apiService from "../../../logics/services/api.service";
import InfiniteScroll from "react-infinite-scroll-component";
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import "../../../logics/prototypes";
import Picker from 'emoji-picker-react';
import LeftNavSvg from  '../../../assets/svg-icons/left_nav.svg';
import SearchGlobalSvg from  '../../../assets/svg-icons/search-global.svg';
import CloseSvg from  '../../../assets/svg-icons/close.svg';
import EmojiSvg from  '../../../assets/svg-icons/emoji.svg';
import SendMessageSvg from  '../../../assets/svg-icons/send-message.svg';
import NoMessageSvg from  '../../../assets/svg-icons/no-messages.svg';
import store from '../../../logics/store/case.store';
import * as $ from 'jquery';
import { websocketService, socket } from "../../../logics/services/websocket.service";

const LeftNavIcon = props => <Icon component={LeftNavSvg} className="left-nav-icon" style={{ fontSize: 40, verticalAlign: 'middle'}} {...props} />;
const SearchGlobalIcon = props => <Icon component={SearchGlobalSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const CloseIcon = props => <Icon component={CloseSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const EmojiIcon = props => <Icon component={EmojiSvg} style={{ fontSize: 20, verticalAlign: 'middle'}} {...props} />;
const SendMessageIcon = props => <Icon component={SendMessageSvg} style={{ fontSize: 20, verticalAlign: 'middle'}} {...props} />;
const NoMessageIcon = props => <Icon component={NoMessageSvg} style={{ fontSize: 80, verticalAlign: 'middle'}} {...props} />;

const { TextArea } = Input;

const CryptoJSAesJson = {
    stringify(cipherParams) {
        const j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
        if (cipherParams.iv) { j.iv = cipherParams.iv.toString(); }
        if (cipherParams.salt) { j.s = cipherParams.salt.toString(); }
        return JSON.stringify(j);
    },
    parse(jsonStr) {
        const j = JSON.parse(jsonStr);
        const cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
        if (j.iv) { cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv); }
        if (j.s) { cipherParams.salt = CryptoJS.enc.Hex.parse(j.s); }
        return cipherParams;
    }
};

class Message extends React.Component {
    constructor(props) {
        super(props);
        this.caseId=this.props.match.params.caseId;
        this.caseDetailView = this.props.match.params.caseDetailView;
        this.link = this.caseDetailView ? JSON.parse(atob(this.caseDetailView)): '';
        this.caseInfo=null;
        this.chatRoomId=null;
        this.adminUserChatList=[];
        this.typingUsers=[];
        this.isTypingRoom=[];
        this.dateFormats={sameDay: '[Today]', nextDay: 'ddd, MMM D YYYY', nextWeek: 'ddd, MMM D YYYY', lastDay: '[Yesterday]', lastWeek: 'ddd, MMM D YYYY', sameElse: 'ddd, MMM D YYYY'};
        this.state = {
            isMessageList: true,
            isChat: false,
            isNewMessage: false,
            isNewMessageChat: false,
            isSearch: false,
            chatMessageText: '',
            searchText: '',
            chatSearchText: '',
            emojiPicker: false,
            isTyping: false,
            isLoading: false,
            currentChat: [],
            currentMark: 0,
            markLength: 0,
            ws: null
        };
        this.onKeyUpEvent = this.onKeyUpEvent.bind(this);
    }

    timeout = 250; // Initial timeout duration as a class variable

    componentDidMount = async () => {
        window.addEventListener('popstate', (event) => {
            if(this.state.isChat || this.state.isNewMessage) {
                this.closeChat(this.state.isNewMessageChat);
                this.closeNewMessage();
            }
        });
        this.getCaseInfo();
        this.connect();
        let response;
        const caseId = this.caseId;
        this.user = userService.getUser();
        this.setState({isLoading: true});
        apiService.getChatRoom({
            case_id: caseId
        }).then((res) => {
            if (res.status === 'success') {
                this.chatList=res.data;
                this.userStatus = [];
                this.chatList.forEach((e,key)=> {
                    if (e.is_group == 0 || e.is_group == undefined) {
                        this.userStatus[e.uid] = e.user_status;
                    }
                    if(this.chatRoomId && e.chat_room_id == this.chatRoomId) {
                        //this.setState({currentChat: e});
                        //this.viewChat(e);
                    }
                    else if (this.state.currentChat && this.state.currentChat.chat_room_id && e.chat_room_id == this.state.currentChat.chat_room_id) {
                            //this.viewChat(e);
                    } else if(key == 0 && ((e.chat_room_id != null && e.is_group == 1) || (e.chat_room_id != null  && e.is_group == 0)) ) {
                            //this.viewChat(e);
                    } else {
                        this.chatLoading = false;
                    }
                    if (!e.chat_room_id) {
                        this.selectMemLabel = true;
                    }
                });
                if (this.link && this.link.link_id) {
                    this.chatList.forEach(e => {
                        if (e.room_id == this.link.link_id) {
                            this.viewChat(e,'old');
                        }
                    });
                }
                this.setState({isLoading: false});
            }
        });
    };

    getCaseInfo = () => {
        apiService.getSingleCase({
            case_id: this.caseId
        }).then((res) => {
            if (res.status === 'success') {
                this.caseInfo = res.data;
            }
        });
    }

    /**
     * @function connect
     * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
     */
     connect = () => {
        var ws = new WebSocket(window['odrWsUrl']);
        let that = this; // cache the this
        var connectInterval;

        // websocket onopen event listener
        ws.onopen = () => {
            console.log("connected websocket main component");

            this.setState({ ws: ws });

            that.timeout = 250000; // reset timer to 250 on open of websocket connection 
            clearTimeout(connectInterval); // clear Interval on on open of websocket connection
        };
        
        ws.onmessage = (resData) => {
            let res = (resData && resData.data) ? JSON.parse(resData.data): [];
            if (res && res.method == 'typing') {
                let data = res.data;
                if (this.isTypingRoom[data.roomId]) {                                                
                    if (this.isTypingRoom[data.roomId].users.indexOf(data.from.name) == -1) {
                        this.isTypingRoom[data.roomId].users.push(data.from.name);
                    }
                } else {
                    this.isTypingRoom[data.roomId] = {
                        users: []
                    };
                    if (this.isTypingRoom[data.roomId].users.indexOf(data.from.name) == -1) {
                        this.isTypingRoom[data.roomId].users.push(data.from.name);
                    }
                }
                this.setState({ isTyping: false});
                if (data.roomId == this.state.currentChat.chat_room_id) {
                    this.setState({ isTyping: true});
                    if (this.typingUsers.indexOf(data.from.name) == -1) {
                        this.typingUsers.push(data.from.name);
                    }
                }
            } else if (res && res.method == 'stoptyping') {
                let data = res.data;
                if (this.isTypingRoom[data.roomId]) {                                                
                    this.isTypingRoom[data.roomId].users = this.isTypingRoom[data.roomId].users.filter(arrayItem => arrayItem !== data.from.name);
                    if (this.isTypingRoom[data.roomId].users.length == 0) {
                        this.isTypingRoom[data.roomId] = undefined;
                    }
                }
                if (data.roomId == this.state.currentChat.chat_room_id) {
                    this.typingUsers = this.typingUsers.filter(arrayItem => arrayItem !== data.from.name);
                    if (this.typingUsers.length == 0) {
                        this.setState({ isTyping: false});
                    }
                }
            } else if (res && res.method == 'message') {
                let data = res.data;
                let decryptMessage = CryptoJS.AES.decrypt(data.message.msg_info.msg, apiService.encryptKey(), { format: CryptoJSAesJson, mode: CryptoJS.mode.CBC }); //this.apiService.decrypt(data.message.msg_info.msg);
                let decryptedData = JSON.parse(decryptMessage.toString(CryptoJS.enc.Utf8));
                if (data.roomId == this.state.currentChat.chat_room_id) {
                    let d = this.state.currentChat.chatmessages.length > 0?this.state.currentChat.chatmessages[this.state.currentChat.chatmessages.length-1].key:'';
                    let n = new Date();
                    let fd = n.getFullYear()+'-'+("0" + (n.getMonth() + 1)).slice(-2)+'-'+("0" + (n.getDate())).slice(-2);
                    data.message.msg_info.msg = decryptedData.message;
                    if (d == fd) {
                        this.state.currentChat.chatmessages[this.state.currentChat.chatmessages.length-1].value.push(data.message); 
                    } else {
                        this.state.currentChat.chatmessages[this.state.currentChat.chatmessages.length] = {
                            key: fd,
                            value: [data.message]
                        };
                    }
                    let newChatMessage = this.state.currentChat;
                    setTimeout(() => {
                        window.scroll({
                            top: document.getElementById('chat-section').offsetHeight,
                            left: 0, 
                            behavior: 'smooth',
                        });
                    }, 500);
                    this.chatList.forEach(e => {
                        if (e.chat_room_id == data.roomId && data.message.msg_info) {
                            e.message = decryptedData.message;
                            e.updated_at = moment().utc();
                        }
                    });
                    this.setState({ currentChat: newChatMessage});
                    //this.dataService.setOptionNext('chatCountRefresh', true);
                } else if (data.users.indexOf(this.user.id) != -1 && data.from.u_id != this.user.id && this.caseId == data.case_id) {
                    let newChatMessage = this.state.currentChat;
                    this.chatList.forEach(e => {
                        if (e.chat_room_id == data.roomId && data.message.msg_info) {
                            e.message = decryptedData.message;
                            e.updated_at = moment().utc();
                            if (e.user_counter) {
                                e.user_counter.unreads += 1;
                            }
                            //this.unreadCount += 1;
                        }
                    });
                    this.setState({ currentChat: newChatMessage});
                }
            } else if (res && res.method == 'startChat') {
                let data = res.data;
                if (data.type == 'createChat' && data.users.indexOf(this.user.id) != -1 && this.caseId == data.case_id) {
                    this.getChatRoom();
                }
            }
        };

        // websocket onclose event listener
        ws.onclose = e => {
            console.log(
                `Socket is closed. Reconnect will be attempted in ${Math.min(
                    10000 / 1000,
                    (that.timeout + that.timeout) / 1000
                )} second.`,
                e.reason
            );

            that.timeout = that.timeout + that.timeout; //increment retry interval
            connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
        };

        // websocket onerror event listener
        ws.onerror = err => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            );

            ws.close();
        };
    };

    /**
     * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
     */
    check = () => {
        const { ws } = this.state;
        if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
    };

    send(data) {
        const user = userService.getUser();
        if (this.caseInfo && !data.data.customCase) {
            data.data.case_name = this.caseInfo?.case_name;
            data.data.case_id = this.caseInfo?.case_id;
        }
        if(data.data.time_display) {
            data.data.time = new Date();
        }
        if (!data.data.customFrom) {
            data.data.from = {
                'u_id': user.id,
                'p_name': user.p_name,
                'user_image': user.image,
                'color': user.color,
                'name': user.name,      
                'role_name': user.role_name      
            }
        }
        if (this.state.ws && this.state.ws.readyState !== WebSocket.CLOSED) {
            console.log("Websocket is open");
            this.state.ws.send(JSON.stringify(JSON.stringify(data))); //send data to the server
         } else {
            console.log("Websocket is closed");
         }
    }

    viewChat(chat, type = null) {
        this.setState({currentChat: [], isLoading: true});
        if(type == 'new') {
            this.setState({ isNewMessageChat: true, isChat: true, isMessageList: false, isNewMessage: false });
            let element = document.getElementsByClassName('bottom-fixed-menu')[0];
            element.style.display = 'none';
        } else if(type == 'old') {
            this.setState({ isChat: true, isMessageList: false, isNewMessage: false });
            let element = document.getElementsByClassName('bottom-fixed-menu')[0];
            element.style.display = 'none';
        }

        this.isNewChat = false;
        this.searchMessage = '';
        this.setState({ isTyping: false});
        this.typingUsers = [];
        //this.chatLoading = true;
        if (this.state.chatMessageText) {
            this.send({
                method: 'stoptyping',
                data: {
                    roomId: this.state.currentChat.chat_room_id,
                    users: this.state.currentChat.users.split(',')
                }
            });
        }
        if (!chat.chat_room_id) {
            apiService.createRoom({
                case_id: chat.case_case_id,
                username: chat.chat_username,
                userId: chat.users
            }).then((res) => {
                if (res.status == 'success') {
                    //this.dataService.setOptionNext('chatCountRefresh', true);
                    this.setState({currentChat: res.data});
                    this.getChatRoom();
                    this.setState({isLoading: false});
                    setTimeout(() => {
                        window.scroll({
                            top: document.getElementById('chat-section').offsetHeight,
                            left: 0, 
                            behavior: 'smooth',
                        });
                    }, 500);
                    let users = chat.users.split(',').map(Number);
                    users[users.length] = chat.creator;
                    this.send({
                        method: 'startChat',
                        data: {
                            roomId: this.state.currentChat.chat_room_id,
                            room_id: this.state.currentChat.room_id,
                            users: users,
                            content: 'has started the conversation ',
                            type: 'createChat',
                            title: 'Chat'
                        }
                    });
                }
            });
        } else if (chat.is_group == 1) {
            if (chat.user_counter && chat.user_counter.unreads && chat.user_counter.unreads != 0) {
                this.unreadCount = this.unreadCount - chat.user_counter.unreads;
                chat.user_counter.unreads = 0;
            }
            apiService.getGroupChatData({
                roomId: chat.chat_room_id
            }).then((res) => {
                if (res.status == 'success') {
                    chat.messages = res.data.reverse();
                    const grouped = this.groupBy(chat.messages, 'ts');
                    chat.chatmessages = grouped;
                    this.chatLoading = false;
                    chat.messages.forEach(e => {
                        let decryptMessage = CryptoJS.AES.decrypt(e.msg_info.msg, apiService.encryptKey(), { format: CryptoJSAesJson, mode: CryptoJS.mode.CBC });
                        let decryptedData = JSON.parse(decryptMessage.toString(CryptoJS.enc.Utf8));  
                        if(decryptedData && decryptedData.message) {
                            e.msg_info.msg = decryptedData.message;
                        } else {
                            e.msg_info.msg = decryptedData;
                        }
                    });
                    this.setState({currentChat: chat, isLoading: false});
                    //this.chatExportUrl = `${this.apiService.apiUrl}chat/export?title=${chat.name}&case_id=${this.selectedCase.case_id}&roomId=${chat.chat_room_id}&token=${this.apiService.getAuthToken()}&X_Auth_Token=${this.user.chat_token}&X_User_Id=${this.user.chat_uid}`;
                    setTimeout(() => {
                        window.scroll({
                            top: document.getElementById('chat-section').offsetHeight,
                            left: 0, 
                            behavior: 'smooth',
                        });
                    }, 500);
                    //this.dataService.setOptionNext('chatCountRefresh', true);
                    this.msgInputRef?.nativeElement.focus();
                }
            });
        } else {
            if (chat.user_counter && chat.user_counter.unreads && chat.user_counter.unreads != 0) {
                this.unreadCount = this.unreadCount - chat.user_counter.unreads;
                chat.user_counter.unreads = 0;
            }
            apiService.getChatData({
                roomId: chat.chat_room_id
            }).then(res => {
                if (res.status == 'success') {
                    chat.messages = res.data.reverse();
                    const grouped = this.groupBy(chat.messages, 'ts');
                    chat.chatmessages = grouped;
                    this.chatLoading = false;
                    chat.messages.forEach(e => {
                        let decryptMessage = CryptoJS.AES.decrypt(e.msg_info.msg, apiService.encryptKey(), { format: CryptoJSAesJson, mode: CryptoJS.mode.CBC });
                        let decryptedData = JSON.parse(decryptMessage.toString(CryptoJS.enc.Utf8));  
                        if(decryptedData && decryptedData.message) {
                            e.msg_info.msg = decryptedData.message;
                        } else {
                            e.msg_info.msg = decryptedData;
                        }                        
                    });
                    this.setState({currentChat: chat, isLoading: false});
                    //this.chatExportUrl = `${this.apiService.apiUrl}chat/export?title=${chat.name}&case_id=${this.selectedCase.case_id}&roomId=${chat.chat_room_id}&token=${this.apiService.getAuthToken()}&X_Auth_Token=${this.user.chat_token}&X_User_Id=${this.user.chat_uid}`;
                    setTimeout(() => {
                        window.scroll({
                            top: document.getElementById('chat-section').offsetHeight,
                            left: 0, 
                            behavior: 'smooth',
                          });
                    }, 500);
                    store.dispatch({ type: 'chatCountRefresh', value: true});
                    //let element = document.getElementsByClassName('bottom-fixed-menu')[0];
                    //this.dataService.setOptionNext('chatCountRefresh', true);
                    this.msgInputRef?.nativeElement.focus();
                }
            });
        }   
    }

    getChatRoom() {
        this.chatLoading = true;
        this.showCaseList = true;
        apiService.getChatRoom({
                case_id: this.caseId
            }).then(res => {
                if (res.status == 'success') {  
                    this.chatList = res.data;
                    this.userStatus = [];
                    this.selectMemLabel = false;
                    if(this.chatList.length == 0) {
                        this.chatLoading = false;
                    }
                    this.chatList.forEach((e,key)=> {
                        if (e.is_group == 0 || e.is_group == undefined) {
                            this.userStatus[e.uid] = e.user_status;
                        }
                        if(this.chatRoomId && e.chat_room_id == this.chatRoomId) {
                            this.setState({currentChat: e});
                            this.viewChat(e);
                        }
                        else if (this.state.currentChat && this.state.currentChat.chat_room_id && e.chat_room_id == this.state.currentChat.chat_room_id) {
                                this.viewChat(e);
                        } else if(key == 0 && ((e.chat_room_id != null && e.is_group == 1) || (e.chat_room_id != null  && e.is_group == 0)) ) {
                                this.viewChat(e);
                        } else {
                            this.chatLoading = false;
                        }
                        if (!e.chat_room_id) {
                            this.selectMemLabel = true;
                        }
                    });
                    this.chatRoomId = undefined;
                    if(this.adminUserChatList.length > 0) {
                        this.adminUserChatList.forEach(e => { 
                            if (e.is_group == 0 || e.is_group == undefined) {
                                this.userStatus[e.uid] = e.user_status;
                            }
                        });
                    }
                    if (this.link && this.link.link_id) {
                        this.chatList.forEach(e => {
                            if (e.room_id == this.link.link_id) {
                                this.viewChat(e,'old');
                            }
                        });
                    }
                } else {
                    this.chatLoading = false;
                }
            });
    }

    groupBy(collection, keyGetter) {
        if (!collection) {
            return null;
        }
        const groupedCollection = collection.reduce((previous, current) => {
            let d = new Date(current.msg_info[keyGetter].replace(/ /g,"T"));
            let month = d.getMonth() + 1;
            let fd = d.getFullYear() + '-' + ("0" + month).slice(-2) + '-' + ("0" + d.getDate()).slice(-2);
            if (!previous[fd]) {
                previous[fd] = [current];
            } else {
                previous[fd].push(current);
            }
            return previous;
        }, {});
        // this will return an array of objects, each object containing a group of objects
        return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
    }

    sendMessage() {
        if (this.state.chatMessageText && this.stripText(this.state.chatMessageText).trim() != '') {
            const directMessage = this.stripText(this.state.chatMessageText).trim();
            const jsonMessage = {
                message: this.stripText(this.state.chatMessageText).trim()
            };
            //CryptoJS.AES.decrypt(e.msg_info.msg, apiService.encryptKey(), { format: CryptoJSAesJson, mode: CryptoJS.mode.CBC });
            const message = CryptoJS.AES.encrypt(JSON.stringify(jsonMessage), apiService.encryptKey(), { format: CryptoJSAesJson, mode: CryptoJS.mode.CBC }).toString();         
            const md = moment().utc();
            const nd = md.format('YYYY') + '-' + ("0" + md.format('M')).slice(-2) + '-' + ("0" + md.format('D')).slice(-2) + ' ' + ("0" + md.hour()).slice(-2) + ':' + ("0" + md.minutes()).slice(-2) + ':' + ("0" + md.seconds()).slice(-2);
            const msg = {
                msg_info: {
                    rid: this.state.currentChat.chat_room_id,
                    msg: message,
                    read_by: [this.user.id],
                    ts: moment().utc(),
                    uid: this.user.id
                },
                user_info: {
                    'u_id': this.user.id,
                    'p_name': this.user.p_name,
                    'user_image': this.user.image,
                    'color': this.user.color,
                    'name': this.user.name, 
                    'role_label': this.user.role_label,
                    'role_name': this.user.role_name 
                }
            };
            const plainMsg = {
                msg_info: {
                    rid: this.state.currentChat.chat_room_id,
                    msg: directMessage,
                    read_by: [this.user.id],
                    ts: nd,
                    uid: this.user.id
                },
                user_info: {
                    'u_id': this.user.id,
                    'p_name': this.user.p_name,
                    'user_image': this.user.image,
                    'color': this.user.color,
                    'name': this.user.name,
                    'role_label': this.user.role_label,
                    'role_name': this.user.role_name             
                }
            };
            this.stopCopying();
            this.setState({chatMessageText: '', emojiPicker: false});
            const d = this.state.currentChat.chatmessages.length != 0 ? this.state.currentChat.chatmessages[this.state.currentChat.chatmessages.length - 1].key : '00';
            const n = new Date();
            let nmonth = n.getMonth() + 1;
            const fd = n.getFullYear() + '-' + ("0" + nmonth).slice(-2) + '-' + ("0" + n.getDate()).slice(-2);
            if (d == fd) {
                this.state.currentChat.chatmessages[this.state.currentChat.chatmessages.length - 1].value.push(plainMsg);
            } else {
                this.state.currentChat.chatmessages[this.state.currentChat.chatmessages.length] = {
                    key: fd,
                    value: [plainMsg]
                };
            }
            apiService.sendMessage({
                msg: message,
                roomId: this.state.currentChat.chat_room_id
            }).then(res => {
                if (res.status == 'success') {
                    this.chatList.forEach(e => {
                        if (e.chat_room_id == this.state.currentChat.chat_room_id) {
                            e.message = directMessage;
                            e.updated_at = moment().utc();
                        }
                    });                    
                    setTimeout(() => {
                        window.scroll({
                            top: document.getElementById('chat-section').offsetHeight,
                            left: 0, 
                            behavior: 'smooth',
                        });
                    }, 50);
                    this.msgInputRef?.nativeElement.focus();
                    let users = this.state.currentChat.users.split(',').map(Number);
                    users[users.length] = this.state.currentChat.creator;
                    this.send({
                        method: 'message',
                        data: {
                            roomId: this.state.currentChat.chat_room_id,
                            room_id: this.state.currentChat.room_id,
                            users: users,
                            message: msg,
                            content: res.instant_msg,
                            type: 'send_msg'
                        }
                    });
                }
            });
        }
    }

    stopCopying() {
        let users = this.state.currentChat.users.split(',').map(Number);
        users[users.length] = this.state.currentChat.creator;
        this.send({
            method: 'stoptyping',
            data: {
                roomId: this.state.currentChat.chat_room_id,
                users: users
            }
        });
    }

    onKeyUpEvent(e) {
        var code = e.keyCode;
        var chatText = e.target.value;
        if (code != 13 && (chatText.trim().length > 0 && chatText.trim().length < 5)) {
            let users = this.state.currentChat.users.split(',').map(Number);
            users[users.length] = this.state.currentChat.creator;
            this.send({
                method: 'typing',
                data: {
                    roomId: this.state.currentChat.chat_room_id,
                    users: users,
                    message: chatText
                }
            });
        } else if (chatText.trim().length == 0) {
            let users = this.state.currentChat.users.split(',').map(Number);
            users[users.length] = this.state.currentChat.creator;
            this.send({
                method: 'stoptyping',
                data: {
                    roomId: this.state.currentChat.chat_room_id,
                    users: users
                }
            });
        }
    }

    stripText($event) {
        var strippedText = $event.replace(/<\/?[^>]+(>|$)/g, ""); //$("<div/>").html($event).text();
        return strippedText;
    }

    chatMessageChange = (e) =>  {
        this.setState({chatMessageText: e.target.value});
    }

    filterChat() {
        // const filteredChat = this.chatList ? this.chatList.filter(
        //     (chat) => chat.is_group != 1
        // ): [];
        const filteredChat = this.chatList ? this.chatList: [];
        return filteredChat;
    }

    participantsExistFilterChat() {
        const participantsExistFilteredChat = this.filterChat() ? this.filterChat().filter(
            (chat) => chat.room_id
        ): [];
        return participantsExistFilteredChat;
    }

    participantsNewFilterChat() {
        var participantsNewFilteredChat = this.filterChat() ? this.filterChat().filter(
            (chat) => !chat.room_id
        ): [];
        if(this.state.searchText != '') {
            var participantsNewFilteredChat = this.searchFilter(participantsNewFilteredChat);
        }
        return participantsNewFilteredChat;
    }

    searchFilter(data) {
        const lowercasedFilter = this.state.searchText.toLowerCase();
        const filteredData = data.length > 0 ? data.filter(
            (item) => item.name.toLowerCase().includes(lowercasedFilter)
        ): data;
        return filteredData;
    }

    searchTextChange = (e) =>  {
        this.setState({searchText: e.target.value});
    }

    onEmojiClick = (e, emojiObject) =>  {
        let existValue = this.state.chatMessageText;
        this.setState({chatMessageText: existValue+emojiObject.emoji, emojiPicker: false});
    }

    quoteText(str) {
        return str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
    }

    chatSearchTextChange = ($event) =>  {
        this.setState({chatSearchText: $event.target.value});
        let count = 0;
        let c = this.state.currentChat.chatmessages.forEach(e => {
            e.value.forEach(emsg => {
                if(!emsg.msg_info.t) {
                    let value = String(emsg.msg_info.msg); // make sure its a string
                    let scount = (value.toLowerCase().match(new RegExp(this.quoteText($event.target.value.toLowerCase()), 'gi')) || []).length;
                    count = count + scount;
                }
            });
        });
        this.searchCount = count;
        this.markLength = $('mark').length;
        this.setState({markLength: $('mark').length});
        if (this.searchCount != 0 && $('mark')[0]) {
            this.currentMark = 0;
            this.setState({currentMark: 0});
            setTimeout(() => {
                if($('mark')[0])
                    $('mark')[0].scrollIntoView({ behavior: 'smooth', block: 'center'});
            });
        } else {
            this.currentMark = 0;
            this.setState({currentMark: 0});
        }
    }

    searchUp = () => {
        if ($('mark')[this.currentMark - 1]) {
            this.markLength = $('mark').length;
            this.setState({markLength: $('mark').length});
            $('mark')[this.currentMark - 1].scrollIntoView({ behavior: 'smooth', block: 'center'});            
            this.currentMark = this.currentMark - 1;
            this.setState({currentMark: this.currentMark});
        }
    }

    searchDowm = () => {
        if ($('mark')[this.currentMark + 1]) {
            $('mark')[this.currentMark + 1].scrollIntoView({ behavior: 'smooth', block: 'center'});
            this.currentMark = this.currentMark + 1;
            this.setState({currentMark: this.currentMark});
        }
    }

    chatSearchTextChange1 = ($event) =>  {
        this.setState({chatSearchText: $event.target.value});
        let count = 0;
        let c = this.state.currentChat.messages.forEach(e => {
            let value = String(e.msg_info.msg.replace('<mark>', '').replace('</mark>', '')); // make sure its a string
            if($event.target.value != '') {
                let matchValue = value.toLowerCase().match(new RegExp($event.target.value.toLowerCase(), 'gi'));
                value = (matchValue!=undefined) ? "<mark>"+value+"</mark>": value;
                let scount = (value.toLowerCase().match(new RegExp($event.target.value.toLowerCase(), 'gi')) || []).length;
                count = count + scount;
            }
            e.msg_info.msg = value;
        });
        this.searchCount = count;
        setTimeout(() => {
            this.markLength = document.getElementsByTagName("mark").length;
            if (this.searchCount != 0 && document.getElementsByTagName('mark')[0]) {
                this.currentMark = 0;
                    if(document.getElementsByTagName('mark')[0]) 
                        document.getElementsByTagName('mark')[0].scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    });
            } else {
                this.currentMark = 0;
            }
        }, 500);
    }

    openChat(type) {
        if(type == 'new') {
            this.setState({ isNewMessageChat: true, isChat: true, isMessageList: false, isNewMessage: false });
        } else if(type == 'old') {
            this.setState({ isChat: true, isMessageList: false, isNewMessage: false });
        }
        let element = document.getElementsByClassName('bottom-fixed-menu')[0];
        element.style.display = 'none';
    }

    closeChat(isNewMessageChat) {
        this.props.history.push('/app/case/'+(this.caseId)+'/message');
        (isNewMessageChat) ? this.setState({ isChat: false, isNewMessage: true, isNewMessageChat: false, isSearch: false, emojiPicker: false, chatSearchText: '', currentChat: []}): this.setState({ isChat: false, isMessageList: true, isSearch: false, emojiPicker: false, chatSearchText: '', currentChat: []});
        let element = document.getElementsByClassName('bottom-fixed-menu')[0];
        element.style.display = 'flex';
    }

    openNewMessage() {
        this.setState({ isMessageList: false, isNewMessage: true });
    }

    closeNewMessage() {
        this.setState({ isMessageList: true, isNewMessage: false, searchText: '' });
    }

    toggleSearch(isSearch) {
        this.setState({ isSearch: !isSearch, chatSearchText: '' });
    }

    toggleEmoji(emojiPicker) {
        this.setState({ emojiPicker: !emojiPicker });
    }

    navigateToPage(data) {
        let link;
        link = {
            link_id: data.room_id
        };
        this.props.history.push('/app/case/'+(this.caseId)+'/message/'+btoa(JSON.stringify(link)));
        window.location.reload();
        //this.viewChat(data, 'old')
    }

    render() {
        const { isMessageList, isChat, isNewMessage, isNewMessageChat, isSearch, chatMessageText, chatSearchText, currentChat, emojiPicker, isTyping, isLoading, markLength, currentMark } = this.state;
        
        //const currentChat = this.currentChat;
        const user = this.user;
        return (
            <div>
                <div style={{ display: (isMessageList ? 'block' : 'none') }}>
                    {isLoading?<Skeleton active loading={isLoading}></Skeleton>:
                        <div>
                            <Row className="page-header">
                                <Col span={12}>
                                    Messages
                                </Col>
                                <Col span={12} className="add-message-group" onClick={()=> this.openNewMessage() }>
                                    +
                                </Col>
                            </Row>
                            <Row className="cases-message-cards">
                                <Card className="message-card mb8 ">
                                { this.participantsExistFilterChat().length > 0 
                                    ? this.participantsExistFilterChat().map((item, index) => (
                                        // <div className="message-contain" onClick={()=> this.viewChat(item, 'old') } key={index}>
                                        <div className="message-contain" onClick={()=> this.navigateToPage(item) } key={index}>
                                            <Row className="message-section">
                                                <Col span={2.5}><Avatar src={item.image}>{item.p_name}</Avatar></Col>
                                                <Col span={20}>
                                                    <Row className="message-user-name">{item.name}</Row>
                                                    <Row className="message-user-role">{item.role}</Row>
                                                    { this.isTypingRoom[item.chat_room_id] 
                                                        ?   
                                                            <Row className="message-text">{this.isTypingRoom[item.chat_room_id].users.join(',')} {this.isTypingRoom[item.chat_room_id].users.length > 1?'are typing':'is typing' }</Row>
                                                        :
                                                            item.message
                                                        ?
                                                            <Row className="message-text" dangerouslySetInnerHTML={{ __html: (item.message).stringToUrl() }}></Row>
                                                        :<></>
                                                    }
                                                </Col>
                                                { (item.user_counter && item.user_counter.unreads)
                                                    ?
                                                        <Col span={1.5} className="message-unread-count"><p>{item.user_counter.unreads}</p></Col>
                                                    :<></>
                                                }
                                            </Row>
                                        </div>
                                    ))
                                    :
                                    <div className="cases-message-nocontent">
                                        <p className="message-icon">
                                            <NoMessageIcon/>
                                        </p>
                                        <p className="message-no-yet">
                                            No messages yet!
                                        </p>
                                        <p className="message-no-content">
                                            Click "+" button to initiate conversation with other participants.
                                        </p>
                                    </div>
                                }
                                </Card>
                            </Row>
                        </div>
                    }
                </div>

                <div style={{ display: (isChat ? 'block' : 'none') }}>
                    {isLoading?<Skeleton active loading={isLoading}></Skeleton>:
                        <div>
                            <Row className="chat-header">
                                <Card className="chat-card mb8 ">
                                    <Row gutter={16} align="middle">
                                        <Col>
                                            <LeftNavIcon onClick={()=> this.closeChat(isNewMessageChat) }/>
                                        </Col>
                                        <Col span={20} style={{ display: (isSearch ? 'block' : 'none') }}>
                                            <Row>
                                                <Col style={{ width: "100%"}} >
                                                    <Input suffix={chatSearchText != '' ? <div className="search-result-container"><p className="search-result-count">{this.searchCount+ " result found"}</p> <CloseIcon onClick={()=> this.toggleSearch(isSearch) }/></div>: <CloseIcon onClick={()=> this.toggleSearch(isSearch) }/>} placeholder="Search for messages" onChange={this.chatSearchTextChange} onKeyDown={() => (currentMark != markLength - 1) && this.searchDowm()} onKeyUp={() => (currentMark != 0) && this.searchUp()} value={chatSearchText} maxLength={64} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={18} style={{ display: (!isSearch ? 'block' : 'none') }}>
                                            { currentChat 
                                                ?<Row gutter={8}>
                                                    <Col><Avatar size={40} src={currentChat.image}>{currentChat.p_name}</Avatar></Col>
                                                    <Col>
                                                        <Row className="chat-user-name">{currentChat.name}</Row>
                                                        <Row className="chat-user-role">{isTyping ? 'typing...': currentChat.role}</Row>
                                                    </Col>
                                                </Row>
                                                :<></>
                                            }
                                        </Col>
                                        <Col span={2} className="chat-search" style={{ display: (!isSearch ? 'block' : 'none') }}>
                                            <SearchGlobalIcon onClick={()=> this.toggleSearch(isSearch) }/>
                                        </Col>
                                    </Row>
                                </Card>
                            </Row>

                            <Row className="chat-section" id="chat-section">
                                
                                <Card className="chat-encryption-card mb8">
                                    <Row>
                                        <Col>
                                            <p>Messages & Conferences are secured by end to end encryption</p>
                                        </Col>
                                    </Row>
                                </Card>

                                { currentChat && currentChat.chatmessages && currentChat.chatmessages.length > 0 
                                    ?   currentChat.chatmessages.map((item, index) => (
                                        <div key={index} style={{width:"100%"}}>
                                            <Divider className="chat-divider">{moment(item.key).isValid() && moment(item.key).calendar(null, this.dateFormats)}</Divider>
                                            { item && item.value.length > 0 
                                                ? item.value.map((message, msgIndex) => (
                                                    <div key={msgIndex}>
                                                        {   message.msg_info && message.msg_info.t && message.msg_info.t == 'au'
                                                            ?
                                                                <Row className="center-chat-text mb8">
                                                                    <Col>
                                                                        <strong>{ message.user_info?message.user_info.name:'Creator' } ({message.user_info && message.user_info.role_label?message.user_info.role_name:''})</strong> added { message.msg_info.msg } to the group
                                                                    </Col>
                                                                </Row>
                                                            :
                                                            message.msg_info && message.msg_info.t && message.msg_info.t == 'ru'
                                                            ?
                                                                <Row className="center-chat-text mb8">
                                                                    <Col>
                                                                        <strong>{ message.user_info?message.user_info.name:'Creator' } ({message.user_info && message.user_info.role_label?message.user_info.role_name:''})</strong> removed { message.msg_info.msg } from this group
                                                                    </Col>
                                                                </Row>
                                                            :
                                                            message.msg_info && message.msg_info.t && message.msg_info.t == 'ul'
                                                            ?
                                                                <Row className="center-chat-text mb8">
                                                                    <Col>
                                                                        <strong>{ message.user_info?message.user_info.name:message.msg_info.msg } ({message.user_info && message.user_info.role_label?message.user_info.role_name:''})</strong> left the group
                                                                    </Col>
                                                                </Row>
                                                            :
                                                            message.msg_info && message.msg_info.t && message.msg_info.t == 'r'
                                                            ?
                                                                <Row className="center-chat-text mb8">
                                                                    <Col>
                                                                        <strong>{ message.user_info?message.user_info.name:'Creator' } ({message.user_info && message.user_info.role_label?message.user_info.role_name:''})</strong> renamed the group { message.msg_info.msg }
                                                                    </Col>
                                                                </Row>
                                                            :
                                                            message.msg_info && message.msg_info.t && message.msg_info.t == 'jc'
                                                            ?
                                                                <Row className="center-chat-text mb8">
                                                                    <Col>
                                                                        <strong>{ message.user_info?message.user_info.name:'Creator' } ({message.user_info && message.user_info.role_label?message.user_info.role_name:''})</strong> { message.msg_info.msg } at {message.msg_info.ts.utcLocal().timeFormat()}
                                                                    </Col>
                                                                </Row>
                                                            :
                                                            message.msg_info && message.msg_info.t && message.msg_info.t == 'lc'
                                                            ?
                                                                <Row className="center-chat-text mb8">
                                                                    <Col>
                                                                        <strong>{ message.user_info?message.user_info.name:'Creator' } ({message.user_info && message.user_info.role_label?message.user_info.role_name:''})</strong> { message.msg_info.msg } { message.msg_info.msg } at {message.msg_info.ts.utcLocal().timeFormat()}
                                                                    </Col>
                                                                </Row>
                                                            :
                                                            message.msg_info && message.msg_info.t && message.msg_info.t == 'ra'
                                                            ?
                                                                <Row className="center-chat-text mb8">
                                                                    <Col>
                                                                        <strong>{ message.user_info?message.user_info.name:'Creator' } ({message.user_info && message.user_info.role_label?message.user_info.role_name:''})</strong> { message.msg_info.msg }
                                                                    </Col>
                                                                </Row>
                                                            :
                                                            message.user_info && message.msg_info && (!message.msg_info.t && message.msg_info.t != 'au') && user && message.user_info.u_id == user.id
                                                            ?
                                                                <>
                                                                    <Card className={(!item.value[msgIndex-1] || (item.value[msgIndex-1] && message.user_info && item.value[msgIndex-1].user_info && item.value[msgIndex-1].user_info.u_id != message.user_info.u_id) || (item.value[msgIndex-1] && item.value[msgIndex-1].msg_info.t)) ? "right-chat-text-card mb8 right-min-width": "right-chat-text-card mb8"} size="small">
                                                                        <Row style={{display: "block"}}>
                                                                            <Row className="right-chat-text">
                                                                                <Col>
                                                                                    <p className="text-message" dangerouslySetInnerHTML={{ __html: message.msg_info.msg.stringToUrl().highlight(message.msg_info.msg, chatSearchText) }}></p>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className="right-chat-detail">
                                                                                {   (!item.value[msgIndex-1] || (item.value[msgIndex-1] && message.user_info && item.value[msgIndex-1].user_info && item.value[msgIndex-1].user_info.u_id != message.user_info.u_id) || (item.value[msgIndex-1] && item.value[msgIndex-1].msg_info.t)) &&
                                                                                    (
                                                                                        <Col span={4} className="right-chat-user">
                                                                                            <p>You</p>
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                <Col span={(!item.value[msgIndex-1] || (item.value[msgIndex-1] && message.user_info && item.value[msgIndex-1].user_info && item.value[msgIndex-1].user_info.u_id != message.user_info.u_id) || (item.value[msgIndex-1] && item.value[msgIndex-1].msg_info.t)) ? 20: 24} className="right-chat-time">
                                                                                    <p>{String(message.msg_info.ts).utcLocal().dateTimeFormat()}</p>
                                                                                </Col>
                                                                            </Row>
                                                                        </Row>
                                                                    </Card>
                                                                </>
                                                            :
                                                            message.user_info && message.msg_info && (!message.msg_info.t && message.msg_info.t != 'au') && user && message.user_info.u_id != user.id
                                                            ?   
                                                                <Card className={(!item.value[msgIndex-1] || (item.value[msgIndex-1] && message.user_info && item.value[msgIndex-1].user_info && item.value[msgIndex-1].user_info.u_id != message.user_info.u_id) || (item.value[msgIndex-1] && item.value[msgIndex-1].msg_info.t)) ? "left-chat-text-card mb8 left-min-width": "left-chat-text-card mb8"}>
                                                                    <Row style={{display: "block"}}>
                                                                        <Row className="left-chat-text">
                                                                            <Col>
                                                                                <p className="text-message" dangerouslySetInnerHTML={{ __html: message.msg_info.msg.stringToUrl().highlight(message.msg_info.msg, chatSearchText) }}></p>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="left-chat-detail">
                                                                            {   (!item.value[msgIndex-1] || (item.value[msgIndex-1] && message.user_info && item.value[msgIndex-1].user_info && item.value[msgIndex-1].user_info.u_id != message.user_info.u_id) || (item.value[msgIndex-1] && item.value[msgIndex-1].msg_info.t)) &&
                                                                                (
                                                                                    <Col span={13} className="left-chat-user">
                                                                                        <p>{message.user_info.name} ({message.user_info && message.user_info.role_label?message.user_info.role_name:'Consumer'})</p>
                                                                                    </Col>
                                                                                )
                                                                            }
                                                                            <Col span={(!item.value[msgIndex-1] || (item.value[msgIndex-1] && message.user_info && item.value[msgIndex-1].user_info && item.value[msgIndex-1].user_info.u_id != message.user_info.u_id) || (item.value[msgIndex-1] && item.value[msgIndex-1].msg_info.t)) ? 11: 24} className="left-chat-time">
                                                                                <p>{String(message.msg_info.ts).utcLocal().dateTimeFormat()}</p>
                                                                            </Col>
                                                                        </Row>
                                                                    </Row>
                                                                </Card>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                ))
                                                :
                                                    <></>
                                            }
                                        </div>
                                    ))
                                    :
                                    <></>
                                }
                                
                            </Row>

                            {   (Boolean(currentChat.portal_active) && Boolean(currentChat.user_case_active) && Boolean(currentChat.curr_user_status)) &&
                                (
                                    <Row className="chat-bottom-text">
                                        <Card>
                                            <Row>
                                                <Col span={21} className="chat-text-box">
                                                    { emojiPicker 
                                                        ?
                                                            <Picker disableSearchBar={true} disableAutoFocus={true} onEmojiClick={this.onEmojiClick} />
                                                        :
                                                            <></>
                                                    }

                                                    <Row>
                                                        <Col>
                                                            <EmojiIcon onClick={()=> this.toggleEmoji(emojiPicker) } />
                                                        </Col>
                                                        <Col span={24}>
                                                            {/* <Picker onSelect={emoji => alert("Hey:"+emoji.native)} /> */}
                                                            <TextArea name="message" id="message" rows={4} onChange={this.chatMessageChange} onKeyUp={this.onKeyUpEvent} value={chatMessageText} placeholder={isTyping ? 'typing...':'Type your message'} autoSize={{ maxRows: 3 }} maxLength={1000} />
                                                        </Col>
                                                    </Row>
                                                    {/* <Input prefix={<EmojiIcon />} placeholder="Type your message" /> */}
                                                </Col>
                                                <Col span={3} className="chat-send-icon">
                                                    <Row>
                                                        <Col span={20}>
                                                            <SendMessageIcon onClick={()=> this.sendMessage() } />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Row>
                                )
                            }
                        </div>
                    }
                </div>

                <div style={{ display: (isNewMessage ? 'block' : 'none') }}>
                    <Row className="page-header">
                        <Col span={12}>
                            Messages
                        </Col>
                        {/* <Col span={12} className="add-message-group" onClick={()=> this.openNewMessage() }>
                            +
                        </Col> */}
                    </Row>
                    <Row className="new-message-header">
                        <Card className="new-message-header-card mb8 ">
                            <Row>
                                <Col span={4}>
                                    <LeftNavIcon onClick={()=> this.closeNewMessage() }/>
                                </Col>
                                <Col span={20}>
                                    <Row>
                                        <Col style={{ width: "100%"}} >
                                            <Input suffix={<SearchGlobalIcon />} value={this.state.searchText} placeholder="Search for Participants" onChange={this.searchTextChange} maxLength={32} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Row>

                    <Row className="cases-new-message-cards">
                        <Card className="new-message-card">
                            { this.participantsNewFilterChat().length > 0 
                                ? this.participantsNewFilterChat().map((item, index) => (
                                    <div className="new-message-contain mb8" onClick={()=> this.viewChat(item, 'new') } key={index}>
                                        <Row className="new-message-section">
                                            <Col span={3}><Avatar src={item.image}>{item.p_name}</Avatar></Col>
                                            <Col span={21}>
                                                <Row className="new-message-user-name">{item.name}</Row>
                                                <Row className="new-message-user-role">{item.role}</Row>
                                            </Col>
                                        </Row>
                                    </div>
                                ))
                                :
                                <div className="cases-message-nocontent">
                                    <p className="message-icon">
                                        <NoMessageIcon/>
                                    </p>
                                    <p className="message-no-yet">
                                        No new participants!
                                    </p>
                                    {/* <p className="message-no-content">
                                        Click "+" button to initiate conversation with other participants.
                                    </p> */}
                                </div>
                            }
                        </Card>
                    </Row>
                </div>
            </div>            
        );
    }
}

export default Message;