import { createStore } from 'redux';

const dataReducer = (state = { case: null, settings: null, currency: null, customMessage: null, chatCountRefresh: null, roles: null, user: null}, action) => {
    if (action.type === 'case') {
        return {
            case: action.value,
            settings: state.settings,
            currency: state.currency,
            customMessage: state.customMessage,
            chatCountRefresh: state.chatCountRefresh,
            roles: state.roles,
            user: state.user
        };
    }
    if (action.type === 'settings') {
        return {
            case: state.case,
            settings: action.value,
            currency: state.currency,
            customMessage: state.customMessage,
            chatCountRefresh: state.chatCountRefresh,
            roles: state.roles,
            user: state.user
        };
    }
    if (action.type === 'currency') {
        return {
            case: state.case,
            settings: state.settings,
            currency: action.value,
            customMessage: state.customMessage,
            chatCountRefresh: state.chatCountRefresh,
            roles: state.roles,
            user: state.user
        };
    }
    if (action.type === 'customMessage') {
        return {
            case: state.case,
            settings: state.settings,
            currency: state.currency,
            customMessage: action.value,
            chatCountRefresh: state.chatCountRefresh,
            roles: state.roles,
            user: state.user
        };
    }
    if (action.type === 'chatCountRefresh') {
        return {
            case: state.case,
            settings: state.settings,
            currency: state.currency,
            customMessage: state.customMessage,
            chatCountRefresh: action.value,
            roles: state.roles,
            user: state.user
        };
    }
    if (action.type === 'roles') {
        return {
            case: state.case,
            settings: state.settings,
            currency: state.currency,
            customMessage: state.customMessage,
            chatCountRefresh: state.chatCountRefresh,
            roles: action.value,
            user: state.user
        };
    }
    if (action.type === 'user') {
        return {
            case: state.case,
            settings: state.settings,
            currency: state.currency,
            customMessage: state.customMessage,
            chatCountRefresh: state.chatCountRefresh,
            roles: state.roles,
            user: action.value
        };
    }
    return state;
}
const store = createStore(dataReducer);

export default store;